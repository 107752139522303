angular.module('app', [
    'ngAnimate',
    'ngSanitize',
    'ngMaterial',
    'angularMoment',
	'ui.bootstrap',
	'ui.router',
	"LocalStorageModule",
	'angulartics', 
	'angulartics.google.analytics',
	'angularUtils.directives.dirPagination',
	'flow',
	'googlechart',
	'ui.timepicker'
])
.directive('onLastRepeat', function () {
	return function(scope, element, attrs) {
		if (scope.$last) {
			setTimeout(function() {
				scope.$emit('onRepeatLast');
			}, 1);
		}
	};
})
.config(["$stateProvider", "$urlRouterProvider", "$mdThemingProvider", function($stateProvider, $urlRouterProvider, $mdThemingProvider) {
	
	function loadTranslations($rootScope, $state, $q, TranslationsService) {
		if (!$rootScope.stateChangeStart || !$rootScope.stateChangeStart.toState.requiredTranslationPackages ||
				$rootScope.stateChangeStart.toState.requiredTranslationPackages.length === 0) return;
		
		// Check to ensure that the required packages exist in local storage
		// else load them (instances where first load of application)
		var d = $q.defer();
		var requiredPackages = $rootScope.stateChangeStart.toState.requiredTranslationPackages;		
		var pkgsToLoad = _.filter(requiredPackages, function (pkgName) {
			return !TranslationsService.isCached(pkgName);
		});

		TranslationsService.load(pkgsToLoad).then(function (resp) {
			$rootScope.stateChangeStart.data = angular.extend({
				translations: TranslationsService.getTranslations(requiredPackages)
			}, $rootScope.stateChangeStart.data);
			
			d.resolve();
		}, function (err) {
			d.reject(err);
		});

		return d.promise;
	}
	loadTranslations.$inject = ["$rootScope", "$state", "$q", "TranslationsService"];
	
	function checkAuth($rootScope, $state, $q, GAuth) {
		// Redirect user to login page if the route requires logged in user access
		if (!$rootScope.user) {
			var d = $q.defer();
			
			// First check if a valid session exists
			var x = GAuth.checkAuth().then(function () {
				d.resolve();
			}, function () {
				d.reject();				
				$state.go('login');
			});
			
			return d.promise;
		} else {
			return;
		}
	}
	checkAuth.$inject = ["$rootScope", "$state", "$q", "GAuth"];
	

	var queryString = window.location.href;
	var varArray = queryString.split("?");
	var externalUser = false;
	var token = "";
	for (var i=0;i<varArray.length;i++) {
	  var param = varArray[i].split("=");
	  if(param[0].indexOf("token") !== -1){
		  externalUser = true;
		  token = param[1];
	  }
	}	  
	
    $urlRouterProvider.otherwise("/");
    
    if(externalUser){
        $stateProvider
	    .state('myCompetences', {
	    	url: '/my-competences',
	    	abstract: true,
	    	template: '<ui-view />',
	    	controller: ["$state", function ($state) {
	    		//$state.go('myCompetences.list'); // Redirect to list
	    		$state.go('myCompetences.editor.external', {group: 1, step: 1}); // Redirect to step1
	    	}]
	    })
	    .state('myCompetences.finish', { 
	    	url: '/',
	    	templateUrl: '/static/templates/my-competences/finish.html',
//	    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
//	    	requiredRoles: ['meincer'],
	    	resolve: {translations: loadTranslations },
	    	requiredTranslationPackages: ['core', 'my-competences']
	    })	    
	    .state('myCompetences.editor', {
	    	abstract: true,
	    	templateUrl: '/static/templates/my-competences/external-editor.html'
	    })
	    .state('myCompetences.editor.external', {
	    	url: '/external/:group/:step',
	    	templateUrl: function ($stateParams) {
	    		return '/static/templates/my-competences/external-group-' + $stateParams.group + '.html';
	    	},
//	    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
//	    	requiredRoles: ['meincer'],
	    	resolve: { translations: loadTranslations },
	    	requiredTranslationPackages: ['core', 'my-competences']
	    });
        	
    } else {
        $stateProvider
	    	.state('home', {
		    	url: '/',
		    	templateUrl: '/static/templates/home.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer', 'career-coach', 'administrator', 'unit-leader', 'spoc-care', 'care-management'],
		    	requiredTranslationPackages: ['core', 'home']
		    })
		    .state('myAchievements', {
		    	url: '/my-achievements',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: ["$state", function ($state) {
		    		$state.go('myAchievements.list'); // Redirect to list
		    	}]
		    })
		    .state('myAchievements.list', {
		    	url: '/:realizeMyPlanId/:viewMode/:achievementsId',
		    	templateUrl: '/static/templates/my-achievements/list.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-achievements']
		    })
		    .state('myAchievements.editor', {
		    	abstract: true,
		    	templateUrl: '/static/templates/my-achievements/editor.html'
		    })
		    .state('myAchievements.editor.new', {
		    	url: '/new/:step',
		    	templateUrl: function ($stateParams) {
		    		return '/static/templates/my-achievements/step-' + $stateParams.step + '.html';
		    	},
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-achievements']
		    })
		    .state('myAchievements.editor.edit', {
		    	url: '/edit/:achievementsId/:step/:editMode',
		    	templateUrl: function ($stateParams) {
		    		return '/static/templates/my-achievements/step-' + $stateParams.step + '.html';
		    	},
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations},
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-achievements'] 
		    })
		    .state('myAchievements.editor.view', {
		    	url: '/view/:step',
		    	templateUrl: function ($stateParams) {
		    		return '/static/templates/my-achievements/step-' + $stateParams.step + '.html';
		    	},
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations},
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-achievements'] 
		    })
		    .state('myCompetences', {
		    	url: '/my-competences',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: ["$state", function ($state) {
		    		$state.go('myCompetences.list'); // Redirect to list
		    	}]
		    })
		    .state('myCompetences.list', {
		    	url: '/',
		    	templateUrl: '/static/templates/my-competences/list.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-competences']
		    })
		    .state('myCompetences.editor', {
		    	abstract: true,
		    	templateUrl: '/static/templates/my-competences/editor.html'
		    })
		    // .state('myCompetences.editor.new', {
		    // 	url: '/new/:group/:step',
		    // 	templateUrl: function ($stateParams) {
		    // 		return '/static/templates/my-competences/group-' + $stateParams.group + '.html';
		    // 	},
		    // 	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    // 	requiredRoles: ['meincer'],
		    // 	requiredTranslationPackages: ['core', 'my-competences']
		    // })
		    .state('otherCompetences', {
		    	url: '/other-competences',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: ["$state", function ($state) {
		    		$state.go('otherCompetences.list'); // Redirect to list
		    	}]
		    })
		    .state('otherCompetences.list', {
		    	url: '/',
		    	templateUrl: '/static/templates/other-competences/list.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'other-competences']
		    })  
		    .state('otherCompetences.content', {
		    	url: '/',
		    	templateUrl: '/static/templates/other-competences/content.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'other-competences']
		    })
		    .state('myPersonality', {
		    	url: '/my-personality',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: 'MyPersonalityBaseController'
		    })
		    .state('myPersonality.home', {
		    	url: '/',
		    	templateUrl: '/static/templates/my-personality/index.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-personality']
		    })
		    .state('myPersonality.preferences', {
		    	url: '/step/1',
		    	templateUrl: '/static/templates/my-personality/preferences.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-personality']
		    })
		    .state('myPersonality.motivators', {
		    	url: '/step/2',
		    	templateUrl: '/static/templates/my-personality/motivators.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-personality']
		    })
		    .state('myContext', {
		    	url: '/my-context',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: ["$state", function ($state) {
		    		$state.go('myContext.list'); // Redirect to list
		    	}]
		    })
		    .state('myContext.list', {
		    	url: '/',
		    	templateUrl: '/static/templates/my-context/list.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-context']
		    })
		    .state('myContext.editor', {
		    	abstract: true,
		    	templateUrl: '/static/templates/my-context/editor.html'
		    })
		    .state('myContext.editor.new', {
		    	url: '/new/:step',
		    	templateUrl: function ($stateParams) {
		    		return '/static/templates/my-context/step-' + $stateParams.step + '.html';
		    	},
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-context']
		    })	    
		    .state('myDestination', {
		    	url: '/my-destination',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: ["$state", function ($state) {
		    		$state.go('myDestination.home'); // Redirect to list
		    	}]
		    })
		    .state('myDestination.home', {
		    	url: '/',
		    	templateUrl: '/static/templates/my-destination/index.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-destination']
		    })
		    .state('myDestination.selection', {
	        	url: '/step',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: 'MyDestinationSelectionController'
	        })
		    // .state('myDestination.selection.visualMap', {
		    // 	url: '/1',
		    // 	templateUrl: '/static/templates/my-destination/visual-map.html',
		    // 	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    // 	requiredRoles: ['meincer'],
		    // 	requiredTranslationPackages: ['core', 'my-destination']
		    // })
		    .state('myDestination.selection.selectRole', {
		    	url: '/2/:destinationId',
		    	templateUrl: '/static/templates/my-destination/select-role.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-destination']
		    })
		    .state('myDestination.selection.confirmRole', {
		    	url: '/3/:destinationId',
		    	templateUrl: '/static/templates/my-destination/confirm-role.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-destination']
		    })
		    .state('myDestination.selection.targetMatrix', {
		    	url: '/2',
		    	templateUrl: '/static/templates/my-destination/target-matrix.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'my-destination','my-competences']
		    })
		    .state('contactCoach', {
		    	url: '/contact-coach',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: 'ContactCoachBaseController'
		    })
		    .state('contactCoach.home', {
		    	url: '/',
		    	templateUrl: '/static/templates/contact-coach/index.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'contact-coach']
		    })		   
		    .state('contactCoach.invite', {
		    	url: '/invite',
		    	templateUrl: '/static/templates/contact-coach/invite.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'contact-coach']
		    })
		    .state('contactCoach.meeting', {
		    	url: '/meeting',
		    	templateUrl: '/static/templates/contact-coach/meeting.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'contact-coach']
		    })
		    .state('contactUnitLeader', {
		    	url: '/contact-unit-leader',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: 'ContactUnitLeaderBaseController'
		    })
		    .state('contactUnitLeader.home', {
		    	url: '/',
		    	templateUrl: '/static/templates/contact-unit-leader/index.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'contact-unit-leader']
		    })		   
		    .state('contactUnitLeader.invite', {
		    	url: '/invite',
		    	templateUrl: '/static/templates/contact-unit-leader/invite.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'contact-unit-leader']
		    })
		    .state('realizeMyPlan', {
		    	url: '/realize-my-plan',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: 'RealizeMyPlanBaseController'
		    })
		    .state('realizeMyPlan.home', {
		    	url: '/',
		    	templateUrl: '/static/templates/realize-my-plan/index.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'realize-my-plan']
		    })
			.state('realizeMyPlan.preferred-ingredients', {
				url: '/new/1',
				templateUrl: '/static/templates/realize-my-plan/content.html',
				resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
				requiredRoles: ['meincer'],
				requiredTranslationPackages: ['core', 'realize-my-plan']
			})
			.state('realizeMyPlan.active-participation', {
				url: '/new/2',
				templateUrl: '/static/templates/realize-my-plan/content2.html',
				resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
				requiredRoles: ['meincer'],
				requiredTranslationPackages: ['core', 'realize-my-plan']
			})
			.state('realizeMyPlan.formal-learnings', {
				url: '/new/3',
				templateUrl: '/static/templates/realize-my-plan/content3.html',
				resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
				requiredRoles: ['meincer'],
				requiredTranslationPackages: ['core', 'realize-my-plan']
			})
		    .state('admin', {
		    	url: '/admin',
		    	abstract: true,
		    	templateUrl: '/static/templates/admin/index.html'
		    })
		    .state('admin.users', {
		    	url: '/users',
		    	abstract: true,
		    	template: '<div ui-view></div>',
		    	title: 'manage-users',
		    	redirectToState: 'admin.users.list',
		    	requiredRoles: ['administrator']
		    })
		    .state('admin.users.list', {
		    	url: '/list',
		    	templateUrl: '/static/templates/admin/users.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-users']
		    })
		    .state('admin.users.add', {
		    	url: '/add',
		    	templateUrl: '/static/templates/admin/users-add.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-users']
		    })
		    .state('admin.coaches', {
		    	url: '/coaches',
		    	abstract: true,
		    	template: '<ui-view />'
		    })
		    .state('admin.coaches.list', {
		    	url: '/list',
		    	templateUrl: '/static/templates/admin/coaches.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	title: 'manage-coaches',
		    	requiredRoles: ['administrator', 'care-management'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-coaches']
		    })
		    .state('admin.coaches.add', {
		    	url: '/add',
		    	templateUrl: '/static/templates/admin/coaches-add.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-users','admin-coaches']
		    })
		    .state('admin.coaches.edit', {
		    	url: '/edit',
		    	templateUrl: '/static/templates/admin/coaches-add.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-users']
		    })	    	    
		    .state('admin.coaches.assign', {
		    	url: '/assign',
		    	templateUrl: '/static/templates/admin/coaches-assign.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	title: 'assign-coaches',
		    	requiredRoles: ['administrator', 'care-management'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-coaches']
		    })
		    .state('admin.content', {
		    	url: '/content',
		    	templateUrl: '/static/templates/admin/content.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	title: 'manage-content',
		    	requiredRoles: ['administrator', 'care-management'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-content']
		    })
		    .state('admin.manage-plan', {
		    	url: '/manage-plan',
		    	templateUrl: '/static/templates/admin/manage-plan.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	title: 'manage-plan',
		    	requiredRoles: ['administrator', 'care-management'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-manage-plan']
		    })
		    .state('admin.accessMeIncer', {
		    	url: '/access-meincer',
		    	templateUrl: '/static/templates/admin/access-meincer.html',
		    	title: 'access-meincer',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations},
		    	requiredRoles: ['administrator','care-management'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-users', 'admin-access-meincer']
		    })
            .state('admin.setBackInProcess', {
                url: '/setback-inprocess',
                templateUrl: '/static/templates/admin/setback-inprocess.html',
                title: 'setback-inprocess',
                resolve: { isAuthenticated: checkAuth, translations: loadTranslations},
                requiredRoles: ['administrator','care-management'],
                requiredTranslationPackages: ['core', 'admin', 'admin-users', 'admin-setback-inprocess']
            })
			.state('spoc-care', {
				url: '/spoc-care',
				abstract: true,
				templateUrl: '/static/templates/spoc-care/index.html'
			})
			.state('spoc-care.review', {
				url: '/users',
				abstract: true,
				template: '<div ui-view></div>',
				title: 'access-meincer',
				redirectToState: 'spoc-care.review.list',
				requiredRoles: ['administrator','spoc-care']
			})
			.state('spoc-care.review.list', {
				url: '/access-meincer-spoc-care',
				templateUrl: '/static/templates/spoc-care/access-meincer.html',
				resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
				requiredRoles: ['administrator','spoc-care'],
				requiredTranslationPackages: ['core', 'admin', 'admin-users', 'admin-access-meincer']
			})
		     .state('workflows', {
		    	url: '/workflows',
		    	abstract: true,
		    	templateUrl: '/static/templates/workflows/index.html'
		    })
			.state('workflows.review', {
		    	url: '/review',
		    	abstract: true,
		    	//template: '<ui-view />',
		    	template: '<div ui-view></div>',
		    	title: 'review-facet5',
		    	redirectToState: 'workflows.review.list',
		    	requiredRoles: ['administrator', 'care-management','unit-leader','spoc-care']    	
		    })
		    .state('workflows.review.list', {
		    	url: '/review-list',
		    	templateUrl: '/static/templates/workflows/review.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management','unit-leader','spoc-care'],
		    	requiredTranslationPackages: ['core', 'admin', 'admin-content','workflows']
		    })	 
			.state('workflows.newcycle', {
		    	url: '/newcycle',
		    	abstract: true,
		    	//template: '<ui-view />',
		    	template: '<div ui-view></div>',
		    	title: 'newcycle',
		    	redirectToState: 'workflows.newcycle.list',
                requiredRoles: ['administrator', 'spoc-care']//TL-451
		    	//requiredRoles: ['administrator', 'care-management','unit-leader']
		    })
		    .state('workflows.newcycle.list', {
		    	url: '/newcycle-list',
		    	templateUrl: '/static/templates/workflows/newcycle.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	//requiredRoles: ['administrator', 'care-management','unit-leader'],
                requiredRoles: ['administrator', 'spoc-care'],//TL-451
		    	requiredTranslationPackages: ['core', 'admin', 'admin-content','workflows']
		    })	  		    
		    // .state('report', {
		    // 	url: '/report',
		    // 	templateUrl: '/static/templates/reports/index.html',
		    // 	abstract: true
		    // })
		    .state('report.processProgress', {
		    	url: '/process-progress',
		    	templateUrl: '/static/templates/reports/process-progress.html',
		    	title: 'report-process-progress',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports']
		    })
		    .state('report.destinationsStats', {
		    	url: '/destinations-stats',
		    	templateUrl: '/static/templates/reports/destinations-stats.html',
		    	title: 'report-destinations-stats',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'my-destination', 'reports']
		    })
		    .state('report.coachingAlignment', {
		    	url: '/coaching-alignment',
		    	templateUrl: '/static/templates/reports/coaching-alignment.html',
		    	title: 'report-coaching-alignment',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports']
		    })
		     .state('report.processProgress1', {
		    	url: '/process-progress1',
		    	templateUrl: '/static/templates/reports/process-progress1.html',
		    	title: 'report-process-progress1',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports']
		    })		    
		    .state('report.processProgress2', {
		    	url: '/process-progress2',
		    	templateUrl: '/static/templates/reports/process-progress2.html',
		    	title: 'report-process-progress2',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports']
		    })		    
			.state('report.competence-dest-report1', {
		    	url: '/competence-dest-report1',
		    	templateUrl: '/static/templates/reports/competence-dest-report1.html',
		    	title: 'report-competence-dest1',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports', 'my-destination']
		    })
		    .state('report.competence-dest-report2', {
		    	url: '/competence-dest-report2',
		    	templateUrl: '/static/templates/reports/competence-dest-report2.html',
		    	title: 'report-competence-dest2',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports', 'my-destination']
		    })
		    .state('report.competence-dest-report3', {
		    	url: '/competence-dest-report3',
		    	templateUrl: '/static/templates/reports/competence-dest-report3.html',
		    	title: 'report-competence-dest3',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports','my-competences']
		    })
		    .state('report.competence-dest-report4', {
		    	url: '/competence-dest-report4',
		    	templateUrl: '/static/templates/reports/competence-dest-report4.html',
		    	title: 'report-competence-dest4',
		    	isAuthenticatedState: true,
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
		    	requiredTranslationPackages: ['core', 'home', 'reports','my-competences']
		    })
		    .state('realizationInsight', {
		    	url: '/realization-insight',
		    	abstract: true,
		    	template: '<ui-view />',
		    	controller: ["$state", function ($state) {
		    		$state.go('realizationInsight.list'); // Redirect to list
		    	}]
		    })
		    .state('realizationInsight.list', {
		    	url: '/',
		    	templateUrl: '/static/templates/realization-insight/list.html',
		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
		    	requiredRoles: ['meincer'],
		    	requiredTranslationPackages: ['core', 'realization-insight', 'realize-my-plan']
		    })
//		    .state('report.competenciesStats', {
//		    	url: '/competencies-stats',
//		    	templateUrl: '/static/templates/reports/competencies-stats.html',
//		    	title: 'report-competencies-stats',
//		    	isAuthenticatedState: true,
//		    	resolve: { isAuthenticated: checkAuth, translations: loadTranslations },
//		    	requiredRoles: ['administrator', 'care-management', 'spoc-care', 'unit-leader'],
//		    	requiredTranslationPackages: ['core', 'reports']
//		    })
		    .state('forbidden', {
		    	url: '/forbidden',
		    	templateUrl: '/static/templates/auth/forbidden.html',
		    	resolve: { translations: loadTranslations },
		    	requiredTranslationPackages: ['core']
		    })
		    .state('login', {
		    	url: '/login',
		    	templateUrl: '/static/templates/auth/login.html',
		    	resolve: { translations: loadTranslations },
		    	requiredTranslationPackages: ['core']
		    })
		    .state('logout', {
		    	url: '/logout',
		    	isLogoutRoute: true,
		    	resolve: { translations: loadTranslations },
		    	requiredTranslationPackages: ['core']
		    });    	
    }
    
    $mdThemingProvider.definePalette('lmi2Pallete', {
        '50': 'e60004',
        '100': 'e60004',
        '200': 'e60004',
        '300': 'e60004',
        '400': 'e60004',
        '500': 'e60004',
        '600': 'e60004',
        '700': 'e60004',
        '800': 'e60004',
        '900': 'e60004',
        'A100': 'ff8a80',
        'A200': 'ff5252',
        'A400': 'ff1744',
        'A700': 'd50000',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100'],
        'contrastLightColors': undefined    
      });
  
    $mdThemingProvider.theme('default')
    	.primaryPalette('lmi2Pallete')
    	.accentPalette('lmi2Pallete');

}])
.run(['$window', '$rootScope', '$state', '$interval', '$q', 'GAuth', 'GApi', 'RolesManager', 
      function ($window, $rootScope, $state, $interval, $q, GAuth, GApi, rolesManager) {
	
	// Configure Google OAuth client of this web application
	// Test Environment Client ID
	// GAuth.setClient('644872794567-l19ht004d5tjnu170l3h0luajufqemc1.apps.googleusercontent.com');
	// Production Environment Client ID
	GAuth.setClient('1089290613938-4djm0t4uinorptj1ru5icmfbb1ccsh24.apps.googleusercontent.com');
	
	// Load Google Endpoints APIs for TriFinance LMI2
	var protocol = $window.location.protocol;
	if ($window.location.host.indexOf('localhost') < 0 && $window.location.host.indexOf('127.0.0.1') < 0) {
		// Google requires production endpoints to be accessed via HTTPS only!
		protocol = 'https:';
	} else {
        //load livereload only in local environment
        var script = document.createElement('script');
        script.src = "//localhost:35729/livereload.js";
        document.head.appendChild(script);
    }
	
	var apiUrl = protocol + '//' + $window.location.host + '/_ah/api';
	GApi.load('lmi2Api', 'v1', apiUrl);
	
	// If the route is protected (i.e. have requiredRoles specified, then 
	// ensure that users have the correct roles, else redirect to login page
	$rootScope.$on('$stateChangeStart',
			function(event, toState, toParams, fromState, fromParams) { 
		
		// Load state info into root scope for others to utilize. E.g. during the resolve of a state in ui-router.
		$rootScope.stateChangeStart = {
			toState: toState,
			toParams: toParams,
			fromState: fromState,
			fromParams: fromParams
		};
		
		// Log the user out and redirect to login route
		if (toState.isLogoutRoute) {
			GAuth.logout().then(function () {			
				$state.go('login');
			});
			
			return;
		}
		
		// Redirect user to forbidden route if the user does not have the necessary roles to view the required page;
		if ($rootScope.user && $rootScope.user.roles && toState.requiredRoles && 
				!rolesManager.hasRoles($rootScope.user, toState.requiredRoles)) {
			
			$state.go('forbidden');
			if (event.preventDefault) event.preventDefault();
			return;
			
		}
		
	});
	
	// Listen to $viewContentLoaded event to initialize material design behaviors. Other events available from
	// $stateProvider are: $stateChangeStart, $stateNotFound, $stateChangeSuccess, $stateChangeError, $viewContentLoading
	$rootScope.$on('$viewContentLoaded', function (event) {
		// Initializes Material Design ripples, input, check box, and radio button behaviors
		$.material.init();
		
		// Initializes Snackbar
		MDSnackbars.init();
	});
	
	// Temporary commented this line due to auto logout in windows
	// Challenge the user's logged in status every minute and log the user out if inactive/session ended
	// $interval(function () {
	// 	if ($rootScope.user) {
	// 		GAuth.challengeAuth();
	// 	}
	// }, 60000);
	
}]);
	

var app = angular.module('app');

app.controller('AdminAccessMeincerController', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi', 'NotificationsService', '$q',
                                    function($rootScope, $scope, $state, rolesManager, GApi, notificationsService, $q) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.isReady = true;
	$scope.searchText = null;
	$scope.selectedUser = null;
	$scope.selectedUserRoles = [];	
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.selectedItemChange = function (item) {
		if (!item) return;
		
		$scope.isReady = false;
		
		GApi.executeAuth('lmi2Api', 'auth.getUserRoles', {
			email: item.email
		}).then(function(resp) {
			if (resp) $scope.selectedUserRoles = resp.items || [];
			else $scope.selectedUserRoles = [];
			
			$scope.isReady = true;
		});
	};
	
	$scope.accessMeincer = function () {
		$rootScope.impersonateUser = angular.copy($scope.selectedUser);
		$rootScope.impersonateUserRoles = angular.copy($scope.selectedUserRoles);
		
		$state.go('home');
	};
	
	$scope.querySearch = function (query) {
		deferred = $q.defer();
		
		GApi.executeAuth('lmi2Api', 'auth.suggestUsers', { 
			criteria: query 
		}).then(function(resp) {			
			var results = [];
			
			if (resp && resp.items) {
				angular.forEach(resp.items, function (item) {
					if (item.userId !== $rootScope.user.id) results.push(item);
				});
			}
			
			deferred.resolve(results);
		}, function (err) {
			deferred.reject(err);
		});	
		
		return deferred.promise;
	};
	
	$scope.getDisplayText = function (item) {
		return item.fullName + '<' + item.email + '>';
	};
		
} ]);
var app = angular.module('app');

app.controller('CoachesAddController', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                    function($rootScope, $scope, $state, rolesManager, GApi) {
	
	
	$scope.editCoaches = $state.editCoaches;
	$scope.coachesItem = $state.selectedCoaches;
	

	$scope.coachesId = $scope.coachesItem.coachesId;
	$scope.firstName = $scope.coachesItem.firstName;
	$scope.lastName = $scope.coachesItem.lastName;
	$scope.email = $scope.coachesItem.email;	

	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.submit = function () {
		if ($scope.editCoaches){
			GApi.executeAuth('lmi2Api', 'coaches.updateCoach', { 
				coachesId: $scope.coachesId,
				firstName : $scope.firstName, 
				lastName :$scope.lastName,
				email : $scope.email
			}).then(function(resp) {
				if (resp.items[0]=="ok"){
					$state.go('admin.coaches.list');
				}else{
					alert("Error create coach");
				}
			}, function (err) {
	
			});
		} else {
			GApi.executeAuth('lmi2Api', 'coaches.addCoach', { 
				firstName : $scope.firstName, 
				lastName :$scope.lastName,
				email : $scope.email
			}).then(function(resp) {
				if (resp.items[0]=="ok"){
					$state.go('admin.coaches.list');
				}else{
					alert("Error update coach");
				}
			}, function (err) {
	
			});
		}
	};
} ]);
var app = angular.module('app');

app.controller('CoachesAssignController', [ '$rootScope', '$scope', '$state', 'GApi', '$modal',
                                    function($rootScope, $scope, $state, GApi, $modal) {
	
	// paging param
	var PAGE_SIZE = 10;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.currentMainPage = 1;
	$scope.totalMainRecords = 0;
	$scope.paginationMain = [];
	// paging param
	
	$scope.firstName = "";
	$scope.lastName = "";
	$scope.email = "";
	$scope.userList = [];
	$state.isAddCoachesAss=true;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
			$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	var lastSearch ={
		firstName: "",
		lastName: "",
		email: ""
	};
	
	//pagination
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentMainPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changeMainPage = function (mainpage) {
		$scope.currentMainPage = mainpage;
		listDomainUsers($scope.currentMainPage,lastSearch.firstName,lastSearch.lastName,lastSearch.email);
	};
	//pagination
	
	function listDomainUsers(page,firstname,lastname,email){
		GApi.executeAuth('lmi2Api','coaches.getUsersList', {
			page: page,
			pageSize: PAGE_SIZE,	
			firstName: firstname,
			lastName: lastname,
			email: email
	    	}
		).then(function (resp){
	    	//$scope.userList = resp.items;
	    	//Pagination
			$scope.currentMainPage = resp.page;
			$scope.totalMainRecords = resp.totalRecords;
			if (resp.records && _.isArray(resp.records)) {
				$scope.userList = _.map(resp.records, function (user) {
					return user;
				});
				
				$scope.userListCopy = angular.copy($scope.userList);
			} else {
				$scope.userList = [];
				$scope.userListCopy = [];
			}
			
			$scope.paginationMain = [];
			for (var i = 1; i <= Math.ceil($scope.totalMainRecords / PAGE_SIZE); i++) {
				$scope.paginationMain.push(i);
			}
			
			//Pagination
	    },function (err){
	    	// TODO: Log error
	    });
	}
	
	$scope.confirmRemoveAssCoaches = function (item) {
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'confirmRemoveCoach',
			  controller: 'UserUnassignCoaches',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});
	    modalInstance.result.then(function (item) {
	    	$scope.deleteAssignCoaches(item);
	      }, function (err) {
	    });
		
	};
	
	$scope.deleteAssignCoaches = function (item) {
		GApi.executeAuth('lmi2Api', 'coaches.deleteAssignCoaches', { 
			assignmentId: item.coachassignment.assignmentId
		}).then(function(resp) {
			listDomainUsers($scope.currentMainPage,lastSearch.firstName,lastSearch.lastName,lastSearch.email);
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	
	$scope.clickSearchDomainUser = function() {
		$scope.currentMainPage = 1;
		lastSearch = {
			firstName: $scope.firstName,
			lastName: $scope.lastName,
			email: $scope.email
		};
		listDomainUsers($scope.currentMainPage,lastSearch.firstName,lastSearch.lastName,lastSearch.email);
    }; 
    
    $scope.resetAssignCoaches = function() {
    	$scope.firstName = "";
    	$scope.lastName = "";
    	$scope.email = "";
    	$scope.userList = [];
    	
    	$scope.currentMainPage = 1;
    	$scope.totalMainRecords = 0;
    	$scope.paginationMain = [];
    	
    	lastSearch ={
    			firstName: "",
    			lastName: "",
    			email: ""
    	};
    };
    
    $scope.popupAssignCoaches = function (item) {
    	
    	if(item.coach){
    		$state.isAddCoachesAss=false;
    	}
    	else{
    		$state.isAddCoachesAss=true;
    	}
    	
    	var allItems = {items: item, translations: $scope.translations};
    	
    	var modalInstance = $modal.open({
    		templateUrl: 'popupAssignDialog',
    		controller: 'UserAssignCoaches',
    		backdrop: true,
    		resolve: {
    			allItems: function() {
    				return allItems;
    			}
    		}
    	});
    	modalInstance.result.then(function (item){
    		listDomainUsers($scope.currentMainPage,lastSearch.firstName,lastSearch.lastName,lastSearch.email);
    	}, function (err) {
    	});
    };
    

  
	
} ]);

app.controller('UserAssignCoaches', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems', '$state',
                                        function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems, $state) {
		// paging param
		var PAGE_SIZE = 10;
		var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
		$scope.currentPage = 1;
		$scope.totalRecords = 0;
		$scope.pagination = [];
		// paging param
		$scope.useritems = allItems.items;
     	$scope.coachFirstName = "";
    	$scope.coachLastName = "";
    	$scope.coachEmail = "";
    	$scope.coachList = [];
    	
     	$scope.translations = allItems.translations;
    	
    	var lastSearchCoach = {
     			coachFirstName: "",
     			coachLastName: "",
     			coachEmail: ""
     		};
    	
    	//Pagination
    	$scope.$on('onRepeatLast', function (scope, element, attrs) {
    		// Initialize the material elements (to render the material check boxes correctly) after the
    		// last role check box has been rendered
    		$.material.init();
    		
    		$('.popupcoachassign .pagination > li').removeClass('active');
    		$('.popupcoachassign .pagination > li').each(function () {
    			var item = $(this);
    			var pageNbr = parseInt(item.find('a').html());
    			
    			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
    		});
    		
    	});
    	
    	$scope.changePage = function (mainpage) {
    		$scope.currentPage = mainpage;
    		$scope.searchAssignCoaches($scope.currentPage,lastSearchCoach.coachFirstName,lastSearchCoach.coachLastName,lastSearchCoach.coachEmail);
    	};
    	//Pagination
    	
     	$scope.proceedAssignCoaches = function (item) {
     		assignCoaches(item);
     		$modalInstance.close();
     	};	
     	$scope.cancelAssignCoaches = function () {
     		$modalInstance.dismiss('cancel');
     	};
    	
     	$scope.clickSearchAssignCoaches = function(){
     		$scope.currentPage = 1;
     		lastSearchCoach = {
     			coachFirstName: $scope.coachFirstName,
     			coachLastName: $scope.coachLastName,
     			coachEmail: $scope.coachEmail
     		};
     		$scope.searchAssignCoaches($scope.currentPage,lastSearchCoach.coachFirstName,lastSearchCoach.coachLastName,lastSearchCoach.coachEmail);
     	};
     	
     	$scope.searchAssignCoaches = function(page,coachFirstName,coachLastName,coachEmail) {
        	GApi.executeAuth('lmi2Api','coaches.getCoachesList', {
    			page: $scope.currentPage,
    			pageSize: PAGE_SIZE,		        		
    			coachFirstName: coachFirstName,
    			coachLastName: coachLastName,
    			coachEmail: coachEmail
            	}
    		).then(function (resp){
            	//$scope.coachList = resp.items;
    			
    			//Pagination
    			$scope.currentPage = resp.page;
    			$scope.totalRecords = resp.totalRecords;
    			
    			if (resp.records && _.isArray(resp.records)) {
    				$scope.coachList = _.map(resp.records, function (coach) {
    					return coach;
    				});
    				
    				$scope.coachListCopy = angular.copy($scope.coachList);
    			} else {
    				$scope.coachList = [];
    				$scope.coachListCopy = [];
    			}
    			
    			$scope.pagination = [];
    			for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
    				$scope.pagination.push(i);
    			}
    			//Pagination
    			
            },function (err){
            	// TODO: Log error
            });
        };
        
        function assignCoaches(item) {
        	if($state.isAddCoachesAss){
        		GApi.executeAuth('lmi2Api', 'coaches.addAssignCoaches', { 
        			userId : $scope.useritems.domainUser.userId, 
        			coachesId :item.coachesId
    			}).then(function(resp) {
    				if (resp.items[0]=="nok"){
    					alert("Error create coachassignment");
    				}
    			}, function (err) {
    				alert("Error create coachassignment");
    			});
        	}
        	else{
        		GApi.executeAuth('lmi2Api', 'coaches.updateAssignCoaches', { 
        			assignmentId: $scope.useritems.coachassignment.assignmentId,
        			coachId : item.coachesId
    			}).then(function(resp) {
    				if (resp.items[0]=="nok"){
    					alert("Error update coachassignment");
    				}
    			}, function (err) {
    				alert("Error update coachassignment");
    			});
        	}	
    	}

} ]);


app.controller('UserUnassignCoaches', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                        function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
     	$scope.items = allItems.items;
     	$scope.translations = allItems.translations;
     	
     	$scope.proceedRemoveAssCoaches = function () {
     		$modalInstance.close($scope.items);
     	};	
     	$scope.cancelRemoveAssCoaches = function () {
     		$modalInstance.dismiss('cancel');
     	};		

} ]);


var app = angular.module('app');

app.controller('CoachesController', [ '$rootScope', '$scope', '$state', 'GApi', '$modal','NotificationsService',
                                    function($rootScope, $scope, $state, GApi, $modal, NotificationsService) {
	// paging param
	var PAGE_SIZE = 10;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	// paging param
	$scope.coaches=[];
	$state.selectedCoaches ="";
	$state.editCoaches=false;
	$scope.existInCoachAss = false;
	var modalInstance;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	listCoaches();
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		listCoaches();
	};
	
	$scope.editCoaches = function (item) {
		$state.editCoaches = true;
		$state.selectedCoaches = item;
	};	
	
	$scope.confirmationDeleteCoaches = function (item) {

		GApi.executeAuth('lmi2Api','coaches.checkExistCoachAssign', {
			coachesId: item.coachesId
	    	}
		).then(function (resp){

	    	if(resp.items===undefined){
	    		$scope.existInCoachAss = false;	
	    	}
	    	else{
	    		$scope.existInCoachAss = true;
	    	}
	    	
	    	var allItems = {items: item, translations: $scope.translations};
	    	if($scope.existInCoachAss){
	    		modalInstance = $modal.open({
					  templateUrl: 'notAllowToDelete.html',
					  controller: 'CoachesDeleteRecord',
					  backdrop: true,
					  resolve: {
						  allItems: function () {
					      return allItems;
					    }
					  }		      		      
				});
	    		modalInstance.result.then(function (item) {

			      }, function (err) {
			    });
			}
			else{
				modalInstance = $modal.open({
					  templateUrl: 'myModalContentCoaches.html',
					  controller: 'CoachesDeleteRecord',
					  backdrop: true,
					  resolve: {
						  allItems: function () {
					      return allItems;
					    }
					  }		      		      
				});
			    modalInstance.result.then(function (item) {
			    	$scope.deleteCoaches(item);
			      }, function (err) {
			    });
			}
	    },function (err){
	    	// TODO: Log error
	    });
		
	};
	
	$scope.deleteCoaches = function (item) {
		GApi.executeAuth('lmi2Api', 'coaches.deleteCoaches', { 
			coachesId: item.coachesId
		}).then(function(resp) {
			$state.reload();
		}, function (err) {
			$scope.isSaving = false;
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	
	function listCoaches(){
		GApi.executeAuth('lmi2Api', 'coaches.getCoachesList', {
			page: $scope.currentPage,
			pageSize: PAGE_SIZE		
		}).then(function (resp) {
			var test = "test1";
			//$scope.coaches = resp.items;
			$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.coaches = _.map(resp.records, function (coach) {
					return coach;
				});
				
				$scope.coachesCopy = angular.copy($scope.coaches);
			} else {
				$scope.coaches = [];
				$scope.coachesCopy = [];
			}
			
			$scope.pagination = [];
			for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
				$scope.pagination.push(i);
			}			
		},function (err) {
			// TODO: Log error
		});			
	}
	
} ]);

app.controller('CoachesDeleteRecord', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                        function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
     	$scope.items = allItems.items;
     	
    	$scope.translations = allItems.translations;
     	
     	$scope.proceedDeleteCoaches = function () {
     		$modalInstance.close($scope.items);
     	};	
     	$scope.cancelDeleteCoaches = function () {
     		$modalInstance.dismiss('cancel');
     	};		

} ]);
var app = angular.module('app');

app.controller('AdminContentController', [ '$rootScope', '$scope', '$state', 'TranslationsService', 'GApi', 'NotificationsService',
                                           function($rootScope, $scope, $state, translationsService, GApi, notificationsService) {
	$scope.translationUrl = null;	
	$scope.isWorking = false;
	$scope.buttonClass = "fa fa-refresh";
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};
	
	var retryTime = 0;
	$scope.refresh = function () {
		$scope.isWorking = true;
		$scope.buttonClass = "fa fa-circle-o-notch fa-spin";
		retryTime = 0;
		clearCacheFunction();
		
	};
	
	function clearCacheFunction(){
		translationsService.clearCache()
		.then(function (resp) {
			var packages = _.compact(_.uniq(_.flatten(_.pluck($state.get(), 'requiredTranslationPackages'))));
			return translationsService.load(packages);
		})
		.then(function (resp) {
			$scope.isWorking = false;
			$scope.buttonClass = "fa fa-refresh";
			notificationsService.show($scope.getTranslation('admin-content', 'success-message'), 3000);
		}, function () {
			
			retryTime = retryTime + 1;
			if(retryTime <= 2){
				clearCacheFunction();
			}
			else{
				$scope.isWorking = false;
				$scope.buttonClass = "fa fa-refresh";
				notificationsService.show($scope.getTranslation('admin-content', 'fail-message'), 6000);
			}
		});
	}
	
	$scope.migration = function (isTruncate) {
		GApi.executeAuth('lmi2Api', 'cycleProcess.migration', { 
			isTruncate: isTruncate
        }).then(function(resp) {
        	notificationsService.show('okay', 10000);
        }, function (err) {

        });	
	};
	
	function loadContent() {
		var docId = "1L5OLx4_qtWxDsadrPzMhS5gVYPyC-LGaqfCJSr5GMSI";
		$scope.getTranslation('core', 'edit-static-translation-sheet-id');
		$scope.translationUrl = 'https://docs.google.com/spreadsheets/d/' + docId + '/edit';
	}	
	
	loadContent();
} ]);
var app = angular.module('app');

app.controller('AdminController', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                    function($rootScope, $scope, $state, rolesManager) {
		
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
			
	$scope.tabs = _.filter($state.get(), function (state) {
		return (state.name && state.name.match(/^admin./) && state.title &&
				state.requiredRoles && rolesManager.hasRoles($rootScope.user, state.requiredRoles));
	});
		
	$scope.isSelected = function (stateName) {
		return (stateName && $state.includes(stateName)) ? 'selected' : '';
	};
	
	$scope.getRouteName = function (redirectTo, stateName) {
		return redirectTo || stateName;
	};
	
} ]);
var app = angular.module('app');

app.controller('AdminManagePlanController', [ '$rootScope', '$scope', '$state', 'TranslationsService', 'NotificationsService',
                                           function($rootScope, $scope, $state, translationsService, notificationsService) {

	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};
	
} ]);
var app = angular.module('app');

app.controller('AdminSetbackInprocessController', [ '$rootScope', '$scope', '$state', '$modal', 'RolesManager', 'GApi', 'NotificationsService', '$q',
                                    function($rootScope, $scope, $state, $modal, rolesManager, GApi, notificationsService, $q) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 5000;
	
	$scope.isReady = true;
	$scope.searchText = null;
	$scope.selectedUser = null;
	$scope.selectedUserRoles = [];
	$scope.processes = [];
	$scope.currentProcessDetails = {};
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.selectedItemChange = function (item) {
		if (!item) return;
		
		$scope.isReady = false;
		
		GApi.executeAuth('lmi2Api', 'auth.getUserRoles', {
			email: item.email
		}).then(function(resp) {
			if (resp) $scope.selectedUserRoles = resp.items || [];
			else $scope.selectedUserRoles = [];
			
			$scope.isReady = true;
            $scope.getCycleProcessStatusByUserId(item.userId);
		});


	};

	$scope.querySearch = function (query) {
		deferred = $q.defer();
		
		GApi.executeAuth('lmi2Api', 'auth.suggestUsers', { 
			criteria: query 
		}).then(function(resp) {			
			var results = [];
			
			if (resp && resp.items) {
				angular.forEach(resp.items, function (item) {
					if (item.userId !== $rootScope.user.id) results.push(item);
				});
			}
			
			deferred.resolve(results);
		}, function (err) {
			deferred.reject(err);
		});	
		
		return deferred.promise;
	};
	
	$scope.getDisplayText = function (item) {
		return item.fullName + '<' + item.email + '>';
	};

	$scope.getCycleProcessStatusByUserId = function(userId){
        GApi.executeAuth('lmi2Api', 'cycleProcess.getCycleProcessStatusByUserId', {
        processes: $scope.cycleModel.processes,
        userId:userId
		}).then(function(resp) {
			for (var i = 0; i < resp.processes.length; i++) {
				switch (resp.processes[i].nameKey) {
					case 'process-name-prepare':
						resp.processes[i].label = 'Prepare Yourself';
						break;
					case 'process-name-refine':
						resp.processes[i].label = 'Refine with Coach';
						break;
					case 'process-name-align':
						resp.processes[i].label = 'Align with Unit Leader';
						break;
					case 'process-name-realize':
						resp.processes[i].label = 'Realize Together';
						break;
				}
				if (resp.processes[i].status == 'not-started') {
					resp.processes[i].isDisabled = true; //add disabled here
				}
			}
			$scope.currentProcessDetails.currentProcessId = resp.currentProcessId;
			$scope.currentProcessDetails.cycleId = resp.id;
			$scope.processes = resp.processes;
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	};

	$scope.reverseCycleProcess = function(index){
		modalInstance = $modal.open({
			templateUrl: 'reverseProcessCycleModalDialog',
			controller: 'confirmReverseProcessCycleController',
			backdrop: true,
			resolve: {
				translations: function () {
					return $scope.translations;
				},
				processes: function () {
					return $scope.processes;
				},
				index: function() {
					return index;
				},
				GApi: function() {
					return GApi;
				},
				selectedUser: function () {
					return $scope.selectedUser;
				},
				notificationsService: function () {
					return notificationsService;
				}
			}
		});
		modalInstance.result.then(
			function (result) {
				//trigger when modal is closed
				$scope.getCycleProcessStatusByUserId($scope.selectedUser.userId); // to refresh the radio-button
			}, function () {
				//trigger when modal is dismissed
				$scope.getCycleProcessStatusByUserId($scope.selectedUser.userId); // to refresh the radio-button
			},
			function (err) {
				notificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			}
		);
	};

	$scope.cycleModel = {
		//id: $rootScope.user.cycleId,
		name: '2015/11',
		userId: $rootScope.user.id,
		status: 'not-started',
		currentProcessId: 0,
		processes: [{
			id: 1,
			nameKey: 'process-name-prepare',
			sortOrder: 1,
			status: 'not-started',
			steps: [{
				id: 1,
				nameKey: 'process-1-step-1-name',
				state: 'myAchievements.list',
				sortOrder: 1,
				status: 'not-started'
			},
				{
					id: 2,
					nameKey: 'process-1-step-2-name',
					state: 'myCompetences.list',
					sortOrder: 2,
					status: 'not-started'
				},
				{
					id: 3,
					nameKey: 'process-1-step-3-name',
					state: 'otherCompetences.list',
					sortOrder: 3,
					status: 'not-started'
				},
				{
					id: 4,
					nameKey: 'process-1-step-4-name',
					state: 'myPersonality.home',
					sortOrder: 4,
					status: 'not-started'
				},
				{
					id: 5,
					nameKey: 'process-1-step-5-name',
					state: 'myContext.list',
					sortOrder: 5,
					status: 'not-started'
				},
				{
					id: 6,
					nameKey: 'process-1-step-6-name',
					state: 'myDestination.home',
					sortOrder: 6,
					status: 'not-started'
				},
				{
					id: 7,
					nameKey: 'process-1-step-7-name',
					state: 'realizeMyPlan.home',
					sortOrder: 7,
					status: 'not-started'
				}]
		},
			{
				id: 2,
				name: 'Refine',
				nameKey: 'process-name-refine',
				sortOrder: 2,
				status: 'not-started',
				steps: [{
					id: 8,
					nameKey: 'process-2-step-1-name',
					state: 'contactCoach.invite',
					sortOrder: 1,
					status: 'not-started'
				},
					{
						id: 9,
						nameKey: 'process-2-step-2-name',
						state: 'myAchievements.list',
						sortOrder: 2,
						status: 'not-started'
					},
					{
						id: 10,
						nameKey: 'process-2-step-3-name',
						state: 'myCompetences.list',
						sortOrder: 3,
						status: 'not-started'
					},
					{
						id: 11,
						nameKey: 'process-2-step-4-name',
						state: 'otherCompetences.list',
						sortOrder: 4,
						status: 'not-started'
					},
					{
						id: 12,
						nameKey: 'process-2-step-5-name',
						state: 'myPersonality.home',
						sortOrder: 5,
						status: 'not-started'
					},
					{
						id: 13,
						nameKey: 'process-2-step-6-name',
						state: 'myContext.list',
						sortOrder: 6,
						status: 'not-started'
					},
					{
						id: 14,
						nameKey: 'process-2-step-7-name',
						state: 'myDestination.home',
						sortOrder: 7,
						status: 'not-started'
					},
					{
						id: 15,
						nameKey: 'process-2-step-8-name',
						state: 'realizeMyPlan.home',
						sortOrder: 8,
						status: 'not-started'
					}]
			},
			{
				id: 3,
				name: 'Align',
				nameKey: 'process-name-align',
				sortOrder: 3,
				status: 'not-started',
				steps: [
					{
						id: 19,
						nameKey: 'process-3-step-4-name',
						state: 'realizeMyPlan.home',
						sortOrder: 4,
						status: 'not-started'
					}]
			},
			{
				id: 4,
				name: 'Realize',
				nameKey: 'process-name-realize',
				sortOrder: 4,
				status: 'not-started',
				steps: [{
					id: 20,
					nameKey: 'process-4-step-1-name',
					state: 'realizationInsight.list',
					sortOrder: 1,
					status: 'not-started'
				}]
			}]
	};


} ]);

app.controller('confirmReverseProcessCycleController', [ '$rootScope', '$scope', '$modalInstance', 'translations', 'processes', 'index', 'GApi', 'selectedUser', 'notificationsService',
	function($rootScope, $scope, $modalInstance, translations, processes, index, GApi, selectedUser, notificationsService) {
		$scope.translations = translations;
		$scope.processes = processes;
		$scope.index = index+1;
		$scope.selectedUser = selectedUser;
		$scope.currentProcessDetails = {};
		var NOTIFICATIONS_FADEOUT_TIMEOUT = 5000;

		$scope.closeModalDialog = function () {
			$modalInstance.dismiss();
		};

		$scope.closeModalDialogAndDoSomething = function () {
			for (var i = $scope.processes.length - 1 ; i >= index; i--) {
				$scope.processes[i].status = 'not-started';
			}
			$scope.processes[index-1].status = 'ongoing';
			GApi.executeAuth('lmi2Api','cycleProcess.reverseCycleProcess', {
				processes: $scope.processes,
				userId: $scope.selectedUser.userId
			}).then(function (resp) {
				notificationsService.show("Successfully reverse process cycle.", NOTIFICATIONS_FADEOUT_TIMEOUT);
				$modalInstance.close();
			}, function (err) {
				notificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			});
		};
	}
]);

var app = angular.module('app');

app.controller('UsersAddController', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi', 'NotificationsService',
                                    function($rootScope, $scope, $state, rolesManager, GApi, NotificationsService) {
	
	var AUTOCOMPLETE_MIN_LENGTH = 2;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	var criteriaLength = 0;
	var cachedSuggestions = [];
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}
	
	$scope.isSaving = false;
	$scope.criteria = '';
	$scope.selectedRoles = [];
	$scope.suggestions = [];
	$scope.selectedUsers = [];
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.roles = _.each(rolesManager.getUserRoles(), function (item) {
		item.checked = false;
	});
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.onKeyDown = function(ev) {
		criteriaLength = $scope.criteria.length;
	};
	
	$scope.selectSuggestedUser = function (userId) {
		var user = _.find(cachedSuggestions, function (item) {
			return item.userId === userId;
		});
		
		if (user) $scope.selectedUsers.push(user);
		
		$scope.criteria = '';
		$('[name=search]').focus();
	};
	
	$scope.filterSuggestions = function (value, index, array) {
		var criteria = new RegExp($scope.criteria.toLowerCase());
		var exists = _.find($scope.selectedUsers, function (user) { return user.userId === value.userId; });
		
		return !exists && (value.userId.toLowerCase().match(criteria) ||
				(value.fullName || '').toLowerCase().match(criteria));
	};
	
	$scope.removeUser = function (userId) {
		if ($scope.isSaving) return;
		
		$scope.selectedUsers = _.filter($scope.selectedUsers, function (user) {
			return user.userId !== userId;
		});
	};
	
	$scope.submit = function () {
		var selectedRoles = _.compact(_.map($scope.roles, function (role) {
			if (role.checked) return role.id;
			else return;
		}));
		
		if ($scope.selectedUsers.length === 0) {
			NotificationsService.show(getErrorMessage('admin-users', 'add-user-error-no-users-specified'), NOTIFICATIONS_FADEOUT_TIMEOUT);
		} else if (selectedRoles.length === 0) {
			NotificationsService.show(getErrorMessage('admin-users', 'add-user-error-no-roles-specified'), NOTIFICATIONS_FADEOUT_TIMEOUT);
		} else {
			$scope.isSaving = true;
			
			GApi.executeAuth('lmi2Api', 'auth.addUsers', { 
				users: $scope.selectedUsers,
				roles: selectedRoles
			}).then(function(resp) {
				$state.go('admin.users.list');
			}, function (err) {
				$scope.isSaving = false;
				NotificationsService.show(getErrorMessage('admin-users', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			});	
		}
	};
	
	$scope.$watch('criteria', function () {
		if ($scope.criteria.length === AUTOCOMPLETE_MIN_LENGTH && criteriaLength < AUTOCOMPLETE_MIN_LENGTH) {
			// Get auto complete suggestions
			GApi.executeAuth('lmi2Api', 'auth.suggestUsers', 
					{ criteria: $scope.criteria }
			).then(function(resp) {
				if (resp && resp.items && _.isArray(resp.items)) {
					cachedSuggestions = resp.items;
					$scope.suggestions = resp.items;
				}
			});			
		} else if ($scope.criteria.length < AUTOCOMPLETE_MIN_LENGTH) {
			// Clear auto complete suggestions
			cachedSuggestions = [];
			$scope.suggestions = [];
		}
	});
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material checkboxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('[name=search]').focus();
	});
		
} ]);
var app = angular.module('app');

app.controller('UsersListController', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi', 'NotificationsService',
                                    function($rootScope, $scope, $state, rolesManager, GApi, notificationsService) {
	
	var PAGE_SIZE = 10;
	var USER_ROLES = rolesManager.getUserRoles();
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.currentPage = 1;
	$scope.isRetrievingUsers = false;
	$scope.isEditing = false;
	$scope.isDeleting = false;
	$scope.isSaving = false;
	$scope.totalRecords = 0;
	$scope.users = [];
	$scope.usersCopy = [];
	$scope.pagination = [];
	$scope.roles = rolesManager.getUserRoles();
	$scope.confirmUserDeletionEmail = null;
	$scope.confirmUserDeletionName = null;
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		if ($scope.isDeleting || $scope.isSaving) return;
		
		$scope.currentPage = page;
		listUsers();
	};
	
	$scope.canDelete = function (user) {
		var currentUser = $rootScope.user;
		
		if (_.filter(user.roles, function (item) {
			return item.id === 'unit-leader' && item.checked;
		}).length > 0) return false;
		if (!currentUser) return false;
		else return (user.email || '').toLowerCase() !== currentUser.email.toLowerCase();
	};
	
	$scope.confirmUserDeletion = function (email, name) {
		$scope.confirmUserDeletionEmail = email;
		$scope.confirmUserDeletionName = name;
		
		$('#confirmDeletionModal').modal();
	};
	
	$scope.deleteUser = function () {
		$('#confirmDeletionModal').modal('hide');
		$('.pagination > li').addClass('disabled');
		$scope.isDeleting = true;
		
		GApi.executeAuth('lmi2Api', 'auth.deleteUser', { 
			email: $scope.confirmUserDeletionEmail
		}).then(function(resp) {
			$('.pagination > li').removeClass('disabled');
			listUsers();
		}, function (err) {
			notificationsService.show(getErrorMessage('admin-users', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$('.pagination > li').removeClass('disabled');
			$scope.isDeleting = false;
		});
	};
	
	$scope.beginEdit = function () {
		$scope.isEditing = true;
	};
	
	$scope.cancelEdit = function () {
		$scope.isEditing = false;
		$scope.users = angular.copy($scope.usersCopy);
	};
	
	$scope.save = function () {
		$scope.isEditing = false;
		$scope.isSaving = true;
		$('.pagination > li').addClass('disabled');
		
		var users = _.map($scope.users, function (user) {
			return {
				email: user.email,
				roles: _.compact(_.map(user.roles, function (role) {
					return role.checked ? role.id : null;
				}))
			};
		});
		
		var rolesScope = _.map($scope.roles, function (role) {
			return role.id;
		});
		
		GApi.executeAuth('lmi2Api', 'auth.saveUsers', { 
			rolesScope: rolesScope,
			users: users
		}).then(function(resp) {
			$('.pagination > li').removeClass('disabled');
			listUsers();
		}, function (err) {
			notificationsService.show(getErrorMessage('admin-users', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.users = angular.copy($scope.usersCopy);
			$('.pagination > li').removeClass('disabled');
			$scope.isSaving = false;
		});
	};
		
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}	
	
	function listUsers() {
		$scope.isEditing = false;
		$scope.isDeleting = false;
		$scope.isSaving = false;
		$scope.isRetrievingUsers = true;
		
		GApi.executeAuth('lmi2Api', 'auth.listUsers', { 
			page: $scope.currentPage,
			pageSize: PAGE_SIZE,
			roles: USER_ROLES
		}).then(function(resp) {
			$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.users = _.map(resp.records, function (user) {
					var userRoles = _.compact(user.roles);
					
					user.roles = [];
					_.each($scope.roles, function (role) {
						var roleCopy = _.extend({ }, role);
						roleCopy.checked = _.contains(userRoles, role.id);
						user.roles.push(roleCopy);
					});
					
					return user;
				});
				
				$scope.usersCopy = angular.copy($scope.users);
			} else {
				$scope.users = [];
				$scope.usersCopy = [];
			}
			
			$scope.pagination = [];
			for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
				$scope.pagination.push(i);
			}
			
			$scope.isRetrievingUsers = false;
		}, function (err) {
			notificationsService.show(getErrorMessage('admin-users', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			
			$scope.isRetrievingUsers = false;
		});
	}
	
	listUsers();
		
} ]);
var app = angular.module('app');

app.controller('ForbiddenController', ['$rootScope', '$scope', '$state', function($rootScope, $scope, $state) {
	
	$scope.isRedirecting = false;	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.redirect = function () {		
		$scope.isRedirecting = true;
		$state.go('home');	
	};
	
}]);
var controllers = angular.module('app');

controllers.controller('LoginController', [ '$window', '$rootScope', '$scope', '$state', '$q', 'GAuth', 'GApi', 'GClient',
	function($window, $rootScope, $scope, $state, $q, GAuth, GApi, GClient) {

		$scope.isLoggingIn = false;
		$scope.translations = $rootScope.stateChangeStart.data &&
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

		$scope.login = function () {
			$scope.isLoggingIn = true;
			$rootScope.$broadcast('auth:logging-in');

			GAuth.login()
				.then(function () {
					$rootScope.$broadcast('auth:logging-in-completed');
					$state.go('home');
				}, function(err) {
					$rootScope.$broadcast('auth:logging-in-completed');
					if (err && err.status == 403) {
						// The user is not defined in the white list
						$state.go('forbidden');
					} else {
						// Login failed or user cancelled
						$scope.isLoggingIn = false;
					}
				});
		};


		$scope.init = function () {
			GClient.getGIS(function () {
					google.accounts.id.initialize({
						// client_id:'644872794567-l19ht004d5tjnu170l3h0luajufqemc1.apps.googleusercontent.com', //tst
						client_id:'1089290613938-4djm0t4uinorptj1ru5icmfbb1ccsh24.apps.googleusercontent.com', //prd
						scope: 'profile',
						callback: handleCredentialResponse
					});
					google.accounts.id.renderButton(
						document.getElementById("buttonDiv"),
						{ theme: "outline", size: "large" }  // customization attributes
					);
			});
		};

		$rootScope.$on("gis:login-popup-failed", function(){
			$('#allowPopupDialog').modal();
		});

		function decodeJwtResponse(token) {
			base64Url = token.split('.')[1];
			base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			return JSON.parse(jsonPayload);
		}

		function handleCredentialResponse(response) {
			$scope.isLoggingIn = true;
			$rootScope.$broadcast('auth:logging-in');
			responsePayload = decodeJwtResponse(response.credential);
			GAuth.login(responsePayload).then(function () {
				$rootScope.$broadcast('auth:logging-in-completed');
				$state.go('home');
			}, function(err) {
				$rootScope.$broadcast('auth:logging-in-completed');
				if (err && err.status == 403) {
					// The user is not defined in the white list
					$state.go('forbidden');
				} else {
					// Login failed or user cancelled
					$scope.isLoggingIn = false;
				}
			});
		}

	} ]);

var app = angular.module('app');

app.controller('ContactCoachBaseController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	$state.go('contactCoach.home');
	
}]);

app.controller('ContactCoachIndexController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	// paging param
	var PAGE_SIZE = 10;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	// paging param
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	init();
	
	function init(){
		$scope.isListLoading = true;
		GApi.executeAuth('lmi2Api', 'coachesInvitation.getMeetingOverview', { 
			page: $scope.currentPage,
			pageSize: PAGE_SIZE
        }).then(function(resp) {
        	//$scope.meetingOverview=resp.items;
        	$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.meetingOverview = _.map(resp.records, function (meetingOverview) {
					return meetingOverview;
				});
				
			} 
			
			$scope.pagination = [];
			for (var j = 1; j <= Math.ceil($scope.totalRecords / PAGE_SIZE); j++) {
				$scope.pagination.push(j);
			}

        	if($scope.meetingOverview){
	        	for (var i=0;i<$scope.meetingOverview.length;i++) {
	        		if(!$scope.meetingOverview[i].notes)
	        			$scope.meetingOverview[i].notes = "";
	        	}
        	}
        	$scope.isListLoading = false;
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
            $state.go('home');
        });			
	}

	$scope.startInviteCoach = function () {
		GApi.executeAuth('lmi2Api', 'coachesInvitation.startInviteCoach', {}).then(function(resp) {
			$state.go('contactCoach.invite');
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	};
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		init();
	};
	
	$scope.showNotes = function (item) {	
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'myModalContentMeeting.html',
			  controller: 'MeetingNotesRecord1',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});
	    modalInstance.result.then(function (item) {
	    	$scope.addNotes(item);
	      }, function (err) {
	    });		
	};
	
	$scope.addNotes = function (item) {
		//alert('Adding notes');
        GApi.executeAuth('lmi2Api', 'coachesInvitation.updateCoachesInvitation', { 
            coachesInvitationId: item.coachesInvitationId,
            notes: item.notes,
            status: item.status
        }).then(function(resp) {
            
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
            $state.go('home');
        });		
		//$state.reload();
	};		
}]);

app.controller('MeetingNotesRecord1', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                       function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
    	$scope.items = allItems.items;
    	
    	$scope.translations = allItems.translations;
    	
    	$scope.proceedAddNotes = function () {
    		$modalInstance.close($scope.items);
    	};	
    	$scope.cancelAddNotes = function () {
    		$modalInstance.dismiss('cancel');
    	};		

} ]);

var app = angular.module('app');

app.controller('ContactCoachInviteController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;

	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	//$scope.contactCoach = {coachesEmail:"", meetingFromDate:"", meetingToDate:""};
	init();
	
	function init(){
		$scope.isIndexLoading = true;
		GApi.executeAuth('lmi2Api', 'coachesInvitation.getContactCoachDetail', { 
        }).then(function(resp) {
        	//$scope.contactCoach = JSON.parse(resp.result);
        	$scope.contactCoach = resp;
        	$scope.contactCoach.eventTitle = "";
        	if($scope.translations['contact-coach'] !== undefined)
        		$scope.contactCoach.eventTitle = $scope.translations['contact-coach']['refine-event-name'];
        	
        	if(!$scope.contactCoach.coachesEmail)
        		$scope.contactCoach.coachesEmail = "";
        	if(!$scope.contactCoach.documentName)
        		$scope.contactCoach.documentName = "";    
        	if(!$scope.contactCoach.googleDocId){
        		$scope.contactCoach.googleDocId = "";
        		$scope.contactCoach.sharePlan = false;
        	}
        	else{
        		$scope.contactCoach.sharePlan = true;
        	}
        	$scope.contactCoach.meeting = new Date();
        	$scope.contactCoach.meetingFromDate = new Date();
        	$scope.contactCoach.meetingToDate = new Date();

            //console.log($scope.contactCoach);
        	$scope.isIndexLoading = false;
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
            $state.go('contactCoach.home');
        });			
	}	
	
	function isInt(value) {
	  return !isNaN(value) && 
	         parseInt(Number(value)) == value && 
	         !isNaN(parseInt(value, 10));
	}	
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.inviteCoach = function () {
		var dateTextBox = $scope.contactCoach.meeting;
        var dateFrom = new Date(dateTextBox);
        var dateTo = new Date(dateTextBox);   		
        var timeFromTextBox = $scope.contactCoach.meetingFromDate;
        var timeToTextBox = $scope.contactCoach.meetingToDate;
        
        var dateToLessThanDateFromErr = $scope.translations['contact-coach']['err-msg-dateto-less-datefrom'];
        var dateFromLessThanNewDate = $scope.translations['contact-coach']['err-msg-datefrom-less-newdate'];
        
        dateFrom.setHours(timeFromTextBox.getHours(),timeFromTextBox.getMinutes());
        dateTo.setHours(timeToTextBox.getHours(),timeToTextBox.getMinutes());
        if(dateTo < dateFrom){
        	alert(dateToLessThanDateFromErr);
        	return;
        }
        if(dateFrom < new Date()){
        	alert(dateFromLessThanNewDate);
        	return;
        }
        //var breakWord = timeFromTextBox.indexOf(":");
        
        //dateFrom.setMonth(dateTextBox.getMonth());
        //dateFrom.setYear(dateTextBox.getFullYear());
        //dateFrom.setHours(timeFromTextBox.substring(0, breakWord));
        //dateFrom.setMinutes(timeFromTextBox.substring(breakWord+1,timeFromTextBox.length));
        
        /*if(timeFromTextBox.indexOf(":") === -1){
        	alert("Invalid format for time from field. Please use hh:mm");
        	return;
        }
        if(timeFromTextBox.substring(0, breakWord)>24){
        	alert("Invalid hours for time from field.");
        	return;
        }
        if(timeFromTextBox.substring(breakWord+1,timeFromTextBox.length)>59){
        	alert("Invalid minutes for time from field.");
        	return;
        }*/
        
       // breakWord = timeToTextBox.indexOf(":");
        //dateTo.setDate(dateTextBox.getDate());
       // dateTo.setTime(timeToTextBox);
       // dateTo.setHours(timeToTextBox.substring(0, breakWord));
       // dateTo.setMinutes(timeToTextBox.substring(breakWord+1,timeToTextBox.length));		
        
        /*if(timeToTextBox.indexOf(":") === -1){
        	alert("Invalid format for time to field. Please use hh:mm");
        	return;
        }
        if(timeToTextBox.substring(0, breakWord)>24){
        	alert("Invalid hours for time to field.");
        	return;
        }*/
        
        $scope.isSaving = true;
        GApi.executeAuth('lmi2Api', 'coachesInvitation.saveCoachesInvitation', { 
            cycleId: $scope.contactCoach.cycleId,
            coachesId: $scope.contactCoach.coachesId,
            documentPlanId: $scope.contactCoach.documentPlanId,
            sharePlan: $scope.contactCoach.sharePlan,
            eventTitle: $scope.contactCoach.eventTitle,
            meetingFromDate: dateFrom,
            meetingToDate: dateTo,
            coachesEmail: $scope.contactCoach.coachesEmail,
            googleDocId: $scope.contactCoach.googleDocId
        }).then(function(resp) {
        	$scope.isSaving = false;
        	$state.go('contactCoach.home');
        }, function (err) {
        	$scope.isSaving = false;
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
        });		
	};

}])

.config(["$mdDateLocaleProvider", function($mdDateLocaleProvider) {
    $mdDateLocaleProvider.formatDate = function(date) {
       return moment(date).format('DD/MM/YYYY');
    };
}]);
var app = angular.module('app');

app.controller('ContactCoachMeetingController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;

	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	init();
	
	function init(){
		GApi.executeAuth('lmi2Api', 'coachesInvitation.getMeetingOverview', { 
        }).then(function(resp) {
        	$scope.meetingOverview=resp.items;
        	
        	if($scope.meetingOverview){
	        	for (var i=0;i<$scope.meetingOverview.length;i++) {
	        		if(!$scope.meetingOverview[i].notes)
	        			$scope.meetingOverview[i].notes = "";
	        	}
        	}
        	
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
        });			
	}
	
	$scope.showNotes = function (item) {	
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'myModalContentMeeting.html',
			  controller: 'MeetingNotesRecord2',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});
	    modalInstance.result.then(function (item) {
	    	$scope.addNotes(item);
	      }, function (err) {
	    });		
	};
	
	$scope.addNotes = function (item) {
		//alert('Adding notes');
        GApi.executeAuth('lmi2Api', 'coachesInvitation.updateCoachesInvitation', { 
            coachesInvitationId: item.coachesInvitationId,
            notes: item.notes,
            status: item.status
        }).then(function(resp) {
            
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
        });		
		//$state.reload();
	};	
}]);

app.controller('MeetingNotesRecord2', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                        function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
     	$scope.items = allItems.items;
     	
    	$scope.translations = allItems.translations;
     	
     	$scope.proceedAddNotes = function () {
     		$modalInstance.close($scope.items);
     	};	
     	$scope.cancelAddNotes = function () {
     		$modalInstance.dismiss('cancel');
     	};		

} ]);
var app = angular.module('app');

app.controller('ContactUnitLeaderBaseController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	$state.go('contactUnitLeader.home');
	
}]);

app.controller('ContactUnitLeaderIndexController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	// paging param
	var PAGE_SIZE = 10;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	// paging param
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	init();
	
	function init(){
		$scope.isListLoading = true;
		GApi.executeAuth('lmi2Api', 'unitLeaderInvitation.getUnitLeaderMeetingOverview', { 
			page: $scope.currentPage,
			pageSize: PAGE_SIZE
        }).then(function(resp) {
        	//$scope.meetingOverview=resp.items;
        	$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.meetingOverview = _.map(resp.records, function (meetingOverview) {
					return meetingOverview;
				});
				
			} 
			
			$scope.pagination = [];
			for (var j = 1; j <= Math.ceil($scope.totalRecords / PAGE_SIZE); j++) {
				$scope.pagination.push(j);
			}

        	if($scope.meetingOverview){
	        	for (var i=0;i<$scope.meetingOverview.length;i++) {
	        		if(!$scope.meetingOverview[i].notes)
	        			$scope.meetingOverview[i].notes = "";
	        	}
        	}
        	$scope.isListLoading = false;
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
            $state.go('home');
        });			
	}
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		init();
	};
	
	$scope.showNotes = function (item) {	
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'myModalContentMeeting.html',
			  controller: 'MeetingNotesRecord3',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});
	    modalInstance.result.then(function (item) {
	    	$scope.addNotes(item);
	      }, function (err) {
	    });		
	};
	
	$scope.addNotes = function (item) {
		//alert('Adding notes');
        GApi.executeAuth('lmi2Api', 'unitLeaderInvitation.updateUnitLeaderInvitation', { 
            unitLeaderInvitationId: item.unitLeaderInvitationId,
            notes: item.notes,
            status: item.status
        }).then(function(resp) {
            
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
            $state.go('home');
        });		
		//$state.reload();
	};		
}]);

app.controller('MeetingNotesRecord3', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                       function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
    	$scope.items = allItems.items;
    	$scope.translations = allItems.translations;
    	
    	$scope.proceedAddNotes = function () {
    		$modalInstance.close($scope.items);
    	};	
    	$scope.cancelAddNotes = function () {
    		$modalInstance.dismiss('cancel');
    	};		

} ]);
var app = angular.module('app');

app.controller('ContactUnitLeaderInviteController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

	init();
	
	function init(){
		$scope.isIndexLoading = true;
		GApi.executeAuth('lmi2Api', 'unitLeaderInvitation.getContactUnitLeaderDetail', { 
            
        }).then(function(resp) {
        	//$scope.contactUnitLeader = JSON.parse(resp.result);
        	$scope.contactUnitLeader = resp;
        	
        	$scope.contactUnitLeader.eventTitle = $scope.translations['contact-unit-leader']['refine-event-name'];
        	
        	if(!$scope.contactUnitLeader.unitLeaderEmail)
        		$scope.contactUnitLeader.unitLeaderEmail = "";
        	if(!$scope.contactUnitLeader.documentName)
        		$scope.contactUnitLeader.documentName = "";    
        	if(!$scope.contactUnitLeader.googleDocId){
        		$scope.contactUnitLeader.googleDocId = "";
        		$scope.contactUnitLeader.sharePlan = false;
        	}
        	else{
        		$scope.contactUnitLeader.sharePlan = true;
        	}
        	$scope.contactUnitLeader.meeting = new Date();
        	$scope.contactUnitLeader.meetingFromDate = new Date();
        	$scope.contactUnitLeader.meetingToDate = new Date();

        	$scope.isIndexLoading = false;
        }, function (err) {
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
            $state.go('contactUnitLeader.home');
        });			
	}	
	
	function isInt(value) {
	  return !isNaN(value) && 
	         parseInt(Number(value)) == value && 
	         !isNaN(parseInt(value, 10));
	}	
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.inviteUnitLeader = function () {
		var dateTextBox = $scope.contactUnitLeader.meeting;
        var dateFrom = new Date(dateTextBox);
        var dateTo = new Date(dateTextBox);   		
        var timeFromTextBox = $scope.contactUnitLeader.meetingFromDate;
        var timeToTextBox = $scope.contactUnitLeader.meetingToDate;
        
        var dateToLessThanDateFromErr = $scope.translations['contact-unit-leader']['err-msg-dateto-less-datefrom'];
        var dateFromLessThanNewDate = $scope.translations['contact-unit-leader']['err-msg-datefrom-less-newdate'];
        
        dateFrom.setHours(timeFromTextBox.getHours(),timeFromTextBox.getMinutes());
        dateTo.setHours(timeToTextBox.getHours(),timeToTextBox.getMinutes());
        if(dateTo < dateFrom){
        	alert(dateToLessThanDateFromErr);
        	return;
        }
        if(dateFrom < new Date()){
        	alert(dateFromLessThanNewDate);
        	return;
        }
        
        $scope.isSaving = true;
        GApi.executeAuth('lmi2Api', 'unitLeaderInvitation.saveUnitLeaderInvitation', { 
            cycleId: $scope.contactUnitLeader.cycleId,
            unitLeaderId: $scope.contactUnitLeader.unitLeaderId,
            documentPlanId: $scope.contactUnitLeader.documentPlanId,
            sharePlan: $scope.contactUnitLeader.sharePlan,
            eventTitle: $scope.contactUnitLeader.eventTitle,
            meetingFromDate: dateFrom,
            meetingToDate: dateTo,
            unitLeaderEmail: $scope.contactUnitLeader.unitLeaderEmail,
            googleDocId: $scope.contactUnitLeader.googleDocId
        }).then(function(resp) {
        	$scope.isSaving = false;
        	$state.go('contactUnitLeader.home');
        }, function (err) {
        	$scope.isSaving = false;
            NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
        });		
	};

}])

.config(["$mdDateLocaleProvider", function($mdDateLocaleProvider) {
    $mdDateLocaleProvider.formatDate = function(date) {
       return moment(date).format('DD/MM/YYYY');
    };
}]);
var app = angular.module('app');

app.directive('barChart', function () {
    return {
        restrict: 'E',
        templateUrl: '/static/templates/directives/bar-chart.html',
        scope: {
            'graphValue': '=',
            'graphStyle': '@'
        },
        controller: [ '$rootScope', '$scope', '$filter', '$q', 'TranslationsService',
            function($rootScope, $scope, $filter, $q, translationsService) {

                $scope.translations = $rootScope.stateChangeStart.data &&
                    $rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

                $scope.$watch('graphValue', function () {
                    if ($scope.graphValue) populateGraph();
                });

                function populateGraph() {

                    var graphValue = $scope.graphValue;
                    var chartObject = {
                        type: 'BarChart',
                        data: {
                            cols: [
                                {id: "t", label: "Competence Name", type: 'string'},
                                {id: "s", label: "AS-IS", type: 'number'}
                            ],
                            rows: null
                        },
                        options: {
                            bars: 'horizontal',
                            backgroundColor: 'transparent',
                            colors: ['#e60004'],
                            titleTextStyle: { fontSize: 14 },
                            legend: { position: 'top', textStyle: {fontSize: 13}},
                            chartArea:{left: '30%', width:'60%', top: '80'},
                            tooltip:{
                                textStyle:{
                                    fontSize:13
                                }
                            },
                            hAxis: {
                                showTextEvery: 1,
                                minValue: 0,
                                maxValue: 5,
                                gridlines: {
                                    count: 5
                                },
                                minorGridlines: {
                                    count: 0
                                },
                                textStyle: { fontSize: 13 },
                                ticks: [
                                    {v: 0, f: ''},
                                    {v: 1, f: getLevelDescription(1)},
                                    {v: 2, f: getLevelDescription(2)},
                                    {v: 3, f: getLevelDescription(3)},
                                    {v: 4, f: getLevelDescription(4)},
                                    {v: 5, f: getLevelDescription(5)}
                                ]
                            },
                            vAxis: {
                                maxTextLines: 1,
                                showTextEvery: 1,
                                //textPosition: 'in',
                                textStyle: { fontSize: 13 }
                            }
                        }
                    };

                    if (graphValue && graphValue.toBeValueList) {
                        chartObject.data.cols.push({id: "u", label: "TO-BE", type: 'number'});
                        chartObject.options.colors = ['#4b4b4b', '#e60004'];
                    }

                    //console.log("Behaviour, Knowledge: Financial Instituions test sorting");
                    angular.forEach(graphValue.asIsValueList, function (competenceValue) {
                        var itemsSorted  = $filter('orderBy')(competenceValue.competencesValueModalList, 'groupId');
                        competenceValue.competencesValueModalList = itemsSorted;
                    });

                    var asIsValueList = graphValue.asIsValueList; //resp.destinationValueList;
                    var toBeValueList = graphValue.toBeValueList; //resp.destinationValueList;

                    $scope.graphs = [];
                    for(var i=0; i < asIsValueList.length; i++) {
                        var rows = [];
                        var copiedChart = angular.copy(chartObject);
                        var currentGroupId = 0;

                        for(var j=0; j < asIsValueList[i].competencesValueModalList.length; j++){
                            var isDummyRowNeeded = false;
                            if (currentGroupId !== asIsValueList[i].competencesValueModalList[j].groupId) isDummyRowNeeded = true;
                            currentGroupId = asIsValueList[i].competencesValueModalList[j].groupId;
                            var rowData = {
                                c: [
                                    { v: asIsValueList[i].competencesValueModalList[j].competenceName.replace("\n", " ").replace(/\s+/g,' ').trim()},
                                    { v: asIsValueList[i].competencesValueModalList[j].rating, f: getLevelDescription(asIsValueList[i].competencesValueModalList[j].rating)} //,
                                    //{ v: toBeValueList[i].competencesValueModalList[j].rating}
                                ]
                            };

                            var dummyRowData = {
                                c: [
                                    { v: currentGroupId},
                                    { v: 0}
                                ]
                            };

                            if (toBeValueList) {
                                var rating = 0;
                                var toBeValue = _.find(toBeValueList, function (item) {
                                    return item.tableName === asIsValueList[i].tableName;
                                });

                                if (toBeValue && toBeValue.competencesValueModalList) {
                                    var toBeValueCompetencesValueModal = _.find(toBeValue.competencesValueModalList, function (item) {
                                        return item.groupId === asIsValueList[i].competencesValueModalList[j].groupId &&
                                            item.competenceName === asIsValueList[i].competencesValueModalList[j].competenceName;
                                    });
                                    if (toBeValueCompetencesValueModal && toBeValueCompetencesValueModal.rating) rating = toBeValueCompetencesValueModal.rating;
                                }
                                rowData.c.push({ v: rating, f: getLevelDescription(rating)});
                                dummyRowData.c.push({ v: 0});
                            }
                            if(isDummyRowNeeded) rows.push(dummyRowData);
                            rows.push(rowData);
                        }

                        copiedChart.data.rows = rows;
                        copiedChart.options.title = $scope.translations['core'][asIsValueList[i].tableName];//$scope.translations['core'][asIsValueList[i].tableName];
                        copiedChart.options.chartArea.height = asIsValueList[i].competencesValueModalList.length * 49;
                        copiedChart.options.height = copiedChart.options.chartArea.height + 200;

                        $scope.graphs.push(copiedChart);
                    }

                    var isProcessed = [];

                    $scope.chartReady = function(chart) {
                        //console.log('chartId', chart.HS.id);
                        isProcessed.push(chart.HS.id);
                        if (isProcessed.length <= $scope.graphs.length) isProcessed = [];
                        else return;

                        renderChart().then(function () {
                            $rootScope.$broadcast('bar-chart:loaded');
                            // if($scope.$parent.options.selectedOption.index == index) {
                            //     chartDiv.style.display = "block";
                            // }else {
                            //     chartDiv.style.display = "none";
                            // }
                            // angular.forEach($scope.$parent.options.availableOptions, function (option, index) {
                                // if (option.div === selectedOption.div) {
                                //     $("chart_div"+index).style.display = "block";
                                // }else{
                                //     $("chart_div"+index).style.display = "none";
                                // }
                                // var chartDiv = document.getElementById(option.div);
                                // if (option.div === "chart_div" + $scope.$parent.options.selectedOption.index) {
                                //     chartDiv.style.display = "block";
                                // } else {
                                //     chartDiv.style.display = "none";
                                // }
                            // });
                        }, function (err) {
                            //do nothing for now when error
                        });

                        function renderChart() {
                            var deferred = $q.defer();
                            angular.forEach($scope.graphs, function (graph, index) {
                                var chartId = 'chart_div' + index;
                                var chartDiv = document.getElementById(chartId);

                                var groupValue = '';
                                var svgParent = chartDiv.getElementsByTagName('svg')[0];
                                var competenceName = '';
                                var isFirstLine = true;

                                Array.prototype.forEach.call(chartDiv.getElementsByTagName('text'), function(text, textIndex) {
                                    var groupLabel;
                                    if ((text.getAttribute('text-anchor') === 'end') && //'start'
                                        (parseFloat(text.getAttribute('x')) < 500)) {

                                        if (!isNaN(text.innerHTML)) {
                                            // console.log("is number", text.innerHTML);
                                            var currentObj = _.find(graphValue.asIsValueList[index].competencesValueModalList, function (item) {
                                                return item.groupId === parseInt(text.innerHTML);
                                            });

                                            var chartGroupId = chartId + "_" + currentObj.groupId;
                                            if (document.getElementById(chartGroupId) === null) {
                                                groupValue = currentObj.groupName;
                                                groupLabel = text.cloneNode(true);
                                                groupLabel.setAttribute('x', '0');
                                                //groupLabel.setAttribute('y', text.getAttribute('y') - 37.5);
                                                groupLabel.setAttribute('id', chartGroupId);
                                                groupLabel.setAttribute('text-anchor', "start");
                                                groupLabel.setAttribute('style', "font-weight: bold");
                                                groupLabel.innerHTML = groupValue;
                                                svgParent.appendChild(groupLabel);
                                                if (!isFirstLine) addGroupLine(groupLabel, -24.5); //-20 one bar //ori -67.5
                                                text.remove();
                                            }
                                        }
                                        isFirstLine = false;



                                        // if ((text.getAttribute('stroke') === 'none')) text.setAttribute('style', 'text-shadow: -1px -1px 2px #fff, 1px 1px 2px #fff, 1px 0px 2px #fff, 0px 1px 2px #fff, 0px 1px 2px #fff,  -1px 0px 2px #fff, 0px -1px 2px #fff, 0px -1px 2px #fff');

                                        // if (text.nextSibling === null || text.nextSibling.tagName !== 'text') {
                                        //     competenceName += text.innerHTML;
                                        //
                                        //     var currentObj = _.find(graphValue.asIsValueList[index].competencesValueModalList, function (item) {
                                        //         return item.competenceName.replace("\n", " ").replace(/\s+/g,' ').trim() === competenceName.replace(/\s+/g,' ').trim();
                                        //     });
                                        //
                                        //     competenceName = '';
                                        //
                                        //     if (currentObj && currentObj.groupName && groupValue !== currentObj.groupName) {
                                        //         var chartGroupId = chartId + "_" + currentObj.groupId;
                                        //         if (document.getElementById(chartGroupId) === null) {
                                        //             groupValue = currentObj.groupName;
                                        //             groupLabel = text.cloneNode(true);
                                        //             groupLabel.setAttribute('x', '0');
                                        //             //groupLabel.setAttribute('y', text.getAttribute('y') - 37.5);
                                        //             groupLabel.setAttribute('id', chartGroupId);
                                        //             groupLabel.setAttribute('text-anchor', "start");
                                        //             groupLabel.setAttribute('style', "font-weight: bold");
                                        //             groupLabel.innerHTML = groupValue;
                                        //             console.log("groupLabel", groupLabel);
                                        //             console.log("svgParent", svgParent);
                                        //             svgParent.appendChild(groupLabel);
                                        //             addGroupLine(groupLabel, -29.5); //-20 one bar //ori -67.5
                                        //         }
                                        //
                                        //     }
                                        //     // if (rowIndex === (data.getNumberOfRows() - 1)) {
                                        //     // 	addGroupLine(text, 16);
                                        //     // }
                                        // } else {
                                        //     competenceName += text.innerHTML + ' ';
                                        // }

                                    }
                                });

                                function addGroupLine(text, yOffset) {
                                    var groupLine = chartDiv.getElementsByTagName('rect')[0].cloneNode(true);
                                    groupLine.setAttribute('y', parseFloat(text.getAttribute('y')) + yOffset);
                                    groupLine.setAttribute('x', '0');
                                    groupLine.setAttribute('height', '0.8');
                                    groupLine.setAttribute('width', '30%'); //198.5
                                    groupLine.setAttribute('stroke', 'none');
                                    groupLine.setAttribute('stroke-width', '0');
                                    groupLine.setAttribute('fill', '#000000');
                                    svgParent.appendChild(groupLine);
                                }
                            });
                            deferred.resolve();
                            return deferred.promise;
                        }

                    };
                }

                function getLevelDescription(level) {
                    
                    switch(parseInt(level)) {
                        case 1:
                            return 'Novice';
                        case 2:
                            return 'Advanced Beginner';
                        case 3:
                            return 'Competent';
                        case 4:
                            return 'Proficient';
                        case 5:
                            return 'Expert';
                        default:
                            return '';
                    }
                }
            }]
    };
});
var app = angular.module('app');

app.directive('radarChart', function () {
    return {
        restrict: 'E',
        templateUrl: '/static/templates/directives/radar-chart.html',
        scope: {
            'graphValue': '=',
            'graphStyle': '@'
        },
        controller: [ '$rootScope', '$scope', '$filter', 'TranslationsService',
            function($rootScope, $scope, $filter, translationsService) {

                $scope.translations = $rootScope.stateChangeStart.data &&
                $rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

                $scope.$watch('graphValue', function () {
                    if ($scope.graphValue) populateGraph();
                    displayGraph();
                    // if ($scope.graphValue) console.log($scope.graphValue);
                });

                var graphList = [];

                function populateGraph() {
                    var graphValue = $scope.graphValue;
                    var currentColor = generateRandomColor();
                    var objList = [];

                    angular.forEach(graphValue.asIsValueList, function(competence, index) {
                        var labelList = [];
                        var groupList = [];
                        var colorList = [];
                        var valueList = [];
                        var groupId = '';
                        var chartTitle = competence.tableName;
                        angular.forEach(competence.competencesValueModalList, function(item) {
                            if (groupId === '') {
                                // first item
                                groupId = item.groupId;
                                colorList.push(currentColor);
                                labelList.push(item.competenceName);
                                valueList.push(item.rating);
                                groupList.push({
                                    groupId: item.groupId,
                                    groupName: item.groupName,
                                    color: currentColor
                                });
                            } else if (groupId != item.groupId) {
                                // check whether this group is inside groupList
                                // if yes, this group is processed
                                // if no, add this group, assign new color
                                var foundGroup = null;
                                angular.forEach(groupList, function(group) {
                                    if (group.groupId == item.groupId) foundGroup = group;
                                });
                                if (foundGroup === null) {
                                    groupId = item.groupId;
                                    currentColor = generateRandomColor();
                                    colorList.push(currentColor);
                                    labelList.push(item.competenceName);
                                    valueList.push(item.rating);
                                    groupList.push({
                                        groupId: item.groupId,
                                        groupName: item.groupName,
                                        color: currentColor
                                    });
                                } else {
                                    colorList.push(foundGroup.color);
                                    valueList.push(item.rating);
                                    labelList.push(item.competenceName);
                                }
                            } else {
                                colorList.push(currentColor);
                                valueList.push(item.rating);
                                labelList.push(item.competenceName);
                            }
                        });
                        var obj = {
                            'chartTitle': $scope.translations['core'][chartTitle],
                            'labelList': labelList,
                            'groupList': groupList,
                            'colorList': colorList,
                            'valueList': valueList
                        };
                        objList.push(obj);
                    });

                    if (graphValue.toBeValueList !== undefined && graphValue.toBeValueList.length !== 0) {
                        angular.forEach(graphValue.toBeValueList, function(competence, index) {
                            var labelList = [];
                            var groupList = [];
                            var colorList = [];
                            var valueList = [];
                            var valueList2 = [];
                            var groupId = '';
                            var chartTitle = competence.tableName;
                            angular.forEach(competence.competencesValueModalList, function(item) {
                                if (groupId === '') {
                                    // first item
                                    groupId = item.groupId;
                                    colorList.push(currentColor);
                                    labelList.push(item.competenceName);
                                    valueList2.push(item.rating);
                                    groupList.push({
                                        groupId: item.groupId,
                                        groupName: item.groupName,
                                        color: currentColor
                                    });
                                } else if (groupId != item.groupId) {
                                    // check whether this group is inside groupList
                                    // if yes, this group is processed
                                    // if no, add this group, assign new color
                                    var foundGroup = null;
                                    angular.forEach(groupList, function(group) {
                                        if (group.groupId == item.groupId) foundGroup = group;
                                    });
                                    if (foundGroup === null) {
                                        groupId = item.groupId;
                                        currentColor = generateRandomColor();
                                        colorList.push(currentColor);
                                        labelList.push(item.competenceName);
                                        valueList2.push(item.rating);
                                        groupList.push({
                                            groupId: item.groupId,
                                            groupName: item.groupName,
                                            color: currentColor
                                        });
                                    } else {
                                        colorList.push(foundGroup.color);
                                        valueList2.push(item.rating);
                                        labelList.push(item.competenceName);
                                    }
                                } else {
                                    colorList.push(currentColor);
                                    valueList2.push(item.rating);
                                    labelList.push(item.competenceName);
                                }
                            });
                            objList[index]['valueList2'] = valueList2;
                        });
                    }
                    graphList = objList;
                }

                function displayGraph() {
                    var x = 15 - graphList.length;
                    if (x > 0) {
                        for (var i = 15 ; i >= graphList.length; i--) {
                            var exclude_radar_chart_div = document.getElementById('radar_chart_div' + i);
                            var legend_div = document.getElementById('legend_div' + i);
                            exclude_radar_chart_div.hidden = true;
                            legend_div.hidden = true;
                        }
                    }
                    angular.forEach(graphList, function(graph, index) {
                        var labelData = [];
                        if (graph.valueList2 !== undefined && graph.valueList2.length !== 0) {
                            labelData.push(
                                {
                                    label: 'AS-IS',
                                    data: graph.valueList,
                                    backgroundColor: 'rgba(75, 75, 75, 0.2)',
                                    borderColor: 'rgb(75, 75, 75)'
                                },
                                {
                                    label: 'TO-BE',
                                    data: graph.valueList2,
                                    backgroundColor: 'rgba(230, 0, 4, 0.2)',
                                    borderColor: 'rgba(230, 0, 4)'
                                }
                            );
                        } else {
                            labelData.push(
                                {
                                    label: 'AS-IS',
                                    data: graph.valueList,
                                    backgroundColor: 'rgba(230, 0, 4, 0.2)',
                                    borderColor: 'rgba(230, 0, 4)'
                                }
                            );
                        }
                        // if (graph.groupList.length !== 0) {
                        //     angular.forEach(graph.groupList, function (group) {
                        //         var label = {
                        //             label: group.groupName,
                        //             backgroundColor: group.color,
                        //             borderColor: group.color
                        //         };
                        //         labelData.push(label);
                        //     });
                        // }
                        var chartId = 'radar_chart_div' + index;
                        var chartDiv = document.getElementById(chartId);
                        var legendId = 'legend_div' + index;
                        var legendDiv = document.getElementById(legendId);
                        var chartOption = null;
                        var legends = [];
                        if (!graph.colorList.length || !graph.groupList.length || !graph.labelList.length || !graph.valueList.length) {
                            var ctx = chartDiv.getContext("2d");
                            chartDiv.height = 100;
                            ctx.font = "30px Arial";
                            ctx.fillText("No data available", 125, 50);
                            legendDiv.hidden = true;
                        } else {
                            if (graph.valueList2 !== undefined && graph.valueList2.length !== 0) {
                                chartOption = {
                                    type: 'radar',
                                    data: {
                                        labels: graph.labelList,
                                        datasets: labelData
                                    },
                                    options: {
                                        legend: {
                                            position: 'top'
                                        },
                                        title: {
                                            display: true,
                                            text: graph.chartTitle,
                                            fontSize: 14
                                        },
                                        scale: {
                                            ticks: {
                                                max: 5,
                                                min: 0,
                                                stepSize: 1
                                            },
                                            pointLabels: {
                                                display: true,
                                                fontColor: graph.colorList
                                            }
                                        }
                                    }
                                };
                            } else {
                                chartOption = {
                                    type: 'radar',
                                    data: {
                                        labels: graph.labelList,
                                        datasets: labelData
                                    },
                                    options: {
                                        legend: {
                                            position: 'top'
                                        },
                                        title: {
                                            display: true,
                                            text: graph.chartTitle,
                                            fontSize: 14
                                        },
                                        scale: {
                                            ticks: {
                                                max: 5,
                                                min: 0,
                                                stepSize: 1
                                            },
                                            pointLabels: {
                                                display: true,
                                                fontColor: graph.colorList
                                            }
                                        }
                                    }
                                };
                            }
                            var myChart = new Chart(chartDiv, chartOption);

                            //draw legend
                            angular.forEach(graph.groupList, function (group) {
                                legends.push(new Legend(0, 30, 25, 25, group.color, group.groupName));
                            });
                            var legend_div = document.getElementById('legend_div' + index);
                            var context = legend_div.getContext('2d');
                            var x = 10;
                            for (var i in legends) {
                                var legend = legends[i];
                                y = x + legend.x + Math.round(context.measureText(legend.text).width) + 40;
                                if (y >= 835) {
                                    console.log("new line here");
                                } else {
                                    context.fillStyle = legend.fill;
                                    context.fillRect(legend.x + x, legend.y, legend.w, legend.h);
                                    context.font = "12px Arial";
                                    context.fillText(legend.text, legend.x + x + 30, legend.y + 15);
                                    x += legend.x + Math.round(context.measureText(legend.text).width) + 40;
                                }
                            }
                        }
                    });
                    $rootScope.$broadcast('radar-chart:loaded');
                }

                function Legend(x, y, w, h, fill, text) {
                    this.x = x;
                    this.y = y;
                    this.w = w;
                    this.h = h;
                    this.fill = fill;
                    this.text = text;
                }

                function generateRandomColor() {
                    var randomColor = Math.floor(Math.random()*16777215).toString(16);
                    return "#" + randomColor;
                }

                function capitalizeFirstLetter(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                }
            }]
    };
});
var app = angular.module('app');

app.directive('staticContent', function () {
	return {
		restrict: 'E',
		templateUrl: '/static/templates/directives/static-content.html',
		scope: {
			'packageName': '@',
			'key': '@',
			'replaceItems': '=',
			'displayGraph': '@'
		},
		controller: [ '$rootScope', '$scope', '$sce', 'TranslationsService', 'RolesManager', '$compile',
		              function($rootScope, $scope, $sce, translationsService, rolesManager, $compile) {
			
			$scope.content = null;
			$scope.editUrl = null;
			$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
			$scope.canEditContent = rolesManager.hasRoles($rootScope.user, ['administrator', 'care-management']) && !$scope.spocCareUserAccess;
			$scope.translations = $rootScope.stateChangeStart.data && 
				$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
			function loadContent() {
				var pkgName = $scope.packageName;
				var key = $scope.key;

				if (pkgName && key && $scope.translations[pkgName] &&
						$scope.translations[pkgName][key]) {
					
					if($scope.translations[pkgName][key].match(/(\${google[Dd]oc:)(.*)(})$/)){
						pkgName = "core";
						key = "fail-to-load-static-content";
					}
					
					if($scope.displayGraph === 'true'){
						if($scope.replaceItems){
							$scope.graph1 = $scope.replaceItems[0];
							$scope.graph2 = $scope.replaceItems[1];
							$scope.graph3 = $scope.replaceItems[2];
							$scope.graph4 = $scope.replaceItems[3];
						}

						$scope.content = $scope.translations[pkgName][key].replace('#!CHART_SELFMANAGEMENT!#','')
							.replace('#!CHART_BUSINESS!#','')
							.replace('#!CHART_METHODOLOGY!#','')
							.replace('#!CHART_TECHNICAL_KNOWLEDGE!#','');

						// $scope.content = $scope.translations[pkgName][key].replace('#!CHART_SELFMANAGEMENT!#',' <div id="google-chart-space1" google-chart chart="graph1" ></div> ')
						// 				.replace('#!CHART_BUSINESS!#',' <div id="google-chart-space2" google-chart chart="graph2" ></div> ')
						// 				.replace('#!CHART_METHODOLOGY!#',' <div id="google-chart-space3" google-chart chart="graph3" ></div> ')
						// 				.replace('#!CHART_TECHNICAL_KNOWLEDGE!#',' <div id="google-chart-space4" google-chart chart="graph4" ></div> ');


						for(var i = 1; i <= 4; i++){
							var chart = angular.element(document.getElementById('google-chart-space'+ i));
							var el = $compile( chart )( $scope );
							chart.replaceWith(el);
						}

						$scope.content = $sce.trustAsHtml($scope.content);
					}
					else{
						$scope.content = $sce.trustAsHtml($scope.translations[pkgName][key]);
					}

					var docId = translationsService.getCachedStaticDocId(pkgName, key);
					$scope.editUrl = 'https://docs.google.com/document/d/' + docId + '/edit';
				}
			}
			
			loadContent();
			

			$scope.$watch('key', function () {
				loadContent();
			});		
			
			$scope.$watch('replaceItems', function () {
				loadContent();
			});		
			
		}]
	};
});
var app = angular.module('app');

app.controller('HomeController', [ '$rootScope', '$scope', '$state', '$modal', '$window', 'RolesManager', 'GApi', 'NotificationsService',
                                     function($rootScope, $scope, $state, $modal, $window, rolesManager, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	var modalInstance;
	$scope.processId = 0;
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.circleLoading = true;
	$scope.showCircularPage = true;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;

	/*********************************Layout*********************************/
//	$scope.getProcessContainerClass = function () {				
//		if (!$scope.cycle || !$scope.cycle.processes || !_.isArray($scope.cycle.processes)) return '';
//		else return 'col-xs-' + Math.floor(12 / $scope.cycle.processes.length);		
//	};
	
/*	$scope.getProcessHeaderClass = function (process) {		
		return 'process-header ' + process.status;		
	};*/
	
	$scope.getStepIcon = function (step) {
		switch (step.status) {
			case 'not-started':
				return 'mdi-toggle-check-box-outline-blank';
			case 'ongoing':
				return 'mdi-action-schedule';
			case 'done':
			case 'closed':
				return 'mdi-toggle-check-box';
		}
	};
	
	/*$scope.canStartNextProcess = function (process) {
		return process.sortOrder === 1;
	};*/
	
	/*********************************Cycle Working*********************************/
	GApi.executeAuth('lmi2Api', 'cycleProcess.addCycleProcess', {
	}).then(function() {
		getCycleProcessStatus();
	}, function (err) {
		NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
	});	
	
	//Step1
	function getCycleProcessStatus(){
		GApi.executeAuth('lmi2Api', 'cycleProcess.getCycleProcessStatus', { 
			//id: $rootScope.user.cycleId,
			//name: $scope.cycleModel.name,
			//status: $scope.cycleModel.status,
			processes: $scope.cycleModel.processes
		}).then(function(resp) {
			$scope.cycle = resp;
            $scope.processId = resp.currentProcessId;
			loadButtonName();
			$scope.getCycleGroup();
			//$scope.circleLoading = false;
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	}
	
	//Step2
	//Begin - CycleGroup Dropdownlist
	$scope.getCycleGroup = function () {
		$scope.cycleGroup = null;
		
		GApi.executeAuth('lmi2Api', 'cycleProcess.getDocumentPlanList', { }).then(function(resp) {
			if(resp.items){
				$scope.cycleGroupList = resp.items;
			}
			$scope.selectedCycleGroup = '0';
			$scope.checkcheckPendingRequestNewCycle();
			//$scope.circleLoading = false;
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	
		
	};
	
	$scope.displayDocumentPlan = function (cycleGroupStr) {
		var cycleGroup = JSON.parse(cycleGroupStr);
		if(cycleGroupStr && cycleGroupStr !== '0'){
			$scope.editReturnDocumentUrl = 'https://docs.google.com/document/d/' + cycleGroup.googleDocId + '/edit';
	 		$window.open($scope.editReturnDocumentUrl, '_blank');
		}
	};
	//End - CycleGroup Dropdownlist
	
	//Step3
	$scope.checkcheckPendingRequestNewCycle = function () {
		GApi.executeAuth('lmi2Api', 'cycleProcess.checkPendingRequest', { }).then(function(resp) {
			if(resp.items[0] === "err-msg-request-still-pending"){
				$scope.showCircularPage = false;
			}
			else{
				$scope.showCircularPage = true;
			}
			$scope.circleLoading = false;
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	
		
	};
	
	//B4 migration, if the model got any changes, please change the model in MigrationService also
	$scope.cycleModel = {
		//id: $rootScope.user.cycleId,
		name: '2015/11',
		userId: $rootScope.user.id,
		status: 'not-started',
		currentProcessId: 0,
		processes: [{
					id: 1,
		            nameKey: 'process-name-prepare',
		            sortOrder: 1,
		            status: 'not-started',
		            steps: [{
		            		id: 1,
		            		nameKey: 'process-1-step-1-name',
		            		state: 'myAchievements.list',
		            		sortOrder: 1,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 2,
		            		nameKey: 'process-1-step-2-name',
		            		state: 'myCompetences.list',
		            		sortOrder: 2,
		            		status: 'not-started'
		            	},	
		            	{
		            		id: 3,
		            		nameKey: 'process-1-step-3-name',
		            		state: 'otherCompetences.list',
		            		sortOrder: 3,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 4,
		            		nameKey: 'process-1-step-4-name',
		            		state: 'myPersonality.home',
		            		sortOrder: 4,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 5,
		            		nameKey: 'process-1-step-5-name',
		            		state: 'myContext.list',
		            		sortOrder: 5,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 6,
		            		nameKey: 'process-1-step-6-name',
		            		state: 'myDestination.home',
		            		sortOrder: 6,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 7,
		            		nameKey: 'process-1-step-7-name',
		            		state: 'realizeMyPlan.home',
		            		sortOrder: 7,
		            		status: 'not-started'
		            	}]
				}, 
				{
					id: 2,
		            name: 'Refine',
		            nameKey: 'process-name-refine',
		            sortOrder: 2,
		            status: 'not-started',
		            steps: [{
		            		id: 8,
		            		nameKey: 'process-2-step-1-name',
		            		state: 'contactCoach.invite',
		            		sortOrder: 1,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 9,
		            		nameKey: 'process-2-step-2-name',
		            		state: 'myAchievements.list',
		            		sortOrder: 2,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 10,
		            		nameKey: 'process-2-step-3-name',
		            		state: 'myCompetences.list',
		            		sortOrder: 3,
		           			status: 'not-started'
		            	},
		            	{
		           			id: 11,
		           			nameKey: 'process-2-step-4-name',
		           			state: 'otherCompetences.list',
		           			sortOrder: 4,
		           			status: 'not-started'
		            	},
		            	{
		            		id: 12,
		            		nameKey: 'process-2-step-5-name',
		            		state: 'myPersonality.home',
		            		sortOrder: 5,
		            		status: 'not-started'
		            	},
		            	{
		           			id: 13,
		           			nameKey: 'process-2-step-6-name',
		           			state: 'myContext.list',
		           			sortOrder: 6,
		       				status: 'not-started'
		            	},
		            	{
		            		id: 14,
		            		nameKey: 'process-2-step-7-name',
		            		state: 'myDestination.home',
		            		sortOrder: 7,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 15,
		            		nameKey: 'process-2-step-8-name',
		            		state: 'realizeMyPlan.home',
		            		sortOrder: 8,
		            		status: 'not-started'
		            	}]
				},
				{
					id: 3,
		            name: 'Align',
		            nameKey: 'process-name-align',
		            sortOrder: 3,
		            status: 'not-started',
		            steps: [/*{
		            		id: 16,
		            		nameKey: 'process-3-step-1-name',
		            		state: null,
		            		sortOrder: 1,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 17,
		            		nameKey: 'process-3-step-2-name',
		            		state: null,
		            		sortOrder: 2,
		            		status: 'not-started'
		            	},
		            	{
		            		id: 18,
		            		nameKey: 'process-3-step-3-name',
		            		state: null,
		            		sortOrder: 3,
		            		status: 'not-started'
		            	},*/
		            	{
		            		id: 19,
		            		nameKey: 'process-3-step-4-name',
		            		state: 'realizeMyPlan.home',
		            		sortOrder: 4,
		            		status: 'not-started'
		            	}]
				},
				{
					id: 4,
		            name: 'Realize',
		            nameKey: 'process-name-realize',
		            sortOrder: 4,
		            status: 'not-started',
		            steps: [{
		            		id: 20,
		            		nameKey: 'process-4-step-1-name',
		            		state: 'realizationInsight.list',
		            		sortOrder: 1,
		            		status: 'not-started'
		            	}/*,
		            	{
		            		id: 21,
		            		nameKey: 'process-4-step-2-name',
		            		state: 'realizationInsight.list',
		            		sortOrder: 2,
		            		status: 'not-started'
		            	}*/]
				}]
		};
		

		
		
		
		
	
	/*********************************Process Working*********************************/
	
	function loadButtonName() {
		if($scope.processId === 1){
			$scope.firstButtonName = 'btn-preview-plan';
			$scope.secondButtonName = 'btn-start-refine';
		}
		else if($scope.processId===2){
			$scope.firstButtonName = 'btn-preview-plan';
			$scope.secondButtonName = 'btn-start-aligning';
		}
		else if($scope.processId===3){
			$scope.firstButtonName = 'btn-preview-objectives';
			$scope.secondButtonName = 'btn-start-realization';
		}
		else if($scope.processId===4){
			$scope.firstButtonName = 'btn-preview-plan';
			$scope.secondButtonName = 'btn-request-new-cycle';
		}
	}
	
	
	$scope.startProcess = function(){
		if($scope.processId===1){
			//Popup first only do checking
			confirmPrepareToRefineOrAlign();
		}
		else if($scope.processId===2){
			//startAlign();
			//Do checking first only popup
			$scope.checkProcessAllDoneOrProceed(3,false);
		}
		else if($scope.processId===3){
			//startRealize();
			//Do checking first only popup
			$scope.checkProcessAllDoneOrProceed(4,false);
		}
		else if($scope.processId===4){
			//confirmRequestNewCycle();
			//Do checking first only popup
			$scope.checkProcessAllDoneOrProceed(1,false); //restart new cycle become 1
		}
	};
	
	function confirmPrepareToRefineOrAlign() {
		$scope.processPopupTitle = $scope.translations.home['btn-start-refine'];
		//$scope.processPopupMsg = $scope.translations.home['popup-refine-plan-confirmation'];
        $scope.processPopupMsg = $scope.translations.home['popup-refine-plan-confirmation-style'];
		$scope.processPopupButton = $scope.translations.home['btn-proceed-refinement'];
		$('#confirmStartNextProcess').modal('show');	
	}
	
	function confirmRefineToAlign() {
		$scope.processPopupTitle = $scope.translations.home['btn-start-aligning'];
		$scope.processPopupMsg = $scope.translations.home['popup-align-plan-confirmation'];
		$('#confirmStartNextProcess').modal('show');	
	}
	
	function confirmAlignToRealize() {
		$scope.processPopupTitle = $scope.translations.home['btn-start-realization'];
		$scope.processPopupMsg = $scope.translations.home['popup-realize-plan-confirmation'];
		$scope.processPopupButton = $scope.translations.home['btn-proceed-realization'];
		$('#confirmStartNextProcess').modal('show');	
	}
	
	function confirmRequestNewCycle() {
		$scope.processPopupTitle = $scope.translations.home['btn-request-new-cycle'];
		$scope.processPopupMsg = $scope.translations.home['popup-request-new-cycle'];
		$scope.processPopupButton = $scope.translations.home['btn-proceed-request-new-cycle'];
		$('#confirmStartNextProcess').modal('show');
	}
	
	function startRefine() {
		$scope.processId = 2;
		loadButtonName();
	}
	
	function startAlign() {
		$scope.processId = 3;
		loadButtonName();
	}
	
	function startRealize() {
		$scope.processId = 4;
		loadButtonName();
	}
	
	$scope.requestNewCycle = function () {
		
	};
	
	//From Prepare -> Refine or Prepare -> Align ; From Refine -> Align
	$scope.checkProcessAllDoneOrProceed = function (processId,fromPopup) {
		var currentProcess = $scope.cycle.processes[$scope.processId-1]; //array need to -1 to get actual list
		var nextProcessProcess = $scope.cycle.processes[processId-1];
		
		if(processId === 2){	//Proceed to Refine
			proceedToNextProcess(currentProcess.nameKey, nextProcessProcess.nameKey, $scope.processId, true);
			startRefine();
			$('#confirmStartNextProcess').modal('hide');
		}
		else if(processId === 3 || processId === 4 || processId === 1){ //Proceed to Align(3) / Proceed to Realization (4) / Restart New Cycle (back to 1)
			
			if(($scope.processId === 1) || ($scope.processId === 2 && !fromPopup) || ($scope.processId === 3 && !fromPopup) || ($scope.processId === 4 && !fromPopup)){ //$scope.processId = current process
				//make sure all is "done" before Prepare -> Align / Refine -> Align
				GApi.executeAuth('lmi2Api', 'cycleProcess.checkProcessAllDone', { 
					steps: currentProcess.steps,
					processes: currentProcess.nameKey
				}).then(function(resp) {
					if(resp.items[0] === "ok"){
						if($scope.processId === 1){ //Current process : Prepare 
							proceedToNextProcess(currentProcess.nameKey, nextProcessProcess.nameKey, $scope.processId, true);
							startAlign();
							$('#confirmStartNextProcess').modal('hide');
						}
						else if($scope.processId === 2){ //Current process : Refine
							//Checking pass, only popup confirmation
							confirmRefineToAlign();
						}
						else if($scope.processId === 3){ //Current process : Align
							//Checking pass, only popup confirmation
							confirmAlignToRealize();
						}
						else if($scope.processId === 4){ //Current process : Realize
							//Checking pass, only popup confirmation
							confirmRequestNewCycle();
						}
						
					}
					else if(resp.items[0] === "nok"){
						if($scope.processId === 1){ //Current process : Prepare 
							$scope.processErrPopupMsg = $scope.translations.home['err-msg-prepare-to-align'];
							$('#notAllowToStartNextProcess').modal();
						}
						else if($scope.processId === 2){ //Current process : Refine
							$scope.processErrPopupMsg = $scope.translations.home['err-msg-refine-to-align'];
							$('#notAllowToStartNextProcess').modal();
						}
						else if($scope.processId === 3){ //Current process : Align
							$scope.processErrPopupMsg = $scope.translations.home['err-msg-align-to-realize'];
							$('#notAllowToStartNextProcess').modal();
						}
					}
					else if(resp.items[0] === "err-msg-unit-leader-done"){ //Only Current process : Align got this error message 
						$scope.processErrPopupMsg = $scope.translations.home['err-msg-unit-leader-done'];
						$('#notAllowToStartNextProcess').modal();
					}
					else if(resp.items[0] === "err-msg-complete-at-least-one-objective"){
						$scope.processErrPopupMsg = $scope.translations.home['err-msg-complete-at-least-one-objective'];
						$('#notAllowToStartNextProcess').modal();
					}
					else if(resp.items[0] === "err-msg-request-still-pending"){
						$scope.processErrPopupMsg = $scope.translations.home['err-msg-request-still-pending'];
						$('#notAllowToStartNextProcess').modal();
					}

				}, function (err) {
					NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
				});			
			}
			else if(fromPopup){ //$scope.processId === 4 at another function, no need create lated document and no need update the cycle's status 
				if($scope.processId === 2){
					proceedToNextProcess(currentProcess.nameKey, nextProcessProcess.nameKey, $scope.processId, true);
					startAlign();
					$('#confirmStartNextProcess').modal('hide');
				}
				else if($scope.processId === 3){
					proceedToNextProcess(currentProcess.nameKey, nextProcessProcess.nameKey, $scope.processId, true);
					startRealize();
					$('#confirmStartNextProcess').modal('hide');
				}
				else if($scope.processId === 4){
					proceedToNextProcess(currentProcess.nameKey, nextProcessProcess.nameKey, $scope.processId, true);
					$('#confirmStartNextProcess').modal('hide');
				}
			}
			
		}
		
	};
	
	$scope.closeTwoDialog = function (){
		$('#notAllowToStartNextProcess').modal('hide');
		$('#confirmStartNextProcess').modal('hide');
	};
	
	//update current process -> done, update next process -> ongoing
	function proceedToNextProcess (currentProcessNameKey, nextProcessNameKey, processId, generateDoc){
		$scope.circleLoading = true;

		//$scope.generateTemplate(processId,isOpenNewTab,isCloseProcess,isRequestNewCycle,currentProcessNameKey,nextProcessNameKey)
		if(processId === 4){ //Request new cycle
			$scope.generateTemplate(processId, false, false, true, currentProcessNameKey, null);
		}
		else{
			$scope.generateTemplate(processId, false, true, false, currentProcessNameKey, nextProcessNameKey);
		}
		
	}
	
	
	
	/*********************************Step Working*********************************/
	$scope.checkingProceedStep = function (step){
		if(step.nameKey === 'process-1-step-3-name' || step.nameKey === 'process-1-step-6-name' ||  	//Prepare process
				step.nameKey === 'process-2-step-4-name' || step.nameKey === 'process-2-step-7-name'){	//Refine process
			GApi.executeAuth('lmi2Api', 'cycleProcess.checkProceed', { 
				nameKey: step.nameKey
			}).then(function(resp) {
				if(resp.items[0] === "ok"){
					$scope.goStepState(step);
				}
				else{

					if(step.nameKey === 'process-1-step-3-name' || step.nameKey === 'process-2-step-4-name'){
                        if(step.nameKey === 'process-1-step-3-name') {
                            $scope.notAllowProceedMsg = $scope.translations.core['msg-complete-my-competences'];
                        }else{
                            $scope.notAllowProceedMsg = $scope.translations.core['msg-complete-previous-step'];
						}
					}
					else if(step.nameKey === 'process-1-step-6-name' || step.nameKey === 'process-2-step-7-name'){
						$scope.notAllowProceedMsg = $scope.translations.core['msg-complete-other-competences'];
					}
						
					$('#notAllowToProceedDialog').modal();
				}
			}, function (err) {
				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			});	
		}
		else{
			GApi.executeAuth('lmi2Api', 'cycleProcess.startProcess', {
				nameKey: step.nameKey
			}).then(function(resp) {

			}, function (err) {
				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			});
			$scope.goStepState(step);
		}
	};
	
	$scope.goStepState = function (step) {
		if (step.state) $state.go(step.state);
	};

	
	/*$scope.hasNextProcess = function (process) {

		//TEMPORARY HIDE THE BUTTON FIRST!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
		if(process.nameKey !== 'process-name-prepare'){
			return false;
		}
		
		var currentProcessSortOrder = process.sortOrder;
		var nextProcess = _.find($scope.cycle.processes, function (item) {
			return item.sortOrder === currentProcessSortOrder + 1;
		});
				
		return nextProcess !== null && nextProcess !== undefined;
	};
	
	$scope.getNextProcessName = function (process) {
		var currentProcessSortOrder = process.sortOrder;
		var nextProcess = _.find($scope.cycle.processes, function (item) {
			return item.sortOrder === currentProcessSortOrder + 1;
		});
		
		if (nextProcess) return $scope.translations.home[nextProcess.nameKey];
		else return '';
	};*/

	
	
	
		
	/*********************************Prepare - Preview Plan*********************************/
	
	$scope.isPreviewing = false;
	$scope.isBackendDocGenerating = false;
	
	function isObjEmpty(obj){
		if(obj && obj !== undefined && obj !== ''){
			return false;
		}
		else{
			return true;
		}
	}
	
	$scope.generateTemplate = function(processId,isOpenNewTab,isCloseProcess,isRequestNewCycle,currentProcessNameKey,nextProcessNameKey){
		// if (processId == 3) {
		$('#longProcessDialog').modal();
		// }
		if($scope.cycle.id && $scope.cycle.userId){
			if(isObjEmpty($scope.cycle.templateId)){
				NotificationsService.show($scope.translations.home['plan-template-id-empty-err-msg'], NOTIFICATIONS_FADEOUT_TIMEOUT);
			}
			else{
				if(isOpenNewTab){
					$scope.isPreviewing = true;
				}
				else{
					$scope.isBackendDocGenerating = true;
					NotificationsService.show($scope.translations.home['msg-pending-generating-plan'], 0);
				}
				$scope.returnDocumentId = null;
				//http://localhost:8080/prepare/preview-plan?userId=110352386112605004523&cycleId=18
				//var zdData = { 'Accept': 'application/javascript', 'contentType': 'application/javascript', 'Access-Control-Allow-Credentials': 'true', 'cors':'true', 'dataType': 'jsonp', 'proxy_v2': 'true' };
				//var SCRIPT_URL = 'https://script.google.com/a/macros/mincko.com/s/AKfycbwwGNuisSAIFfD4-ZBNWZPiLgYDrGxeQSnlsfa1h3Y6jIw0pGov/exec?';
				// var SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbx5FJeOr_1v07cCkifagKak3Yt9mTwkiXXFa521K6yT8P6caFDO/exec?';

				// TEST SERVER
				var SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbw4RBhVZj2Y7JPi3WVzYodqdy5n_4YgGYP4Kg71KIxnLerjfv2PQkIIVwJLxrgGTzEZ/exec?';

				// PRD SERVER
				// var SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbxSqkIYa2WFM7EHOiQfg2ColZ1Gw_MuOGBAYb0vx53ZAv4_4s5RmUZ17SCdjYxBdRBa/exec?';

				var passParameter = 'userId=' + $scope.cycle.userId + '&cycleId=' + $scope.cycle.id + '&systemURL=' + $scope.cycle.systemURL + '&folderId=' + $scope.cycle.previewPlanFolderId + '&templateId=' + $scope.cycle.templateId + '&callback=?';
				//	var testingParameter = 'userId=117715479908204760164&cycleId=9&callback=?'; //tst data
				//https://script.google.com/macros/s/AKfycbx5FJeOr_1v07cCkifagKak3Yt9mTwkiXXFa521K6yT8P6caFDO/exec?userId=110352386112605004523&cycleId=266&systemURL=trifinance-lmi2-prd.appspot.com&folderId=0B9vnZgZ1tobZdnp4dV9wRkg0TzA&templateId=1kyeSctW66hV5ysqmOZgBULnWGiNXSpXnS9pfKzj08CQ&callback=?
				var FULL_URL = SCRIPT_URL + passParameter;//testingParameter;
				/* $.getJSON(SCRIPT_URL,
			          function (data) { 
					 	$scope.returnDocumentId = data;
					 	
					 	if($scope.returnDocumentId){
					 		$scope.isPreviewing = false;
					 		$scope.editReturnDocumentUrl = 'https://docs.google.com/document/d/' + $scope.returnDocumentId + '/edit';
					 		$window.open($scope.editReturnDocumentUrl, '_blank');
						}
					 	$scope.isPreviewing = false;
					 	
			     }).done(function(d) {
		             alert("success");
		         }).fail(function(d, textStatus, error) {
		              console.error("getJSON failed, status: " + textStatus + ", error: "+error)
		         }).always(function(d) {
		        	 $scope.isPreviewing = false;
		             alert("complete");
		         });*/
				
				 $.ajax({
					 	async: false,
					    url: FULL_URL,
					    dataType: 'json',
					    success: function( data ) {
							$('#longProcessDialog').modal('hide');
					    	$scope.returnDocumentId = data;

						 	if($scope.returnDocumentId){
						 		if(isRequestNewCycle){
						 			$scope.isBackendDocGenerating = true;
						 			NotificationsService.show($scope.translations.home['msg-pending-generating-plan'], 0);
						 			GApi.executeAuth('lmi2Api', 'requestNewCycle.saveRequestNewCycle', { 
						 				//For Save Latest DocumentPlan
						 				currentProcessNameKey: currentProcessNameKey,
						 				googleDocId: $scope.returnDocumentId
						 			}).then(function(resp) {
						 				getCycleProcessStatus();
						 				NotificationsService.show($scope.translations.home['msg-restart-cycle-request-success'], NOTIFICATIONS_FADEOUT_TIMEOUT);
						 			}, function (err) {
						 				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
						 			});
						 		}
						 		else{
						 			if(isCloseProcess){
							 			GApi.executeAuth('lmi2Api', 'cycleProcess.proceedToNextProcess', { 
							 				//Proceed to Next Cycle
							 				currentProcessNameKey: currentProcessNameKey,
							 				nextProcessNameKey: nextProcessNameKey,
							 				//For Save Latest DocumentPlan
							 				googleDocId: $scope.returnDocumentId
							 			}).then(function(resp) {
							 				getCycleProcessStatus();
							 			}, function (err) {
							 				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
							 			});
							 		}
							 		else{
							 			saveDocumentPlan($scope.returnDocumentId,$scope.cycle.processes[processId-1].nameKey, isOpenNewTab);
							 		}
						 		}
						 		
							}
						 	$scope.$apply(function(){
						 		if(isOpenNewTab){
						 			$scope.isPreviewing = false;
						 		}
						 		else{
						 			$scope.isBackendDocGenerating = false;
						 			NotificationsService.hide();
						 		}
					            
					        });
					    },
					    error: function(d,textStatus, error) {
							$('#longProcessDialog').modal('hide');
					    	$scope.$apply(function(){
					    		NotificationsService.show("failed, status: " + textStatus + ", error: "+error, NOTIFICATIONS_FADEOUT_TIMEOUT);
					    		if(isOpenNewTab){
					    			$scope.isPreviewing = false;
					    		}
					    		else{
					    			$scope.isBackendDocGenerating = false;
					    			NotificationsService.hide();
					    		}
					    		
					        });
					    }
				 });
				
				
				
			}
			
		}
	

    };

    function saveDocumentPlan(googleDocId, processNameKey, isOpenNewTab){
		NotificationsService.show("Template generated, saving template plan...", 10000);
    	GApi.executeAuth('lmi2Api', 'cycleProcess.saveDocumentPlan', { 
			googleDocId: googleDocId,
			processNameKey : processNameKey
		}).then(function(resp) {
			if(isOpenNewTab){
				$scope.editReturnDocumentUrl = 'https://docs.google.com/document/d/' + googleDocId + '/edit';
		 		$window.open($scope.editReturnDocumentUrl, '_blank');
				NotificationsService.show("Template plan is saved and generated successfully.", 10000);
			}
			$('#previewPlanGeneratedDialog').modal();
			NotificationsService.show("Template plan saved successfully.", 10000);
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
    }
	
} ]);

/* globals jQuery */

(function($) {
  // Selector to select only not already processed elements
  $.expr[":"].notmdproc = function(obj){
    if ($(obj).data("mdproc")) {
      return false;
    } else {
      return true;
    }
  };

  function _isChar(evt) {
    if (typeof evt.which == "undefined") {
      return true;
    } else if (typeof evt.which == "number" && evt.which > 0) {
      return !evt.ctrlKey && !evt.metaKey && !evt.altKey && evt.which != 8 && evt.which != 9;
    }
    return false;
  }

  $.material =  {
    "options": {
      // These options set what will be started by $.material.init()
      "input": true,
      "ripples": true,
      "checkbox": true,
      "togglebutton": true,
      "radio": true,
      "arrive": true,
      "autofill": false,

      "withRipples": [
        ".btn:not(.btn-link)",
        ".card-image",
        ".navbar a:not(.withoutripple)",
        ".dropdown-menu a",
        ".nav-tabs a:not(.withoutripple)",
        ".withripple"
      ].join(","),
      "inputElements": "input.form-control, textarea.form-control, select.form-control",
      "checkboxElements": ".checkbox > label > input[type=checkbox]",
      "togglebuttonElements": ".togglebutton > label > input[type=checkbox]",
      "radioElements": ".radio > label > input[type=radio]"
    },
    "checkbox": function(selector) {
      // Add fake-checkbox to material checkboxes
      $((selector) ? selector : this.options.checkboxElements)
      .filter(":notmdproc")
      .data("mdproc", true)
      .after("<span class=checkbox-material><span class=check></span></span>");
    },
    "togglebutton": function(selector) {
      // Add fake-checkbox to material checkboxes
      $((selector) ? selector : this.options.togglebuttonElements)
      .filter(":notmdproc")
      .data("mdproc", true)
      .after("<span class=toggle></span>");
    },
    "radio": function(selector) {
      // Add fake-radio to material radios
      $((selector) ? selector : this.options.radioElements)
      .filter(":notmdproc")
      .data("mdproc", true)
      .after("<span class=circle></span><span class=check></span>");
    },
    "input": function(selector) {
      $((selector) ? selector : this.options.inputElements)
      .filter(":notmdproc")
      .data("mdproc", true)
      .each( function() {
        var $this = $(this);

        if (!$(this).attr("data-hint") && !$this.hasClass("floating-label")) {
          return;
        }
        $this.wrap("<div class=form-control-wrapper></div>");
        $this.after("<span class=material-input></span>");

        // Add floating label if required
        if ($this.hasClass("floating-label")) {
          var placeholder = $this.attr("placeholder");
          $this.attr("placeholder", null).removeClass("floating-label");
          $this.after("<div class=floating-label>" + placeholder + "</div>");
        }

        // Add hint label if required
        if ($this.attr("data-hint")) {
          $this.after("<div class=hint>" + $this.attr("data-hint") + "</div>");
        }

        // Set as empty if is empty (damn I must improve this...)
        if ($this.val() === null || $this.val() == "undefined" || $this.val() === "") {
          $this.addClass("empty");
        }

        // Support for file input
        if ($this.parent().next().is("[type=file]")) {
          $this.parent().addClass("fileinput");
          var $input = $this.parent().next().detach();
          $this.after($input);
        }
      });

      $(document)
      .on("change", ".checkbox input[type=checkbox]", function() { $(this).blur(); })
      .on("keydown paste", ".form-control", function(e) {
        if(_isChar(e)) {
          $(this).removeClass("empty");
        }
      })
      .on("keyup change", ".form-control", function() {
        var $this = $(this);
        if ($this.val() === "" && (typeof $this[0].checkValidity != "undefined" && $this[0].checkValidity())) {
          $this.addClass("empty");
        } else {
          $this.removeClass("empty");
        }
      })
      .on("focus", ".form-control-wrapper.fileinput", function() {
        $(this).find("input").addClass("focus");
      })
      .on("blur", ".form-control-wrapper.fileinput", function() {
        $(this).find("input").removeClass("focus");
      })
      .on("change", ".form-control-wrapper.fileinput [type=file]", function() {
        var value = "";
        $.each($(this)[0].files, function(i, file) {
          value += file.name + ", ";
        });
        value = value.substring(0, value.length - 2);
        if (value) {
          $(this).prev().removeClass("empty");
        } else {
          $(this).prev().addClass("empty");
        }
        $(this).prev().val(value);
      });
    },
    "ripples": function(selector) {
      $((selector) ? selector : this.options.withRipples).ripples();
    },
    "autofill": function() {

      // This part of code will detect autofill when the page is loading (username and password inputs for example)
      var loading = setInterval(function() {
        $("input[type!=checkbox]").each(function() {
          if ($(this).val() && $(this).val() !== $(this).attr("value")) {
            $(this).trigger("change");
          }
        });
      }, 100);

      // After 10 seconds we are quite sure all the needed inputs are autofilled then we can stop checking them
      setTimeout(function() {
        clearInterval(loading);
      }, 10000);
      // Now we just listen on inputs of the focused form (because user can select from the autofill dropdown only when the input has focus)
      var focused;
      $(document)
      .on("focus", "input", function() {
        var $inputs = $(this).parents("form").find("input").not("[type=file]");
        focused = setInterval(function() {
          $inputs.each(function() {
            if ($(this).val() !== $(this).attr("value")) {
              $(this).trigger("change");
            }
          });
        }, 100);
      })
      .on("blur", "input", function() {
        clearInterval(focused);
      });
    },
    "init": function() {
      if ($.fn.ripples && this.options.ripples) {
        this.ripples();
      }
      if (this.options.input) {
        this.input();
      }
      if (this.options.checkbox) {
        this.checkbox();
      }
      if (this.options.togglebutton) {
        this.togglebutton();
      }
      if (this.options.radio) {
        this.radio();
      }
      if (this.options.autofill) {
        this.autofill();
      }

      if (document.arrive && this.options.arrive) {
        if ($.fn.ripples && this.options.ripples) {
          $(document).arrive(this.options.withRipples, function() {
            $.material.ripples($(this));
          });
        }
        if (this.options.input) {
          $(document).arrive(this.options.inputElements, function() {
            $.material.input($(this));
          });
        }
        if (this.options.checkbox) {
          $(document).arrive(this.options.checkboxElements, function() {
            $.material.checkbox($(this));
          });
        }
        if (this.options.radio) {
          $(document).arrive(this.options.radioElements, function() {
            $.material.radio($(this));
          });
        }
        if (this.options.togglebutton) {
          $(document).arrive(this.options.togglebuttonElements, function() {
            $.material.togglebutton($(this));
          });
        }

      }
    }
  };

})(jQuery);

/* Copyright 2014+, Federico Zivolo, LICENSE at https://github.com/FezVrasta/bootstrap-material-design/blob/master/LICENSE.md */
/* globals jQuery, navigator */

(function($, window, document, undefined) {

  "use strict";

  /**
   * Define the name of the plugin
   */
  var ripples = "ripples";


  /**
   * Get an instance of the plugin
   */
  var self = null;


  /**
   * Define the defaults of the plugin
   */
  var defaults = {};


  /**
   * Create the main plugin function
   */
  function Ripples(element, options) {
    self = this;

    this.element = $(element);

    this.options = $.extend({}, defaults, options);

    this._defaults = defaults;
    this._name = ripples;

    this.init();
  }


  /**
   * Initialize the plugin
   */
  Ripples.prototype.init = function() {
    var $element  = this.element;

    $element.on("mousedown touchstart", function(event) {
      /**
       * Verify if the user is just touching on a device and return if so
       */
      if(self.isTouch() && event.type === "mousedown") {
        return;
      }


      /**
       * Verify if the current element already has a ripple wrapper element and
       * creates if it doesn't
       */
      if(!($element.find(".ripple-wrapper").length)) {
        $element.append("<div class=\"ripple-wrapper\"></div>");
      }


      /**
       * Find the ripple wrapper
       */
      var $wrapper = $element.children(".ripple-wrapper");


      /**
       * Get relY and relX positions
       */
      var relY = self.getRelY($wrapper, event);
      var relX = self.getRelX($wrapper, event);


      /**
       * If relY and/or relX are false, return the event
       */
      if(!relY && !relX) {
        return;
      }


      /**
       * Get the ripple color
       */
      var rippleColor = self.getRipplesColor($element);


      /**
       * Create the ripple element
       */
      var $ripple = $("<div></div>");

      $ripple
      .addClass("ripple")
      .css({
        "left": relX,
        "top": relY,
        "background-color": rippleColor
      });


      /**
       * Append the ripple to the wrapper
       */
      $wrapper.append($ripple);


      /**
       * Make sure the ripple has the styles applied (ugly hack but it works)
       */
      (function() { return window.getComputedStyle($ripple[0]).opacity; })();


      /**
       * Turn on the ripple animation
       */
      self.rippleOn($element, $ripple);


      /**
       * Call the rippleEnd function when the transition "on" ends
       */
      setTimeout(function() {
        self.rippleEnd($ripple);
      }, 500);


      /**
       * Detect when the user leaves the element
       */
      $element.on("mouseup mouseleave touchend", function() {
        $ripple.data("mousedown", "off");

        if($ripple.data("animating") === "off") {
          self.rippleOut($ripple);
        }
      });

    });
  };


  /**
   * Get the new size based on the element height/width and the ripple width
   */
  Ripples.prototype.getNewSize = function($element, $ripple) {

    return (Math.max($element.outerWidth(), $element.outerHeight()) / $ripple.outerWidth()) * 2.5;
  };


  /**
   * Get the relX
   */
  Ripples.prototype.getRelX = function($wrapper,  event) {
    var wrapperOffset = $wrapper.offset();

    if(!self.isTouch()) {
      /**
       * Get the mouse position relative to the ripple wrapper
       */
      return event.pageX - wrapperOffset.left;
    } else {
      /**
       * Make sure the user is using only one finger and then get the touch
       * position relative to the ripple wrapper
       */
      event = event.originalEvent;

      if(event.touches.length !== 1) {
        return event.touches[0].pageX - wrapperOffset.left;
      }

      return false;
    }
  };


  /**
   * Get the relY
   */
  Ripples.prototype.getRelY = function($wrapper, event) {
    var wrapperOffset = $wrapper.offset();

    if(!self.isTouch()) {
      /**
       * Get the mouse position relative to the ripple wrapper
       */
      return event.pageY - wrapperOffset.top;
    } else {
      /**
       * Make sure the user is using only one finger and then get the touch
       * position relative to the ripple wrapper
       */
      event = event.originalEvent;

      if(event.touches.length !== 1) {
        return event.touches[0].pageY - wrapperOffset.top;
      }

      return false;
    }
  };


  /**
   * Get the ripple color
   */
  Ripples.prototype.getRipplesColor = function($element) {

    var color = $element.data("ripple-color") ? $element.data("ripple-color") : window.getComputedStyle($element[0]).color;

    return color;
  };


  /**
   * Verify if the client browser has transistion support
   */
  Ripples.prototype.hasTransitionSupport = function() {
    var thisBody  = document.body || document.documentElement;
    var thisStyle = thisBody.style;

    var support = (
      thisStyle.transition !== undefined ||
      thisStyle.WebkitTransition !== undefined ||
      thisStyle.MozTransition !== undefined ||
      thisStyle.MsTransition !== undefined ||
      thisStyle.OTransition !== undefined
    );

    return support;
  };


  /**
   * Verify if the client is using a mobile device
   */
  Ripples.prototype.isTouch = function() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };


  /**
   * End the animation of the ripple
   */
  Ripples.prototype.rippleEnd = function($ripple) {
    $ripple.data("animating", "off");

    if($ripple.data("mousedown") === "off") {
      self.rippleOut($ripple);
    }
  };


  /**
   * Turn off the ripple effect
   */
  Ripples.prototype.rippleOut = function($ripple) {
    $ripple.off();

    if(self.hasTransitionSupport()) {
      $ripple.addClass("ripple-out");
    } else {
      $ripple.animate({"opacity": 0}, 100, function() {
        $ripple.trigger("transitionend");
      });
    }

    $ripple.on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function() {
      $ripple.remove();
    });
  };


  /**
   * Turn on the ripple effect
   */
  Ripples.prototype.rippleOn = function($element, $ripple) {
    var size = self.getNewSize($element, $ripple);

    if(self.hasTransitionSupport()) {
      $ripple
      .css({
        "-ms-transform": "scale(" + size + ")",
        "-moz-transform": "scale(" + size + ")",
        "-webkit-transform": "scale(" + size + ")",
        "transform": "scale(" + size + ")"
      })
      .addClass("ripple-on")
      .data("animating", "on")
      .data("mousedown", "on");
    } else {
      $ripple.animate({
        "width": Math.max($element.outerWidth(), $element.outerHeight()) * 2,
        "height": Math.max($element.outerWidth(), $element.outerHeight()) * 2,
        "margin-left": Math.max($element.outerWidth(), $element.outerHeight()) * (-1),
        "margin-top": Math.max($element.outerWidth(), $element.outerHeight()) * (-1),
        "opacity": 0.2
      }, 500, function() {
        $ripple.trigger("transitionend");
      });
    }
  };


  /**
   * Create the jquery plugin function
   */
  $.fn.ripples = function(options) {
    return this.each(function() {
      if(!$.data(this, "plugin_" + ripples)) {
        $.data(this, "plugin_" + ripples, new Ripples(this, options));
      }
    });
  };

})(jQuery, window, document);

var app = angular.module('app');

app.controller('MyAchievementsEditorController', [ '$rootScope', '$scope', '$state', 'GApi', 'NotificationsService', '$q', '$modal',
                                     function($rootScope, $scope, $state, GApi, NotificationsService, $q, $modal) {
	FINAL_STEP = 8;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	// Control common functions like navigating from one step to another/cancel/save/delete
	var currentStep = parseInt($state.params.step);
	
	if (!isNaN(currentStep)) currentStep = 1;
	
	$scope.hasPreviousStep = false;
	$scope.hasNextStep = false;
	$scope.isFinalStep = false;
	$scope.isEdit = $state.params.editMode;
	$scope.isSaving = false;
	$scope.enterStep1 = false;
	$scope.achievementsId = $state.params.achievementsId;
	$scope.currentStatus  =  Math.round((currentStep-1)/FINAL_STEP*100);
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;

	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}

	function updateState () {
		$scope.hasPreviousStep = currentStep > 1;
		$scope.hasNextStep = currentStep < FINAL_STEP;
		$scope.isFinalStep = currentStep === FINAL_STEP;
		$scope.currentStatus  = Math.round((currentStep-1)/FINAL_STEP*100);
	}
	
	updateState();
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };	
	
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.cancelPopUp = function () {
//		$('#cancelAndGoBack').modal('show');
		
		modalInstance = $modal.open({
			  templateUrl: 'cancelAndGoBack.html',
			  controller: 'CancelPopUp',
			  backdrop: true,
			  resolve: {
				  translations: function () {
				      return $scope.translations;
				    }
			  }     		      
		});
	    modalInstance.result.then(function () {
	    	$state.go('myAchievements.list');
	    	//$scope.cancel();
	      }, function (err) {
	    });		
	};

	 $scope.savePopUp = function () {
		 modalInstance = $modal.open({
			 templateUrl: 'saveAndGoBack.html',
			 controller: 'SavePopUp',
			 backdrop: true,
			 resolve: {
				 translations: function () {
					 return $scope.translations;
				 }
			 }
		 });
		 modalInstance.result.then(function () {
		 	 $scope.finish('save');
			 //$state.go('myAchievements.list');

		 }, function (err) {
		 });
	 };
	$scope.cancel = function () {
		$('#cancelAndGoBack').modal('hide');
		$('.modal-backdrop').remove();		
		$state.go('myAchievements.list');
	};
	
	$scope.back = function () {
		currentStep--;
		updateState();

		$state.go($state.current.name, {step: currentStep});
	};
	
	$scope.nextView = function () {
		currentStep++;
		updateState();
		$state.go($state.current.name, {step: currentStep});
	};

	 $scope.next = function () {
		 if ($state.viewMode === 'true' || $scope.spocCareUserAccess) {
			 $scope.nextView();
		 } else {
			 $scope.isNextSaving = true;
			 $scope.finish('next');
		 }
	 };

	$scope.showSaveButton = function(){
		if($scope.hasNextStep && $state.viewMode !== 'true' && $scope.isObjEmpty($state.realizeMyPlanId)){
			return true;
		}
		else{
			return false;
		}
	};

	$scope.isNewOrEdit = function(){
		if ($scope.isEdit) {
			return true;
		} else {
			if (window.location.href.indexOf("new") > -1) {
				return true;
			}
		}
	};
	
	$scope.hideProgBarFinishUploadBtnDisableField = function(){
		if($state.viewMode === 'true'){
			return true;
		}
		else{
			return false;
		}
	};
	
	$scope.isObjEmpty = function (obj){
		if(obj && obj !== undefined && obj !== ''){
			return false;
		}
		else{
			return true;
		}
	};
	
	// $scope.finish = function (mode) {
	// 	var achievementDetailArr=[];
    //
	// 	for(var key in $scope.achievementDetail){
	// 		achievementDetailArr.push($scope.achievementDetail[key]);
	// 	}
	//
	// 	if($scope.achievementsId)
	// 		$scope.achievementList.achievementsId=$scope.achievementsId;
	//
	// 	if(mode === "savenew"){
	// 		$scope.achievementList.achievementsId=0;
	// 	}
    //
	// 	// Save the achievement
	// 	$scope.isSaving = true;
	// 	GApi.executeAuth('lmi2Api', 'achievement.saveAchievement', {
	// 		achievementList: $scope.achievementList,
	// 		achievementDetail: achievementDetailArr,
	// 		realizeMyPlanIdStr: $state.realizeMyPlanId
	// 	}).then(function(resp) {
	// 		$scope.isSaving = false;
	// 		$state.go('myAchievements.list');
	// 	}, function (err) {
	// 		$scope.isSaving = false;
	// 		NotificationsService.show(getErrorMessage('my-achievements', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
	// 	});
	// };

	 $scope.finish = function (mode) {
		 var achievementDetailArr=[];

		 for(var key in $scope.achievementDetail){
			 achievementDetailArr.push($scope.achievementDetail[key]);
		 }

		 if($scope.achievementsId)
			 $scope.achievementList.achievementsId=$scope.achievementsId;

		 if(mode === "savenew"){
			 $scope.achievementList.achievementsId=0;
		 }

		 // Save the achievement
		 $scope.isSaving = true;
		 console.log($state.realizeMyPlanId);
		 GApi.executeAuth('lmi2Api', 'achievement.saveAchievement', {
			 achievementList: $scope.achievementList,
			 achievementDetail: achievementDetailArr,
			 realizeMyPlanIdStr: $state.realizeMyPlanId
		 }).then(function(resp) {
			 $scope.isSaving = false;
			 if(mode === "next") {
                 if(!$scope.achievementsId){
                     $scope.achievementsId = resp.achievementList.achievementsId;

                     GApi.executeAuth('lmi2Api', 'achievement.getAchievementDetail', {
                         achievementId: $scope.achievementsId
                     }).then(function (resp) {
                         $scope.achievementList = resp.achievementList;

                         $scope.achievementDetail = { step1:{detailId:0, step:1, achievementId:0, dataEntry:''},
                             step2:{detailId:0, step:2, achievementId:0, dataEntry:''},
                             step3:{detailId:0, step:3, achievementId:0, dataEntry:''},
                             step4:{detailId:0, step:4, achievementId:0, dataEntry:''},
                             step5:{detailId:0, step:5, achievementId:0, dataEntry:''},
                             step6:{detailId:0, step:6, achievementId:0, dataEntry:''},
                             step7:{detailId:0, step:7, achievementId:0, dataEntry:''},
                             step8:{detailId:0, step:8, achievementId:0, dataEntry:''}
                         };

                         var i=0;

                         for(var key in $scope.achievementDetail){
                             //achievementDetailArr.push($scope.achievementDetail[key]);
                             $scope.achievementDetail[key] = resp.achievementDetail[i];
                             i++;
                         }

                     },function (err) {
                         // TODO: Log error
                     });


                 }
                 $scope.isNextSaving = false;
                 $scope.isSaving = false;
                 currentStep++;
                 updateState();
                 $state.go($state.current.name, {step: currentStep});
			 }else{
				 $state.go('myAchievements.list');
			 }
		 }, function (err) {
			 $scope.isSaving = false;
			 $scope.isNextSaving = false;
			 NotificationsService.show(getErrorMessage('my-achievements', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		 });
	 };

		$scope.achievementList = {
			achievementsId:0
		};
		
		$scope.achievementDetail = { step1:{detailId:0, step:1, achievementId:0, dataEntry:''}, 
			step2:{detailId:0, step:2, achievementId:0, dataEntry:''}, 
			step3:{detailId:0, step:3, achievementId:0, dataEntry:''}, 
			step4:{detailId:0, step:4, achievementId:0, dataEntry:''}, 
			step5:{detailId:0, step:5, achievementId:0, dataEntry:''}, 
			step6:{detailId:0, step:6, achievementId:0, dataEntry:''}, 
			step7:{detailId:0, step:7, achievementId:0, dataEntry:''}, 
			step8:{detailId:0, step:8, achievementId:0, dataEntry:''}
		};

} ]);

app.controller('CancelPopUp', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations',
                                            function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations) {
	$scope.translations = translations;
	
 	$scope.closeAndDoSomething = function () {
 		$modalInstance.close($scope.items);
 	};	
 	$scope.closeModalDialog = function () {
 		$modalInstance.dismiss('cancel');
 	};

} ]);

app.controller('SavePopUp', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations',
    function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations) {
        $scope.translations = translations;

        $scope.closeAndSave = function () {
            $modalInstance.close($scope.items);
        };
        $scope.closeModalDialog = function () {
            $modalInstance.dismiss('cancel');
        };
} ]);
var app = angular.module('app');

app.controller('MyAchievementsListController', [ '$rootScope', '$scope', '$state', '$modal', 'RolesManager', 'GApi', 'NotificationsService', 
                                     function($rootScope, $scope, $state, $modal, rolesManager, GApi, NotificationsService) {
	
	// paging param
	var PAGE_SIZE = 10;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	// paging param
	$scope.achievementList=[];
	$scope.wholePageLoading = true;

	listAchievements();
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		listAchievements();
	};

	function listAchievements(){
		$scope.wholePageLoading = true;
		GApi.executeAuth('lmi2Api', 'achievement.isFromRealize', {
		}).then(function (resp) {
			var isFromRealize = resp.items[0];
			if(isFromRealize && $state.params.realizeMyPlanId && $state.params.realizeMyPlanId !== undefined && $state.params.realizeMyPlanId !== ''){
				$state.viewMode = $state.params.viewMode;
				$state.achievementsId = $state.params.achievementsId;
				$state.realizeMyPlanId = $state.params.realizeMyPlanId;
				$state.go('myAchievements.editor.new',{step: 1});
			}
			else if(isFromRealize){
				if($state.params.viewMode && $state.params.achievementsId){
					$state.viewMode = $state.params.viewMode;
					$state.achievementsId = $state.params.achievementsId;
					$state.realizeMyPlanId = $state.params.realizeMyPlanId;
					$state.go('myAchievements.editor.view',{step: 1});
				}
				else{
					$state.go('realizationInsight.list');
				}
				
			}
			else if(!isFromRealize){
				$state.viewMode = null;
				$state.achievementsId = null;
				$state.realizeMyPlanId = null;
				GApi.executeAuth('lmi2Api', 'achievement.getAchievementDetailList', {
					page: $scope.currentPage,
					pageSize: PAGE_SIZE
				}).then(function (resp) {
					$scope.wholePageLoading = false;
					$scope.currentPage = resp.page;
					$scope.totalRecords = resp.totalRecords;
					
					if (resp.records && _.isArray(resp.records)) {
						$scope.achievementList = _.map(resp.records, function (achievement) {
							return achievement;
						});
						
						$scope.achievementListCopy = angular.copy($scope.achievementList);
					} else {
						$scope.achievementList = [];
						$scope.achievementListCopy = [];
					}
					
					$scope.pagination = [];
					for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
						$scope.pagination.push(i);
					}
				},function (err) {
					$state.go('home');
				});	
			}
		},function (err) {
			$state.go('home');
		});
					
	}
	
	$scope.confirmationDeleteAchievement = function (item) {
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'popupConfirmDeleteAchieve.html',
			  controller: 'AchievementDeleteRecord',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});
	    modalInstance.result.then(function (item) {
	    	$scope.deleteAchievement(item);
	      }, function (err) {
	    });
		
	};
	
	$scope.deleteAchievement = function (item) {
		GApi.executeAuth('lmi2Api', 'achievement.deleteAchievement', { 
			achievementId: item.achievementList.achievementsId
		}).then(function(resp) {
			$state.reload();
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};

	$scope.startAchievement = function () {
		GApi.executeAuth('lmi2Api', 'achievement.startAchievement', {}).then(function(resp) {
			$state.go('myAchievements.editor.new', {step: 1});
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	};
	
	$scope.doneAchievement = function () {
		GApi.executeAuth('lmi2Api', 'achievement.doneAchievement', { 

		}).then(function(resp) {

			if(resp.items[0] === "at_least_one"){
				$scope.dialogTitle=$scope.translations.core.warning;
				$scope.dialogMsg=$scope.translations['my-achievements']['msg-not-allow-done'];
				$('#CannotDoneAchievementDialog').modal();
			}
			else{
	
				item=resp.items[0];
				var allItems = {items: item, translations: $scope.translations};
				modalInstance = $modal.open({
					  templateUrl: 'doneAchievementDialogModal',
					  controller: 'AchievementDeleteRecord',
					  backdrop: true,
					  resolve: {
						  allItems: function () {
					      return allItems;
					    }
					  }		      
				});
				modalInstance.result.then(function (item) {
					$state.go('home');
			      }, function (err) {
			    });
			}
			
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	
} ]);

app.controller('AchievementDeleteRecord', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                        function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
     	$scope.items = allItems.items;
     	
    	$scope.translations = allItems.translations;
     	
		if($scope.items === "done"){
			$scope.dialogTitle=$scope.translations.core.notice;
			$scope.dialogMsg=$scope.translations['my-achievements']['msg-done'];
			
		}
		else if($scope.items === "continue_later"){
			$scope.dialogTitle=$scope.translations.core.notice;
			$scope.dialogMsg=$scope.translations['my-achievements']['msg-continue-later'];
		}
    		
     	$scope.closeAndDoSomething = function () {
     		$modalInstance.close($scope.items);
     	};	
     	$scope.closeModalDialog = function () {
     		$modalInstance.dismiss('cancel');
     	};

} ]);

var app = angular.module('app');

app.controller('MyAchievementsEditorStep1Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	
	//$scope.viewMode = $state.viewMode;

	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	function loadData(achievementsId){
		$scope.achievementsId = achievementsId;

		//console.log($scope.achievementsId);
		GApi.executeAuth('lmi2Api', 'achievement.getAchievementDetail', {
			achievementId: $scope.achievementsId
		}).then(function (resp) {
			$scope.achievementList = resp.achievementList;
				
			$scope.achievementDetail = { step1:{detailId:0, step:1, achievementId:0, dataEntry:''}, 
				step2:{detailId:0, step:2, achievementId:0, dataEntry:''}, 
				step3:{detailId:0, step:3, achievementId:0, dataEntry:''}, 
				step4:{detailId:0, step:4, achievementId:0, dataEntry:''}, 
				step5:{detailId:0, step:5, achievementId:0, dataEntry:''}, 
				step6:{detailId:0, step:6, achievementId:0, dataEntry:''}, 
				step7:{detailId:0, step:7, achievementId:0, dataEntry:''}, 
				step8:{detailId:0, step:8, achievementId:0, dataEntry:''}
			};	
			
			var i=0;
			
			for(var key in $scope.achievementDetail){
				//achievementDetailArr.push($scope.achievementDetail[key]);
				$scope.$parent.achievementDetail[key] = resp.achievementDetail[i];
				i++;
			}	
			assignData();
		},function (err) {
			// TODO: Log error
		});		
	}

	
	function assignData(){
		$scope.achievementType = $scope.$parent.achievementDetail.step1.dataEntry;
	}
	
	$scope.back = function () {
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
		$scope.$parent.achievementDetail.step1.dataEntry = $scope.achievementType;
		$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.achievementDetail.step1.dataEntry = $scope.achievementType;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step1.dataEntry = $scope.achievementType;
		$scope.$parent.finish(mode);
	};

	loadScreen();
	
	function loadScreen(){
		//From Realize Progress - Check Plan and Objectives
		if(!$scope.$parent.$parent.isObjEmpty($state.realizeMyPlanId) && $scope.$parent.$parent.enterStep1===false){
			$scope.$parent.$parent.enterStep1 = true;
			loadObjectivesDefaultValue();
		}
		else if($state.viewMode === "true" && $state.achievementsId && $scope.$parent.$parent.enterStep1===false){
			$scope.$parent.$parent.enterStep1 = true;
			loadData($state.achievementsId);
		}
		//From My achievements
		else if($state.params.editMode === "true" && $scope.$parent.$parent.enterStep1===false){
			$scope.$parent.$parent.enterStep1 = true;
			loadData($state.params.achievementsId);
		} 
		else {
			assignData();
		}
	}
	
	function loadObjectivesDefaultValue(){
		GApi.executeAuth('lmi2Api', 'realizeMyPlan.getPlanObjectives', {
			realizeMyPlanId: $state.realizeMyPlanId
		}).then(function (resp) {

			var realizeMyPlanTemplate = resp.realizeMyPlanTemplate;
			var realizeMyPlan = resp.realizeMyPlanList[0];
			
			if(realizeMyPlanTemplate.realizeMyPlanTemplateId === 1){
				$scope.$parent.achievementDetail.step2.dataEntry = realizeMyPlan.objectives;	
			}
			else if(realizeMyPlanTemplate.realizeMyPlanTemplateId === 2){
				$scope.$parent.achievementDetail.step2.dataEntry = realizeMyPlan.objectives;
			}
			else if(realizeMyPlanTemplate.realizeMyPlanTemplateId === 3){
				$scope.$parent.achievementDetail.step1.dataEntry = 'achievementtype-value-learning';
				$scope.$parent.achievementDetail.step2.dataEntry = realizeMyPlan.objectives;
			}
			else if(realizeMyPlanTemplate.realizeMyPlanTemplateId === 4){
				$scope.$parent.achievementDetail.step1.dataEntry = 'achievementtype-value-learning';
				$scope.$parent.achievementDetail.step2.dataEntry = realizeMyPlan.objectives;
			}
			
			assignData();
		},function (err) {
			// TODO: Log error
		});	
	}
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep2Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;

	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.businessCase = $scope.$parent.achievementDetail.step2.dataEntry;
	$scope.back = function () {
		$scope.$parent.achievementDetail.step2.dataEntry=$scope.businessCase ;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	 $scope.savePopUp = function () {
         $scope.$parent.achievementDetail.step2.dataEntry=$scope.businessCase;
		 $scope.$parent.savePopUp();
	 };

	$scope.next = function () {
		$scope.$parent.achievementDetail.step2.dataEntry=$scope.businessCase;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step2.dataEntry=$scope.businessCase ;
		$scope.$parent.finish(mode);
	};		
	
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep3Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;

	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.keyChallenges = $scope.$parent.achievementDetail.step3.dataEntry;
	$scope.back = function () {
		$scope.$parent.achievementDetail.step3.dataEntry=$scope.keyChallenges ;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
        $scope.$parent.achievementDetail.step3.dataEntry=$scope.keyChallenges ;
	 	$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.achievementDetail.step3.dataEntry=$scope.keyChallenges ;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step3.dataEntry=$scope.keyChallenges ;
		$scope.$parent.finish(mode);
	};		
	
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep4Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.finalResult = $scope.$parent.achievementDetail.step4.dataEntry;
	$scope.back = function () {
		$scope.$parent.achievementDetail.step4.dataEntry=$scope.finalResult ;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
        $scope.$parent.achievementDetail.step4.dataEntry=$scope.finalResult;
	 	$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.achievementDetail.step4.dataEntry=$scope.finalResult ;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step4.dataEntry=$scope.finalResult ;
		$scope.$parent.finish(mode);
	};			
	
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep5Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.customerFeedback = $scope.$parent.achievementDetail.step5.dataEntry;
	$scope.back = function () {
		$scope.$parent.achievementDetail.step5.dataEntry=$scope.customerFeedback ;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
        $scope.$parent.achievementDetail.step5.dataEntry=$scope.customerFeedback ;
	 	$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.achievementDetail.step5.dataEntry=$scope.customerFeedback ;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step5.dataEntry=$scope.customerFeedback ;
		$scope.$parent.finish(mode);
	};				
	
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep6Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.addedValue = $scope.$parent.achievementDetail.step6.dataEntry;
	$scope.back = function () {
		$scope.$parent.achievementDetail.step6.dataEntry=$scope.addedValue ;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
        $scope.$parent.achievementDetail.step6.dataEntry=$scope.addedValue ;
	 	$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.achievementDetail.step6.dataEntry=$scope.addedValue ;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step6.dataEntry=$scope.addedValue ;
		$scope.$parent.finish(mode);
	};				
	
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep7Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$scope.personalLearning = $scope.$parent.achievementDetail.step7.dataEntry;
	$scope.back = function () {
		$scope.$parent.achievementDetail.step7.dataEntry=$scope.personalLearning ;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
        $scope.$parent.achievementDetail.step7.dataEntry=$scope.personalLearning ;
	 	$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.achievementDetail.step7.dataEntry=$scope.personalLearning ;
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.achievementDetail.step7.dataEntry=$scope.personalLearning ;
		$scope.$parent.finish(mode);
	};		
	
} ]);
var app = angular.module('app');

app.controller('MyAchievementsEditorStep8Controller', [ '$rootScope', '$scope', '$state', 'RolesManager','GApi', 'NotificationsService',
                                     function($rootScope, $scope, $state, rolesManager, GApi, NotificationsService) {
	
	//$scope.realizeMyPlanId = $state.realizeMyPlanId;
	//$scope.viewMode = $state.viewMode;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.uploadedDocument = new Flow({
		target: '/upload', 
		testChunks: false,
		method: 'octet',
		chunkSize: (1024 * 100),
		singleFile: true
	}); 

	$scope.uploadInProgress = false;
	$scope.uploadedFiles = [];
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };	
	$scope.isEdit = $scope.$parent.isEdit;
	if ($scope.$parent.achievementDetail.step8.dataEntry){
		$scope.$parent.currentStatus = 100;
		tmpFiles = $scope.$parent.achievementDetail.step8.dataEntry.split(";");
		for (var j=0; j< tmpFiles.length; j++){
			tmpFile = tmpFiles[j].split(":");
            fileId = tmpFile[0];
            fileName = tmpFile[1];
            resourceKey = tmpFile[2];//TL-518
            $scope.uploadedFiles.push([fileId,fileName,resourceKey]);
			// fileName = tmpFile[0];
			// fileId = tmpFile[1];
			// $scope.uploadedFiles.push([fileName,fileId]);
		}
		//$scope.uploadedFiles = $scope.$parent.achievementDetail.step8.dataEntry;
	}

	$scope.back = function () {
		tempData = "";
		for (var i=0;i< $scope.uploadedFiles.length;i++){
			tempData = tempData + $scope.uploadedFiles[i][0] + ":" + $scope.uploadedFiles[i][1] + ":" + $scope.uploadedFiles[i][2] +";";//TL-518
		}
		tempData = tempData.length > 0 ? tempData.substring(0,tempData.length-1) : tempData;
		$scope.$parent.achievementDetail.step8.dataEntry= tempData;
		$scope.$parent.back();
	};
	
	$scope.cancelPopUp = function () {
		$scope.$parent.cancelPopUp();
	};
	
	$scope.cancel = function () {
		$scope.$parent.cancel();
	};

	$scope.savePopUp = function () {
        tempData = "";
        for (var i=0;i< $scope.uploadedFiles.length;i++){
            tempData = tempData + $scope.uploadedFiles[i][0] + ":" + $scope.uploadedFiles[i][1] + ":" + $scope.uploadedFiles[i][2] + ";";//TL-518
        }
        tempData = tempData.length > 0 ? tempData.substring(0,tempData.length-1) : tempData;
        $scope.$parent.achievementDetail.step8.dataEntry= tempData;
	 	$scope.$parent.savePopUp();
	};

	$scope.next = function () {
		$scope.$parent.next();
	};
	
	$scope.finish = function (mode) {
		tempData = "";
		for (var i=0;i< $scope.uploadedFiles.length;i++){
			tempData = tempData + $scope.uploadedFiles[i][0] + ":" + $scope.uploadedFiles[i][1] + ":" + $scope.uploadedFiles[i][2] + ";";//TL-518
		}
		tempData = tempData.length > 0 ? tempData.substring(0,tempData.length-1) : tempData;
		$scope.$parent.achievementDetail.step8.dataEntry= tempData;
		$scope.$parent.finish(mode);
	};		
		
	$scope.getIconStateUpload = function (initialState, workingState) {
		return $scope.uploadInProgress ? workingState : initialState;
	};
	
	$scope.removeFile = function (fileID) {
		$scope.$parent.currentStatus = 88;
		//console.log(fileID);
		for (var i=0;i< $scope.uploadedFiles.length;i++){
			 if (fileID === $scope.uploadedFiles[i][0]){
			 	$scope.uploadedFiles.splice(i,1);
			 }
		}
	};
	
	 $scope.uploadDocument = function (){ 
	 	  $scope.uploadResult=[];	
	 	  $scope.uploadInProgress = true;
		  if($scope.uploadedDocument.hasOwnProperty('files')){
			   if ((!angular.isUndefined($scope.uploadedDocument.files)) && $scope.uploadedDocument.files.length >0){
			   	 $scope.uploadInProgress = true;
			   	  if(!$scope.uploadedDocument.files[0].error){
					GApi.executeAuth('lmi2Api', 'achievement.uploadDocument', 
					{
						fileName: $scope.uploadedDocument.files[0].name,
						fileId : $scope.uploadedDocument.files[0].uniqueIdentifier,
						email : $rootScope.user.email 
					})
					.then(function(resp) {
						$scope.uploadInProgress = false;
						$scope.$parent.currentStatus = 100;
						$scope.uploadResult.push(resp);
						$scope.uploadedFiles.push([resp.items[0],resp.items[1],resp.items[2]]); //TL-518
						NotificationsService.show($scope.getTranslation('my-achievements', 'success-upload'), NOTIFICATIONS_FADEOUT_TIMEOUT);
					},function(err) {
						NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
						$scope.uploadInProgress = false;
						$scope.uploadedDocument = null;
					});
			   	  }		  
		  	}
		}
		 
	  };
	  
} ]);
var app = angular.module('app');

app.controller('MyCompetencesEditorController', [ '$rootScope', '$scope', '$state', 'GApi', 'NotificationsService', '$q',
                                     function($rootScope, $scope, $state, GApi, NotificationsService,$q) {
	if(!$scope.FINAL_STEP){
		GApi.executeAuth('lmi2Api', 'meCompetences.addCompetencesDetail', { 
		}).then(function (resp) {
		},function (err) {
			NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});	
		
		$scope.FINAL_STEP = 6;
	}
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	var TOTAL_STEPS = 24;

	// Control common functions like navigating from one step to another/cancel/save/delete
	$scope.isSaving = false;
	$scope.isContinue = false;
	$scope.currentGroup = parseInt($state.params.group);
	$scope.currentStep = parseInt($state.params.step);
	$scope.competenceSeq = 1;
	$scope.previousLastStep=1;
	$scope.radioVal= {value:""};
	$scope.competence={competence1:"", competence2:"", competence3:""};
	$scope.currentStatus  = Math.round(($scope.competenceSeq-1)/TOTAL_STEPS*100);

	if (!isNaN($scope.currentGroup)) $scope.currentGroup = 1;
	if (!isNaN($scope.currentStep)) $scope.currentStep = 1;
	
	$scope.getIconStateContinue = function (initialState, workingState) {
		return $scope.isContinue ? workingState : initialState;
	};
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };	
	
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};	
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}
	
	function updateState (mode) {
		$scope.hasPreviousStep = $scope.currentStep > 1;
		$scope.hasNextStep = $scope.currentStep < $scope.FINAL_STEP;
		$scope.isFinalStep = $scope.currentStep === $scope.FINAL_STEP;
		$scope.isFirstStep = $scope.currentStep === 1;
		$scope.currentStatus  = Math.round(($scope.competenceSeq-1)/TOTAL_STEPS*100);
		
		if(mode !== "continue"){
			GApi.executeAuth('lmi2Api', 'meCompetences.getMeCompetencesTemplate', {
				groupSeq: $scope.currentGroup,
				competenceSeq: $scope.competenceSeq
			}).then(function (resp) {
				$scope.isSaving = false;
				$scope.competences = JSON.parse(resp.data);
				// initialize value
				$scope.radioVal.value="";
				$scope.competence.competence1="";
				$scope.competence.competence2="";
				$scope.competence.competence3="";
				// initialize value
				
				if($scope.currentGroup === 5){
					if($scope.competences[0].userAns){
						$scope.competence.competence1 = $scope.competences[0].userAns[0];
						$scope.competence.competence2 = $scope.competences[0].userAns[1];
						$scope.competence.competence3 = $scope.competences[0].userAns[2];
					}				
				}else{
					if($scope.competences[0].userAns){
						$scope.radioVal.value = $scope.competences[0].userAns[0];
					}
				}
				
			},function (err) {
				NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
				// TODO: Log error
			});				
		}
	}
	
	function saveRecord (mode, finalStep) {
		$scope.isSaving = true;
		$scope.record=[];		
		if(mode === "save" || mode ==="next"){		
			var recordData = {
				groupSeq: $scope.currentGroup,
				competenceSeq: $scope.competenceSeq,
				answerSeq:$scope.radioVal.value
			};
			$scope.record.push(recordData);			
		}else if(mode === "finish"){
			var recordData1 = {
				groupSeq: $scope.currentGroup,
				competenceSeq: $scope.competenceSeq,
				answerSeq:$scope.competence.competence1,
				remark:"1"
			};		
			var recordData2 = {
				groupSeq: $scope.currentGroup,
				competenceSeq: $scope.competenceSeq,
				answerSeq:$scope.competence.competence2,
				remark:"2"
			};		
			var recordData3 = {
				groupSeq: $scope.currentGroup,
				competenceSeq: $scope.competenceSeq,
				answerSeq:$scope.competence.competence3,
				remark:"3"
			};				
			$scope.record.push(recordData1);
			$scope.record.push(recordData2);
			$scope.record.push(recordData3);
		}


		GApi.executeAuth('lmi2Api', 'meCompetences.updateCompetencesDetail', { 
            competencesDetailJson:  JSON.stringify($scope.record)
		}).then(function (resp) {
			if(mode === "save" || mode ==="finish"){
				$state.go('myCompetences.list');
			}else{
				$scope.competenceSeq++;
				if(finalStep){
					$scope.currentGroup++;
					$scope.currentStep=1;
				}else{
					$scope.currentStep++;
				}
				
				updateState();
				$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});					
			}
				
		},function (err) {
			$scope.isSaving = false;
			NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});			
	}
	

	
	updateState();
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.back = function () { 
		$scope.competenceSeq--;
		$scope.currentStep--;
		
		if($scope.currentStep === 0){
			$scope.currentGroup--;
			$scope.currentStep = $scope.previousLastStep;
		}
			
		updateState();
		
		$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});
	};
	
	$scope.jumpPage = function () {
		$scope.isContinue = true;
		GApi.executeAuth('lmi2Api', 'meCompetences.getLastTemplate', {
		}).then(function (resp) {
			if(resp.data === "null"){
				$scope.isContinue = false;
				NotificationsService.show(getErrorMessage('my-competences', 'warning-allFilledIn'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return;
			}
			$scope.competences = JSON.parse(resp.data);
			
			$scope.currentGroup = $scope.competences[0].groupSeq;
			$scope.competenceSeq =  $scope.competences[0].competenceSeq;
			
			if($scope.currentGroup === 1){
				$scope.currentStep = $scope.competenceSeq;
				$scope.FINAL_STEP = 6;
			}else if($scope.currentGroup === 2){
				$scope.currentStep = $scope.competenceSeq - 6;	
				$scope.FINAL_STEP = 3;
			}else if($scope.currentGroup === 3){
				$scope.currentStep = $scope.competenceSeq - 9;
				$scope.FINAL_STEP = 4;
			}else if($scope.currentGroup === 4){
				$scope.currentStep = $scope.competenceSeq - 13;		
				$scope.FINAL_STEP = 10;
			}else if($scope.currentGroup === 5){
				$scope.currentStep = 1;
				$scope.FINAL_STEP = 1;
			}
			
			// initialize value
			$scope.radioVal.value="";
			$scope.competence.competence1="";
			$scope.competence.competence2="";
			$scope.competence.competence3="";
			// initialize value
			
			if($scope.currentGroup === 5){
				if($scope.competences[0].userAns){
					$scope.competence.competence1 = $scope.competences[0].userAns[0];
					$scope.competence.competence2 = $scope.competences[0].userAns[1];
					$scope.competence.competence3 = $scope.competences[0].userAns[2];
				}				
			}else{
				if($scope.competences[0].userAns){
					$scope.radioVal.value = $scope.competences[0].userAns[0];
				}
			}
			$scope.isContinue = false;
			updateState("continue");
			$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});			
			
		},function (err) {
			NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});
	};
	
	$scope.next = function (finalStep) {
		saveRecord("next", finalStep);
	};
	
	
	$scope.finish = function (mode) {
		saveRecord(mode);
		//$state.go('myCompetences.list');
	};
	

	// This is where the Achievement model will be. The various steps will access the achievement model via their parent scope.

} ]);
var app = angular.module('app');

app.controller('MyExternalCompetencesEditorController', [ '$rootScope', '$scope', '$modal', '$state', 'GApi', 'NotificationsService', '$q',
                                     function($rootScope, $scope, $modal, $state, GApi, NotificationsService,$q) {
	
	$scope.FINAL_STEP = 6;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	var TOTAL_STEPS = 24;
	var queryString = window.location.href;
	var varArray = queryString.split("?");
	var externalUser = false;
	var token = "";
	var emailId;
	for (var i=0;i<varArray.length;i++) {
	  var param = varArray[i].split("=");
	  if(param[0].indexOf("token") !== -1){
		  externalUser = true;
		  token = param[1];
	  }
	}	
	// Control common functions like navigating from one step to another/cancel/save/delete
	$scope.showFirstScreen = false;
	$scope.isSaving = false;
	$scope.isContinue = false;
	$scope.currentGroup = parseInt($state.params.group);
	$scope.currentStep = parseInt($state.params.step);
	$scope.competenceSeq = 1;
	$scope.previousLastStep=1;
	$scope.radioVal= {value:""};
	$scope.competence={competence1:""};
	$scope.currentStatus  = Math.round(($scope.competenceSeq-1)/TOTAL_STEPS*100);

	if (!isNaN($scope.currentGroup)) $scope.currentGroup = 1;
	if (!isNaN($scope.currentStep)) $scope.currentStep = 1;
	
	$scope.getIconStateContinue = function (initialState, workingState) {
		return $scope.isContinue ? workingState : initialState;
	};
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };	
	
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};	
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}

	GApi.execute('lmi2Api', 'otherCompetences.addOtherCompetencesDetail', { 
		token:token
	}).then(function (resp) {
		//updateState();
		$scope.token = token;
		if(resp.items){
			emailId = resp.items[0];
			updateState("firstStep");
		}else{
			NotificationsService.show(getErrorMessage('my-competences', "Unable to retrieve data based on token provided"), NOTIFICATIONS_FADEOUT_TIMEOUT);
		}
	},function (err) {
		NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		// TODO: Log error
	});	

	function updateState (mode) {
		$scope.hasPreviousStep = $scope.currentStep > 1;
		$scope.hasNextStep = $scope.currentStep < $scope.FINAL_STEP;
		$scope.isFinalStep = $scope.currentStep === $scope.FINAL_STEP;
		$scope.isFirstStep = $scope.currentStep === 1;
		$scope.currentStatus  = Math.round(($scope.competenceSeq-1)/TOTAL_STEPS*100);
		if(mode !== "continue"){
			GApi.execute('lmi2Api', 'otherCompetences.getOtherCompetencesTemplate', {
				token: token,
				groupSeq: $scope.currentGroup,
				competenceSeq: $scope.competenceSeq
			}).then(function (resp) {
				if(mode==="firstStep" && resp.data==="null"){
					$rootScope.surveyCompleted = true;
					$state.go('myCompetences.finish');
					return;
				}
				$scope.showFirstScreen=true;
				$scope.isSaving = false;
				$scope.competences = JSON.parse(resp.data);
				// initialize value
				$scope.radioVal.value="";
				$scope.competence.competence1="";
				$scope.competence.competence2="";
				$scope.competence.competence3="";
				// initialize value
				
				if($scope.currentGroup === 5){
					if($scope.competences[0].userAns){
						$scope.competence.competence1 = $scope.competences[0].userAns[0];
						$scope.competence.competence2 = $scope.competences[0].userAns[1];
						$scope.competence.competence3 = $scope.competences[0].userAns[2];
					}				
				}else{
					if($scope.competences[0].userAns){
						$scope.radioVal.value = $scope.competences[0].userAns[0];
					}
				}
				
			},function (err) {
				NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
				// TODO: Log error
			});				
		}
	}	
	
	function saveRecord (mode, finalStep) {
		$scope.isSaving = true;
		$scope.record=[];		
		if(mode === "save" || mode ==="next"){		
			var recordData = {
				emailId: emailId,
				competenceId: $scope.competenceSeq,
				rating:$scope.radioVal.value
			};
			$scope.record.push(recordData);			
		}else if(mode === "finish"){
			var recordData1 = {
				emailId: emailId,
				competenceId: $scope.competenceSeq,
				remarks:$scope.competence.competence1
			};				
			$scope.record.push(recordData1);
		}
	
		GApi.execute('lmi2Api', 'otherCompetences.updateOtherCompetencesDetail', { 
	        competencesDetailJson:  JSON.stringify($scope.record)
		}).then(function (resp) {
			if(mode === "save" || mode ==="finish"){
				$scope.isSaving = false;
				if(mode ==="finish")
					$state.go('myCompetences.finish');
				else
					$scope.finishProcess();
			}else{
				$scope.competenceSeq++;
				if(finalStep){
					$scope.currentGroup++;
					$scope.currentStep=1;
				}else{
					$scope.currentStep++;
				}
				
				updateState();
				$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});					
			}
				
		},function (err) {
			$scope.isSaving = false;
			NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});			
	}	
	
	$scope.showPopUp = function () {
		$scope.$broadcast ('myOtherCompetencesPopUp');
	};
	
	$scope.finishProcess = function (){
		$('#finishProcessModal').modal();
//		modalInstance = $modal.open({
//			  templateUrl: 'finishProcess',
//			  controller: 'FinishProcessProceedPopupController',
//			  backdrop: false,
//			  resolve: {
//			     
//			  }		      		      
//		});
	};	
	
	$scope.jumpPage = function () {
		$scope.isContinue = true;
		GApi.execute('lmi2Api', 'otherCompetences.getOtherLastTemplate', {
			token: token
		}).then(function (resp) {
			if(resp.data === "null"){
				$scope.isContinue = false;
				NotificationsService.show(getErrorMessage('my-competences', 'warning-allFilledIn'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return;
			}
			$scope.competences = JSON.parse(resp.data);
			
			$scope.currentGroup = $scope.competences[0].groupSeq;
			$scope.competenceSeq =  $scope.competences[0].competenceSeq;
			
			if($scope.currentGroup === 1){
				$scope.currentStep = $scope.competenceSeq;
				$scope.FINAL_STEP = 6;
			}else if($scope.currentGroup === 2){
				$scope.currentStep = $scope.competenceSeq - 6;	
				$scope.FINAL_STEP = 3;
			}else if($scope.currentGroup === 3){
				$scope.currentStep = $scope.competenceSeq - 9;
				$scope.FINAL_STEP = 4;
			}else if($scope.currentGroup === 4){
				$scope.currentStep = $scope.competenceSeq - 13;		
				$scope.FINAL_STEP = 10;
			}else if($scope.currentGroup === 5){
				$scope.currentStep = 1;
				$scope.FINAL_STEP = 1;
			}
			
			// initialize value
			$scope.radioVal.value="";
			$scope.competence.competence1="";
			$scope.competence.competence2="";
			$scope.competence.competence3="";
			// initialize value
			
			if($scope.currentGroup === 5){
				if($scope.competences[0].userAns){
					$scope.competence.competence1 = $scope.competences[0].userAns[0];
					$scope.competence.competence2 = $scope.competences[0].userAns[1];
					$scope.competence.competence3 = $scope.competences[0].userAns[2];
				}				
			}else{
				if($scope.competences[0].userAns){
					$scope.radioVal.value = $scope.competences[0].userAns[0];
				}
			}
			$scope.isContinue = false;
			updateState("continue");
			$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});			
			
		},function (err) {
			NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});
	};	
	$scope.back = function () { 
		$scope.competenceSeq--;
		$scope.currentStep--;
		
		if($scope.currentStep === 0){
			$scope.currentGroup--;
			$scope.currentStep = $scope.previousLastStep;
		}
			
		updateState();
		
		$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});
	};	
	
	$scope.next = function (finalStep) {
		saveRecord("next", finalStep);
	};


	$scope.finish = function (mode) {
		if (mode === "finish") {
			//console.log($scope.currentStep);
			$scope.isSaving = true;
			$scope.record=[];		
			if(mode === "save" || mode ==="next"){		
				var recordData = {
					emailId: emailId,
					competenceId: $scope.competenceSeq,
					rating:$scope.radioVal.value
				};
				$scope.record.push(recordData);			
			}else if(mode === "finish"){
				var recordData1 = {
					emailId: emailId,
					competenceId: $scope.competenceSeq,
					remarks:$scope.competence.competence1
				};				
				$scope.record.push(recordData1);
			}
		
			GApi.execute('lmi2Api', 'otherCompetences.updateOtherCompetencesDetail', { 
		        competencesDetailJson:  JSON.stringify($scope.record)
			}).then(function (resp) {
					GApi.execute('lmi2Api', 'otherCompetences.submitOtherCompetencesDetail', { 
				        token: $scope.token
					}).then(function (resp) {
						if(mode === "save" || mode ==="finish"){
							if(mode ==="finish")
								$state.go('myCompetences.finish');
							else
								$scope.finishProcess();
						}else{
							$scope.competenceSeq++;
							if(finalStep){
								$scope.currentGroup++;
								$scope.currentStep=1;
							}else{
								$scope.currentStep++;
							}
							updateState();
							$state.go($state.current.name, {group: $scope.currentGroup, step: $scope.currentStep});					
						}
					},function (err) {
						$scope.isSaving = false;
						NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
						// TODO: Log error
					});			
					
			},function (err) {
				$scope.isSaving = false;
				NotificationsService.show(getErrorMessage('my-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
				// TODO: Log error
			});	
		}else {
			saveRecord(mode);
		}
	};	

} ]);

//app.controller('FinishProcessProceedPopupController', [ '$scope','$modalInstance',
//                                                        function($scope, $modalInstance) {
//                     	
//     	$scope.cancelPopup = function () {
//     		$modalInstance.dismiss('cancel');
//     	};
//
//} ]);
var app = angular.module('app');

app.controller('MyExternalCompetencesEditorGroup1Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {
	
	$scope.groupSteps = 6;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;

	$scope.back = function () {
		$scope.$parent.back();
	};
	
	$scope.jumpPage = function () {
		$scope.$parent.jumpPage();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyExternalCompetencesEditorGroup2Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 3;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=6;
		$scope.$parent.$parent.FINAL_STEP=6;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyExternalCompetencesEditorGroup3Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 4;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=3;
		$scope.$parent.$parent.FINAL_STEP=3;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyExternalCompetencesEditorGroup4Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 10;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=4;
		$scope.$parent.$parent.FINAL_STEP=4;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyExternalCompetencesEditorGroup5Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 1;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=10;
		$scope.$parent.$parent.FINAL_STEP=10;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyAcompetencesFinish', [ '$rootScope', '$scope', '$state', '$modal', 'RolesManager', 'GApi', 'NotificationsService',
                                     function($rootScope, $scope, $state, $modal, rolesManager, GApi, NotificationsService) {
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
} ]);

//app.controller('FinishProcessProceedPopupController', [ '$scope','$modalInstance',
//                                                   function($scope, $modalInstance) {
//                	
//	$scope.cancelPopup = function () {
//		$modalInstance.dismiss('cancel');
//	};
//
//} ]);
var app = angular.module('app');

app.controller('MyCompetencesEditorGroup1Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {
	
	$scope.groupSteps = 6;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.back();
	};
	
	$scope.jumpPage = function () {
		$scope.$parent.jumpPage();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyCompetencesEditorGroup2Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 3;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=6;
		$scope.$parent.$parent.FINAL_STEP=6;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyCompetencesEditorGroup3Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 4;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=3;
		$scope.$parent.$parent.FINAL_STEP=3;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyCompetencesEditorGroup4Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 10;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=4;
		$scope.$parent.$parent.FINAL_STEP=4;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyCompetencesEditorGroup5Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {

	$scope.groupSteps = 1;
	$scope.$parent.$parent.FINAL_STEP = $scope.groupSteps;
	
	$scope.back = function () {
		$scope.$parent.$parent.previousLastStep=10;
		$scope.$parent.$parent.FINAL_STEP=10;
		$scope.$parent.back();
	};
	
	$scope.next = function (finalStep) {
		$scope.$parent.next(finalStep);
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyCompetencesListController', [ '$rootScope', '$scope', '$state', '$modal', '$http', 'RolesManager', 'GApi', 'NotificationsService',
	function($rootScope, $scope, $state, $modal, $http, rolesManager, GApi, NotificationsService) {
		var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
		$scope.translations = $rootScope.stateChangeStart.data &&
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;

		$scope.graphValue = null;
		$scope.showLoading = true;
		$scope.displayCurrentMsg = "";
        $scope.defaultData = {
            viewPreferences : 'barChart'
        };
        $scope.chartOptions = [{
            name: 'Bar Chart',
            value: 'barChart'
        },{
            name: 'Radar Chart',
            value: 'radarChart'
        }];
		$scope.options = {
			availableOptions: [],
			selectedOption: null //This sets the default value of the select in the ui
		};
		$scope.options2 = {
			availableOptions: [],
			selectedOption: { //This sets the default value of the select in the ui
				index: 0,
				name: 'select-knowledge-section',
				div: null
			}
		};

		getMeCompetencesGraphValue();

		function getMeCompetencesGraphValue(){
			GApi.executeAuth('lmi2Api', 'meCompetences.getMeCompetencesGraphValue', {
			}).then(function(resp) {
				var isOlder = false;

				if (resp.result['dateDone'] && resp.result['dateDone'] !== '-') {
					// check dateDone not older than 1 year, otherwise throw error
					var dateDone = moment(resp.result['dateDone'], 'DD-MM-YYYY');
					var today = moment();

					isOlder = (today.years() - dateDone.years()) > 1;
				} else {
					isOlder = true;
				}

				if (isOlder) {
					modalInstance = $modal.open({
						templateUrl: 'outdatedMeCompetences',
						controller: 'FailToDoneMeCompetences',
						backdrop: true,
						resolve: {
							translations: function () {
								return $scope.translations;
							}
						}
					});
					return;
				}

				$scope.graphValue = resp.result;
				if ($scope.graphValue['dateDone']) $scope.displayCurrentMsg = ($scope.translations['my-competences']['msg-self-mapping-completed-on'] ? $scope.translations['my-competences']['msg-self-mapping-completed-on'] : "The self mapping was completed on ") + $scope.graphValue['dateDone'];
				$scope.knowledgeAdded = false;
				angular.forEach($scope.graphValue.asIsValueList, function(graph, index) {
					var option = {
						index: index,
						name: graph.tableName,
						div: null
					};
					if (option.name.includes("knowledge")) {
						if ($scope.knowledgeAdded) {
							$scope.options2.availableOptions.push(option);
						} else {
							// for first occurence
							$scope.options2.availableOptions.push(option);
							$scope.options.availableOptions.push({
								index: index,
								name: 'knowledge',
								div: null
							});
							$scope.knowledgeAdded = true;
						}
					} else {
						$scope.options.availableOptions.push(option);
					}
				});

				$scope.showLoading = false;
			}, function (err) {
				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
				$scope.showLoading = false;
			});

			// FOR TESTING
			// $http.get('http://127.0.0.1:8887/competenceGraphValue.json').then(function(resp) {
			// 	var isOlder = false;
			//
			// 	if (resp.data['dateDone']) {
			// 		// check dateDone not older than 1 year, otherwise throw error
			// 		var dateDone = moment(resp.data['dateDone'], 'DD-MM-YYYY');
			// 		var today = moment();
			//
			// 		isOlder = (today.years() - dateDone.years()) > 1;
			// 	} else {
			// 		isOlder = true;
			// 	}
			//
			// 	if (isOlder) {
			// 		modalInstance = $modal.open({
			// 			templateUrl: 'outdatedMeCompetences',
			// 			controller: 'FailToDoneMeCompetences',
			// 			backdrop: true,
			// 			resolve: {
			// 				translations: function () {
			// 					return $scope.translations;
			// 				}
			// 			}
			// 		});
			// 		return;
			// 	}
			//
			// 	$scope.graphValue = resp.data;
			// 	if ($scope.graphValue['dateDone']) $scope.displayCurrentMsg = ($scope.translations['my-competences']['msg-self-mapping-completed-on'] ? $scope.translations['my-competences']['msg-self-mapping-completed-on'] : "The self mapping was completed on ") + $scope.graphValue['dateDone'];
			// 	$scope.knowledgeAdded = false;
			// 	angular.forEach($scope.graphValue.asIsValueList, function(graph, index) {
			// 		var option = {
			// 			index: index,
			// 			name: graph.tableName,
			// 			div: null
			// 		};
			// 		if (option.name.includes("knowledge")) {
			// 			if ($scope.knowledgeAdded) {
			// 				$scope.options2.availableOptions.push(option);
			// 			} else {
			// 				// for first occurence
			// 				$scope.options2.availableOptions.push(option);
			// 				$scope.options.availableOptions.push({
			// 					index: index,
			// 					name: 'knowledge',
			// 					div: null
			// 				});
			// 				$scope.knowledgeAdded = true;
			// 			}
			// 		} else {
			// 			$scope.options.availableOptions.push(option);
			// 		}
			// 	});
			// 	$scope.showLoading = false;
			// }, function (err) {
			// 	NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			// 	$scope.showLoading = false;
			// });
		}

		$scope.doneMeCompetences = function () {
			GApi.executeAuth('lmi2Api', 'meCompetences.doneMeCompetences', {
			}).then(function(resp) {
				if(resp.items[0] == "nok"){
					modalInstance = $modal.open({
						templateUrl: 'cannotDoneMeCompetences',
						controller: 'FailToDoneMeCompetences',
						backdrop: true,
						resolve: {
							translations: function () {
								return $scope.translations;
							}
						}
					});
				}
				else{
					$state.go('home');
				}
			}, function (err) {
				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			});
		};

        $scope.showChartPopup = function (chartType, category) {
			angular.forEach($scope.options.availableOptions, function (option, index) {
				if (option.name == 'knowledge') {
					option.div = null;
				} else {
					option.div = "chart_div" + option.index;
				}
			});

			angular.forEach($scope.options2.availableOptions, function (option, index) {
				option.div = "chart_div" + option.index;
			});

			for (var i=0; i < $scope.options.availableOptions.length; i++) {
				if ($scope.options.availableOptions[i].name === category) {
					$scope.options.selectedOption = $scope.options.availableOptions[i];
				}
			}
            modalInstance = $modal.open({
                templateUrl: 'showChartPopUp.html',
                controller: 'ShowChartPopUp',
                backdrop: true,
                resolve: {
                    translations: function () {
                        return $scope.translations;
                    },
                    graphValue: function () {
                        return $scope.graphValue;
                    },
					options: function() {
						return $scope.options;
					},
					options2: function() {
						return $scope.options2;
					}
                }
            });
            modalInstance.result.then(
				function () {
					//trigger when modal is closed
            	},
				function () {
					//trigger when modal is dismiss
					$scope.options.selectedOption = null;
					$scope.options2.selectedOption = {
						index: 0,
						name: 'select-knowledge-section',
						div: null
					};
            	}
			);
        };

		$scope.showRadarChartPopup = function (chartType, category) {
			angular.forEach($scope.options.availableOptions, function (option, index) {
				if (option.name == 'knowledge') {
					option.div = null;
				} else {
					option.div = "radar_chart_div" + option.index;
				}
			});

			angular.forEach($scope.options2.availableOptions, function (option, index) {
				option.div = "radar_chart_div" + option.index;
			});

			for (var i=0; i < $scope.options.availableOptions.length; i++) {
				if ($scope.options.availableOptions[i].name === category) {
					$scope.options.selectedOption = $scope.options.availableOptions[i];
				}
			}

			modalInstance = $modal.open({
				templateUrl: 'showRadarChartPopUp.html',
				controller: 'ShowRadarChartPopUp',
				backdrop: true,
				resolve: {
					translations: function () {
						return $scope.translations;
					},
					graphValue: function () {
						return $scope.graphValue;
					},
					options: function() {
						return $scope.options;
					},
					options2: function() {
						return $scope.options2;
					}
				}
			});
			modalInstance.result.then(
				function () {
					// trigger when modal is close
				},
				function () {
					// trigger when modal is dismiss
					$scope.options.selectedOption = null;
					$scope.options2.selectedOption = {
						index: 0,
						name: 'select-knowledge-section',
						div: null
					};
				}
			);
		};

	} ]);

app.controller('FailToDoneMeCompetences', [ '$rootScope', '$sce', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', '$state', 'translations',
	function($rootScope, $sce, $scope, $http, GApi, $q, $timeout, $modalInstance, $state, translations) {

		$scope.translations = translations;

		$scope.cancelPopup = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.assessmentOutdate = $sce.trustAsHtml($scope.translations['my-competences']['lbl-warning-assessment-outdated']);
		$scope.backHome = function () {
			$modalInstance.dismiss('cancel');
			$state.go('home');
		};
	} ]);

app.controller('ShowChartPopUp', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations', 'graphValue', 'options', 'options2',
	function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations, graphValue, options, options2) {
		$scope.translations = translations;
		$scope.graphValue = graphValue;
		$scope.options = options;
		$scope.options2 = options2;

		$rootScope.$on("bar-chart:loaded", function () {
			setTimeout(function () {
				angular.forEach($scope.options.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
						}
					} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
						}
					}
				});

				angular.forEach($scope.options2.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					if (graphValue.asIsValueList[index + 2].competencesValueModalList.length > 0 && option.div !== null) {
						if ($scope.options2.selectedOption.div !== null) {
							if (option.div === $scope.options2.selectedOption.div) {
								chartDiv.style.display = "block";
							} else {
								chartDiv.style.display = "none";
							}
						} else {
							chartDiv.style.display = "none";
						}
					} else {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options2.selectedOption.div) {
							chartDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
						}
					}
				});
			}, 250);
		});

        $scope.closeAndSave = function () {
            $modalInstance.close($scope.items);
        };
        $scope.closeModalDialog = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.update = function (selectedOption) {
			$scope.options2.selectedOption = {
				index: 0,
				name: 'select-knowledge-section',
				div: null
			};
			angular.forEach($scope.options.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options.selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				}
			});
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				}
			});
		};

		$scope.update2 = function (selectedOption) {
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				}
			});
		};
    }
]);

app.controller('ShowRadarChartPopUp', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations', 'graphValue', 'options', 'options2',
	function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations, graphValue, options, options2) {
		$scope.translations = translations;
		$scope.graphValue = graphValue;
		$scope.options = options;
		$scope.options2 = options2;

		$rootScope.$on("radar-chart:loaded", function () {
			setTimeout(function () {
				angular.forEach($scope.options.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					var legendDiv = null;
					if (chartDiv !== null) {
						legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
					}
					if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
							legendDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
							legendDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					}
				});

				angular.forEach($scope.options2.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					var legendDiv = null;
					if (chartDiv !== null) {
						legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
					}
					if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
						if ($scope.options2.selectedOption.div !== null) {
							if (option.div === $scope.options2.selectedOption.div) {
								chartDiv.style.display = "block";
								legendDiv.style.display = "block";
							} else {
								chartDiv.style.display = "none";
								legendDiv.style.display = "none";
							}
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					} else {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options2.selectedOption.div) {
							chartDiv.style.display = "block";
							legendDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					}
				});
			}, 250);
		});

		$scope.closeAndSave = function () {
			$modalInstance.close($scope.items);
		};
		$scope.closeModalDialog = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.update = function (selectedOption) {
			$scope.options2.selectedOption = {
				index: 0,
				name: 'select-knowledge-section',
				div: null
			};
			angular.forEach($scope.options.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				var legendDiv = null;
				if (chartDiv !== null) {
					legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
				}
				if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options.selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				}
			});
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				var legendDiv = null;
				if (chartDiv !== null) {
					legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
				}
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				}
			});
		};

		$scope.update2 = function (selectedOption) {
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				var legendDiv = null;
				if (chartDiv !== null) {
					legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
				}
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				}
			});
		};
	}
]);
var app = angular.module('app');

app.controller('MyContextEditorController', [ '$rootScope', '$scope', '$state', 'GApi', 'NotificationsService', '$q',
                                     function($rootScope, $scope, $state, GApi, NotificationsService,$q) {

	GApi.executeAuth('lmi2Api', 'myContext.addMyContext', { 
	}).then(function (resp) {
		$scope.myContext = JSON.parse(resp.data);
		if(!$scope.myContext.description){
			$scope.myContext.coachPlan = true;
			$scope.myContext.alignmentPlan = true;
			$scope.myContext.commitPlan = true;
		}
			
	},function (err) {
		NotificationsService.show(getErrorMessage('my-context', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		// TODO: Log error
	});	
	
	$scope.currentStatus  = 0;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.isSaving = false;
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};	
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};	

	$scope.finish = function (mode) {
		$scope.isSaving = true;
		GApi.executeAuth('lmi2Api', 'myContext.updateMyContext', { 
			myContextJson:  JSON.stringify($scope.myContext)
		}).then(function (resp) {
			$scope.isSaving = false;
			$state.go('myContext.list');
		},function (err) {
			$scope.isSaving = false;
			NotificationsService.show(getErrorMessage('my-context', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});			
	};	
} ]);
var app = angular.module('app');

app.controller('MyContextListController', [ '$rootScope', '$scope', '$state', '$modal', 'RolesManager', 'GApi', 'NotificationsService', 
                                     function($rootScope, $scope, $state, $modal, rolesManager, GApi, NotificationsService) {
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	/*--- Begin: I'm done for now ---*/
	$scope.doneMyContext = function () {
		GApi.executeAuth('lmi2Api', 'myContext.doneMyContext', { 
		}).then(function(resp) {

			item=resp.items[0];
			var allItems = {items: item, translations: $scope.translations};
			modalInstance = $modal.open({
				  templateUrl: 'doneContextDialogModal',
				  controller: 'ImDoneForNowContextController',
				  backdrop: true,
				  resolve: {
					  allItems: function () {
				      return allItems;
				    }
				  }		      
			});
			modalInstance.result.then(function (item) {
				$state.go('home');
		      }, function (err) {
		    });
			
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	/*--- End: I'm done for now ---*/

	$scope.startMyContext = function () {
		GApi.executeAuth('lmi2Api', 'myContext.startMyContext', {}).then(function(resp) {
			$state.go('myContext.editor.new', {step: 1});
		}, function (err) {
			 NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
	 	});
 	};
	
} ]);


app.controller('ImDoneForNowContextController', [ '$rootScope', '$scope', '$modalInstance','allItems',
                                            function($rootScope, $scope, $modalInstance, allItems) {
         	$scope.items = allItems.items;       	
        	$scope.translations = allItems.translations;
         	
    		if($scope.items === "ok"){
    			$scope.dialogTitle=$scope.translations.core.notice;
    			$scope.dialogMsg=$scope.translations['my-context']['msg-done'];
    			
    		}
    		else if($scope.items === "nok"){
    			$scope.dialogTitle=$scope.translations.core.warning;
    			$scope.dialogMsg=$scope.translations['my-context']['msg-not-allow-done'];
    		}
        		
         	$scope.closeAndDoSomethingORclose = function () {
         		if($scope.items === "ok"){
         			$modalInstance.close($scope.items);
         		}
         		else if($scope.items === "nok"){
         			$modalInstance.dismiss('cancel');
         		}
         		
         	};

} ]);

var app = angular.module('app');

app.controller('MyContextEditorStep1Controller', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi',
                                     function($rootScope, $scope, $state, rolesManager,GApi) {
	
	$scope.back = function () {
		$scope.$parent.back();
	};
	
	$scope.finish = function (mode) {
		$scope.$parent.finish(mode);
	};	

} ]);
var app = angular.module('app');

app.controller('MyDestinationConfirmRoleController', ["$rootScope", "$scope", "$state", "$modal", "GApi", "NotificationsService", function ($rootScope, $scope, $state, $modal, GApi, NotificationsService) {
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.confirmationMessage = null;
	
	function init() {
		if ($scope.$parent.$parent.selectedDestination) {
			var destination = $scope.translations['my-destination'][$scope.$parent.$parent.selectedDestination.selection];
			
			$scope.confirmationMessage = $scope.translations['my-destination']['msg-popup-confirm-destination'];
			$scope.confirmationMessage = $scope.confirmationMessage.replace('<<destination>>', '\"' + destination + '\"');
		}
	}
	
	//init();
	
	$scope.confirmSelectDestination = function () {
		
		init();
		
		var item = $scope.confirmationMessage;
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'popupSelectAsDestination.html',
			  controller: 'SelectAsDestinationController',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});
	    modalInstance.result.then(function (item) {
	    	$scope.$parent.$parent.saveDestination();
	      }, function (err) {
	    });
		
	};
		
}]);

app.controller('SelectAsDestinationController', [ '$rootScope', '$scope','$modalInstance','allItems',
                                                  function($rootScope, $scope, $modalInstance, allItems) {
       	$scope.items = allItems.items;
      	$scope.translations = allItems.translations;


       	$scope.closeAndDoSomething = function () {
       		$modalInstance.close($scope.items);
       	};
       	$scope.closeModalDialog = function () {
       		$modalInstance.dismiss('cancel');
       	};

} ]);
var app = angular.module('app');

app.controller('MyDestinationController', ["$rootScope", "$scope", "$state", "$modal", "$http", "GApi", "NotificationsService", function($rootScope, $scope, $state, $modal, $http, GApi, NotificationsService) {

	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;

	$scope.showLoading = true;
	$scope.showGraph = false;
	$scope.displayCurrentMsg = "";
	$scope.defaultData = {
		viewPreferences : 'barChart'
	};
	$scope.chartOptions = [{
		name: 'Bar Chart',
		value: 'barChart'
	},{
		name: 'Radar Chart',
		value: 'radarChart'
	}];
	$scope.options = {
		availableOptions: [],
		selectedOption: null //This sets the default value of the select in the ui
	};
	$scope.options2 = {
		availableOptions: [],
		selectedOption: { //This sets the default value of the select in the ui
			index: 0,
			name: 'select-knowledge-section',
			div: null
		}
	};

	$scope.graphValue = null;

	getCurrentMyDestination();

	// $scope.exploreMyDestination = function(){
	// 	$scope.displayCurrentMsg = "";
	// 	$scope.showLoading = true;
	// 	$state.go("myDestination.selection.visualMap");
	// };

	function getCurrentMyDestination(){
		$scope.displayCurrentMsg = "";
		$scope.showLoading = true;

		 GApi.executeAuth('lmi2Api', 'myDestination.getCurrentMyDestination', {}).then(function(resp) {
			$scope.graphValue = resp.result;
		 	$scope.knowledgeAdded = false;
			//if(resp.items[0]){
			// 	var displayCurrentDestination = String($scope.translations['my-destination'][resp.items[0]]);
			// 	var displayCurrentTranslation = String($scope.translations['my-destination']['msg-previously-select-destination']);
			//
			// 	$scope.displayCurrentMsg = displayCurrentTranslation.replace("<<destination>>","\"" + displayCurrentDestination + "\"");
			// }
			if ($scope.graphValue['roleName'] && $scope.graphValue['dateDone']) {
				var displayCurrentTranslation = $scope.translations['my-destination']['you-have-selected-role'];
				$scope.displayCurrentMsg = displayCurrentTranslation.replace("{{ROLE_NAME}}", $scope.graphValue['roleName'])
					.replace("{{DATE}}", $scope.graphValue['dateDone']);
			} else{
				$scope.displayCurrentMsg = $scope.translations['my-destination']['msg-not-selected-destination'];
			}

			if ($scope.graphValue['asIsValueList'] && $scope.graphValue['asIsValueList'].length > 0 &&
				$scope.graphValue['toBeValueList'] && $scope.graphValue['toBeValueList'].length > 0) {
				angular.forEach($scope.graphValue.asIsValueList, function(graph, index) {
					var option = {
						index: index,
						name: graph.tableName,
						div: null
					};
					if (option.name.includes("knowledge")) {
						if ($scope.knowledgeAdded) {
							$scope.options2.availableOptions.push(option);
						} else {
							// for first occurence
							$scope.options2.availableOptions.push(option);
							$scope.options.availableOptions.push({
								index: index,
								name: 'knowledge',
								div: null
							});
							$scope.knowledgeAdded = true;
						}
					} else {
						$scope.options.availableOptions.push(option);
					}
				});
				$scope.showGraph = true;
			}

			$scope.showLoading = false;
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});

		// FOR TESTING
		// $http.get('http://127.0.0.1:8887/myDestination.json').then(function(resp) {
		// 	$scope.graphValue = resp.data;
		// 	$scope.knowledgeAdded = false;
		// 	//if(resp.items[0]){
		// 	// 	var displayCurrentDestination = String($scope.translations['my-destination'][resp.items[0]]);
		// 	// 	var displayCurrentTranslation = String($scope.translations['my-destination']['msg-previously-select-destination']);
		// 	//
		// 	// 	$scope.displayCurrentMsg = displayCurrentTranslation.replace("<<destination>>","\"" + displayCurrentDestination + "\"");
		// 	// }
		// 	if ($scope.graphValue['roleName'] && $scope.graphValue['dateDone']) {
		// 		var displayCurrentTranslation = $scope.translations['my-destination']['you-have-selected-role'];
		// 		$scope.displayCurrentMsg = displayCurrentTranslation.replace("{{ROLE_NAME}}", $scope.graphValue['roleName'])
		// 			.replace("{{DATE}}", $scope.graphValue['dateDone']);
		// 	} else{
		// 		$scope.displayCurrentMsg = $scope.translations['my-destination']['msg-not-selected-destination'];
		// 	}
		//
		//
		// 	if ($scope.graphValue['asIsValueList'] && $scope.graphValue['asIsValueList'].length > 0 &&
		// 		$scope.graphValue['toBeValueList'] && $scope.graphValue['toBeValueList'].length > 0) {
		// 		angular.forEach($scope.graphValue.asIsValueList, function(graph, index) {
		// 			var option = {
		// 				index: index,
		// 				name: graph.tableName,
		// 				div: null
		// 			};
		// 			if (option.name.includes("knowledge")) {
		// 				if ($scope.knowledgeAdded) {
		// 					$scope.options2.availableOptions.push(option);
		// 				} else {
		// 					// for first occurence
		// 					$scope.options2.availableOptions.push(option);
		// 					$scope.options.availableOptions.push({
		// 						index: index,
		// 						name: 'knowledge',
		// 						div: null
		// 					});
		// 					$scope.knowledgeAdded = true;
		// 				}
		// 			} else {
		// 				$scope.options.availableOptions.push(option);
		// 			}
		// 		});
		// 		$scope.showGraph = true;
		// 	}
		//
		// 	$scope.showLoading = false;
		// }, function (err) {
		// 	NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		// });
	}

	$scope.showChartPopupDestination = function (chartType, category) {
		angular.forEach($scope.options.availableOptions, function (option, index) {
			if (option.name == 'knowledge') {
				option.div = null;
			} else {
				option.div = "chart_div" + option.index;
			}
		});

		angular.forEach($scope.options2.availableOptions, function (option, index) {
			option.div = "chart_div" + option.index;
		});

		for (var i=0; i < $scope.options.availableOptions.length; i++) {
			if ($scope.options.availableOptions[i].name === category) {
				$scope.options.selectedOption = $scope.options.availableOptions[i];
			}
		}

		modalInstance = $modal.open({
			templateUrl: 'showChartPopUpDestination.html',
			controller: 'ShowChartPopUpDestination',
			backdrop: true,
			resolve: {
				translations: function () {
					return $scope.translations;
				},
				graphValue: function () {
					return $scope.graphValue;
				},
				options: function() {
					return $scope.options;
				},
				options2: function() {
					return $scope.options2;
				}
			}
		});
		modalInstance.result.then(
			function () {
				//trigger when modal is closed
			},
			function () {
				//trigger when modal is dismiss
				$scope.options.selectedOption = null;
				$scope.options2.selectedOption = {
					index: 0,
					name: 'select-knowledge-section',
					div: null
				};
			}
		);
	};

	$scope.showRadarChartPopupDestination = function (chartType, category) {
		angular.forEach($scope.options.availableOptions, function (option, index) {
			if (option.name == 'knowledge') {
				option.div = null;
			} else {
				option.div = "radar_chart_div" + option.index;
			}
		});

		angular.forEach($scope.options2.availableOptions, function (option, index) {
			option.div = "radar_chart_div" + option.index;
		});

		for (var i=0; i < $scope.options.availableOptions.length; i++) {
			if ($scope.options.availableOptions[i].name === category) {
				$scope.options.selectedOption = $scope.options.availableOptions[i];
			}
		}

		modalInstance = $modal.open({
			templateUrl: 'showRadarChartPopUpDestination.html',
			controller: 'showRadarChartPopUpDestination',
			backdrop: true,
			resolve: {
				translations: function () {
					return $scope.translations;
				},
				graphValue: function () {
					return $scope.graphValue;
				},
				options: function() {
					return $scope.options;
				},
				options2: function() {
					return $scope.options2;
				}
			}
		});
		modalInstance.result.then(
			function () {
				//trigger when modal is closed
			},
			function () {
				//trigger when modal is dismiss
				$scope.options.selectedOption = null;
				$scope.options2.selectedOption = {
					index: 0,
					name: 'select-knowledge-section',
					div: null
				};
			}
		);
	};
	
	/*--- Begin: I'm done for now ---*/
	$scope.doneMyDestination = function () {
		GApi.executeAuth('lmi2Api', 'myDestination.doneMyDestination', { 
		}).then(function(resp) {

			item=resp.items[0];
			var allItems = {items: item, translations: $scope.translations};
			modalInstance = $modal.open({
				  templateUrl: 'doneDestinationDialogModal',
				  controller: 'ImDoneForNowDestinationController',
				  backdrop: true,
				  resolve: {
					  allItems: function () {
				      return allItems;
				    }
				  }		      
			});
			modalInstance.result.then(function (item) {
				$state.go('home');
		      }, function (err) {
		    });
			
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	/*--- End: I'm done for now ---*/
	
	
}]);

app.controller('ShowChartPopUpDestination', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations', 'graphValue', 'options', 'options2',
	function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations, graphValue, options, options2) {
		$scope.translations = translations;
		$scope.graphValue = graphValue;
		$scope.options = options;
		$scope.options2 = options2;

		$rootScope.$on("bar-chart:loaded", function () {
			setTimeout(function () {
				angular.forEach($scope.options.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
						}
					} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"</div>";
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
						}
					}
				});

				angular.forEach($scope.options2.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					if (graphValue.asIsValueList[index + 2].competencesValueModalList.length > 0 && option.div !== null) {
						if ($scope.options2.selectedOption.div !== null) {
							if (option.div === $scope.options2.selectedOption.div) {
								chartDiv.style.display = "block";
							} else {
								chartDiv.style.display = "none";
							}
						} else {
							chartDiv.style.display = "none";
						}
					} else {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options2.selectedOption.div) {
							chartDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
						}
					}
				});
			}, 250);
		});

		$scope.closeAndSave = function () {
			$modalInstance.close($scope.items);
		};
		$scope.closeModalDialog = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.update = function (selectedOption) {
			$scope.options2.selectedOption = {
				index: 0,
				name: 'select-knowledge-section',
				div: null
			};
			angular.forEach($scope.options.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"        </div>";
					if (option.div === $scope.options.selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				}
			});
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				}
			});
		};

		$scope.update2 = function (selectedOption) {
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
					}
				}
			});
		};
	}
]);

app.controller('ImDoneForNowDestinationController', [ '$rootScope', '$scope', '$modalInstance','allItems',
                                                  function($rootScope, $scope, $modalInstance, allItems) {
       	$scope.items = allItems.items;
      	$scope.translations = allItems.translations;
       	
  		if($scope.items === "ok"){
  			$scope.dialogTitle=$scope.translations.core.notice;
  			$scope.dialogMsg=$scope.translations['my-destination']['msg-done'];
  			
  		}
  		else if($scope.items === "nok"){
  			$scope.dialogTitle=$scope.translations.core.warning;
  			$scope.dialogMsg=$scope.translations['my-destination']['msg-not-allow-done'];
  		}
      		
       	$scope.closeAndDoSomethingORclose = function () {
       		if($scope.items === "ok"){
       			$modalInstance.close($scope.items);
       		}
       		else if($scope.items === "nok"){
       			$modalInstance.dismiss('cancel');
           	}
           		
        };
	}
]);

app.controller('showRadarChartPopUpDestination', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations', 'graphValue', 'options', 'options2',
	function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations, graphValue, options, options2) {
		$scope.translations = translations;
		$scope.graphValue = graphValue;
		$scope.options = options;
		$scope.options2 = options2;

		$rootScope.$on("radar-chart:loaded", function () {
			setTimeout(function () {
				angular.forEach($scope.options.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					var legendDiv = null;
					if (chartDiv !== null) {
						legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
					}
					if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
							legendDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options.selectedOption.div) {
							chartDiv.style.display = "block";
							legendDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					}
				});

				angular.forEach($scope.options2.availableOptions, function (option, index) {
					var chartDiv = document.getElementById(option.div);
					var legendDiv = null;
					if (chartDiv !== null) {
						legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
					}
					if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
						if ($scope.options2.selectedOption.div !== null) {
							if (option.div === $scope.options2.selectedOption.div) {
								chartDiv.style.display = "block";
								legendDiv.style.display = "block";
							} else {
								chartDiv.style.display = "none";
								legendDiv.style.display = "none";
							}
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					} else {
						chartDiv.innerHTML = "<div>\n" +
							"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
							"        </div>";
						if (option.div === $scope.options2.selectedOption.div) {
							chartDiv.style.display = "block";
							legendDiv.style.display = "block";
						} else {
							chartDiv.style.display = "none";
							legendDiv.style.display = "none";
						}
					}
				});
				var x = document.getElementById("radar_chart_div9");
				x.height = 100;
			}, 250);
		});


		$scope.closeAndSave = function () {
			$modalInstance.close($scope.items);
		};
		$scope.closeModalDialog = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.update = function (selectedOption) {
			$scope.options2.selectedOption = {
				index: 0,
				name: 'select-knowledge-section',
				div: null
			};
			angular.forEach($scope.options.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				var legendDiv = null;
				if (chartDiv !== null) {
					legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
				}
				if (graphValue.asIsValueList[index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				} else if (option.div !== null && (chartDiv !== null && chartDiv !== undefined)) {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options.selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				}
			});
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				var legendDiv = null;
				if (chartDiv !== null) {
					legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
				}
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				}
			});
		};

		$scope.update2 = function (selectedOption) {
			angular.forEach($scope.options2.availableOptions, function (option, index) {
				var chartDiv = document.getElementById(option.div);
				var legendDiv = null;
				if (chartDiv !== null) {
					legendDiv = document.getElementById(option.div.replace("radar_chart_div", "legend_div"));
				}
				if (graphValue.asIsValueList[option.index].competencesValueModalList.length > 0 && option.div !== null) {
					if (option.div === selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				} else {
					chartDiv.innerHTML = "<br />\n" +
						"<div>\n" +
						"            <h1 style=\"text-align: center\">No Data Available</h1>\n" +
						"</div>";
					if (option.div === $scope.options2.selectedOption.div) {
						chartDiv.style.display = "block";
						legendDiv.style.display = "block";
					} else {
						chartDiv.style.display = "none";
						legendDiv.style.display = "none";
					}
				}
			});
		};
	}
]);
var app = angular.module('app');

app.controller('MyDestinationSelectRoleController', ["$rootScope", "$scope", "$state", function ($rootScope, $scope, $state) {
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.staticContentKey = null;
	$scope.selectedDestination = null;
	$scope.destinations = [];
	
	$scope.confirmDestination = function () {
		if ($scope.$parent.$parent.selectedDestination) {
			$state.go('myDestination.selection.confirmRole', {
				destinationId: $scope.$parent.$parent.selectedDestination.destinationTemplateId
			});
		}
	};
	
	$scope.displayDestinationStaticContent = function () {
		$scope.$parent.$parent.selectedDestination = _.find($scope.$parent.$parent.destinations, function (item) {
			return item.destinationTemplateId === parseInt($scope.selectedDestination);
		});
		
		if ($scope.$parent.$parent.selectedDestination) {
			$scope.staticContentKey = $scope.$parent.$parent.selectedDestination.staticContentKey;
		}
	};
		
	function init() {
		$scope.staticContentKey = null;
		$scope.selectedDestination = null;
		$scope.destinations = _.filter($scope.$parent.$parent.destinations, function (item) {
			//return item.parentDestinationTemplateId === parseInt($state.params.destinationId);
			return item.parentDestinationTemplateId === parseInt($scope.$parent.$parent.selectedDestinationId);
		});
	}
	
	$scope.$watch('$parent.$parent.selectedDestinationId', function () {
		if($scope.$parent.$parent.selectedDestinationId){
			init();
		}

	});
		
}]);
var app = angular.module('app');

app.controller('MyDestinationSelectionController', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	var DESTINATION_SELECTION_DONT_KNOW = 'selection-dont-know-destination';
	
	$scope.isLoading = false;
	$scope.isSaving = false;
	$scope.destinations = [];
	$scope.subDestinations = [];
	$scope.selectedDestination = null;
	$scope.description = null;
	$scope.isDisplayDropdown = false;
	$scope.groupHeader = null;
	
	$scope.selectDestination = function (destination) {
		$scope.selectedDestination = destination;
		$scope.groupHeader = null;
		
		if (destination.selection === DESTINATION_SELECTION_DONT_KNOW) {
			$state.go('myDestination.selection.targetMatrix');
		} else {
			var childDestinations = _.filter($scope.destinations, function (item) {
				return item.parentDestinationTemplateId === destination.destinationTemplateId;
			});
			
			if (childDestinations !== null && childDestinations.length > 0) {
				$scope.isDisplayDropdown=true;
				$scope.selectedDestinationId = destination.destinationTemplateId;
				$scope.groupHeader = destination.selection;
				/*$state.go('myDestination.selection.selectRole', {
					destinationId: destination.destinationTemplateId
				});*/
			} else {
				$scope.isDisplayDropdown=false;
				/*$state.go('myDestination.selection.confirmRole', {
					destinationId: destination.destinationTemplateId
				});*/
			}
		}
	};
	
	$scope.saveDestination = function () {
		$scope.isSaving = true;
		
		GApi.executeAuth('lmi2Api', 'myDestination.saveMyDestination', { 
			destinationTemplateId: $scope.selectedDestination.destinationTemplateId,
			destinationSeletion: $scope.translations['my-destination'][$scope.selectedDestination.selection],
			description: ($scope.selectedDestination.inputRequired ? $scope.description : null)
		}).then(function(resp) {
			$scope.isSaving = false;
			$state.go('myDestination.home');
		}, function (err) {
			$scope.isSaving = false;
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
				
	};
	
	function init() {
		$scope.isLoading = true;
		
		GApi.executeAuth('lmi2Api', 'myDestination.listDestinations', { }).then(function(resp) {
			$scope.destinations = resp.items;
			
			GApi.executeAuth('lmi2Api', 'myDestination.getOthersDescription', { 

			}).then(function(resp) {
				$scope.isLoading = false;
				$scope.description = (resp.items && resp.items.length > 0 ? resp.items[0] : null);
			}, function (err) {
				$scope.isLoading = false;
				NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			});
		}, function (err) {
			$scope.isLoading = false;
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	}
	
	init();
		
}]);
var app = angular.module('app');

app.controller('MyDestinationTargetMatrixController', ["$rootScope", "$scope", "$state", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $modal, GApi, NotificationsService) {

	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	//Begin - TargetMatrix table
	$scope.showLoading = true;
	$scope.radioVal= {value:""};
	
	getTargetMatrix();
	
	function getTargetMatrix(){
		
		GApi.executeAuth('lmi2Api', 'myDestination.getTargetMatrix', { 
		}).then(function(resp) {
			$scope.targetList = resp;
	       
			$scope.tableSize = $scope.targetList.items[0].dataRecord.length;
			
			$scope.showLoading = false;
			
		}, function (err) {
			
		});			
	}
	
	$scope.getSize=function(n){
        return new Array(n);
    };
    
	$scope.displayData = function (headerName, dataRecord) {

		if(dataRecord === "5" || dataRecord === 5){
			//return "0";
			return $scope.translations.core['not-yet-develop-define-value'];
		}

	    return dataRecord;
	};	
	//End - TargetMatrix table
	
	$scope.popupCompetencesQuestion = function (item,rowIndex) {
		//item = competenceId
		//$scope.targetList.items[2].dataRecord[rowIndex] = currentUserTargetValue; item2 = targetvalue column

		GApi.execute('lmi2Api', 'myDestination.getTargetCompetencesTemplate', {
			competenceSeq: item
		}).then(function (resp) {

			$scope.competences = JSON.parse(resp.data);
			// initialize value
			$scope.radioVal.value="";
			// initialize value
			
			if($scope.targetList.items[2].dataRecord[rowIndex] !== 5 && $scope.targetList.items[2].dataRecord[rowIndex] !== "5"){
				$scope.radioVal.value = $scope.targetList.items[2].dataRecord[rowIndex];
			}
			
			$scope.latestRowIndex = rowIndex;
					
		},function (err) {
			NotificationsService.show(getErrorMessage('my-destination', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});	
		
		$('#targetCompetencesModal').modal();

	};
	
	$scope.saveTargetCompetencesValue = function(item){
		
		GApi.execute('lmi2Api', 'myDestination.saveTargetCompetencesDetail', {
			competenceSeq: item,
			userTargetValue: $scope.radioVal.value,
			destinationTemplateId: $scope.$parent.$parent.selectedDestination.destinationTemplateId
		}).then(function (resp) {

			$('#targetCompetencesModal').modal('hide');

			$scope.targetList.items[2].dataRecord[$scope.latestRowIndex]=$scope.radioVal.value;
					
		},function (err) {
			NotificationsService.show(getErrorMessage('my-destination', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			// TODO: Log error
		});	
		
		
	};

}]);
var app = angular.module('app');

app.controller('MyDestinationVisualMapController', ["$rootScope", "$scope", "$state", "$modal", "GApi", "NotificationsService", function ($rootScope, $scope, $state, $modal, GApi, NotificationsService) {
	
	if($scope.$parent.$parent.selectedDestination){
		$scope.$parent.$parent.selectedDestination = "";
	}
	
	$scope.$parent.$parent.isDisplayDropdown = false;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
				
	$scope.getDestinationIcon = function (destination) {
		var children = _.filter($scope.$parent.$parent.destinations, function (item) {
			return item.parentDestinationTemplateId === destination.destinationTemplateId;
		});
		
		if (children.length > 0) return 'fa fa-users';
		else return 'fa fa-user';
	};
	
	$scope.filterFinanceDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return false;
		
		return item.domain === 'finance';
	};
	
	$scope.filterMethodologiesDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return false;
		
		return item.domain === 'methodological';
	};
	
	$scope.filterBusinessDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return false;
		
		return item.domain === 'running-business';
	};
	
	$scope.filterNonFinanceDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return false;
		
		return item.domain === 'non-finance';
	};
	
	$scope.filterNoDomainDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return true;
	};
	
	$scope.filterFinanceRunningBusinessDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return false;
		
		var domains = item.domain.split(',');
		return domains.length === 2 && _.contains(domains, 'finance') && _.contains(domains, 'running-business');
	};
	
	$scope.filterAllTypesDestinations = function (item) {
		if (item.parentDestinationTemplateId > 0) return false;
		if (!item.domain) return false;
		
		var domains = item.domain.split(',');		
		return domains.length === 4 && 
			_.contains(domains, 'finance') && 
			_.contains(domains, 'running-business') && 
			_.contains(domains, 'methodological') && 
			_.contains(domains, 'non-finance');
	};
		
}]);
var app = angular.module('app');

app.controller('MyPersonalityIndexController', ["$rootScope", "$scope", "$state", "NotificationsService", "GApi", function ($rootScope, $scope, $state, NotificationsService, GApi) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.lastFacet5RequestDate = null;
	$scope.myPersonality = null;
	$scope.isLoading = false;
	$scope.isSubmittingFacet5Request = false;
	$scope.showFacet5Request = false;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.showFacet5Form = function () {
		$scope.showFacet5Request = true;
	};
	
	$scope.donePersonality = function () {
		GApi.executeAuth('lmi2Api', 'personality.donePersonality', { 
			
		}).then(function(resp) {
			if(resp.items[0] == "nok"){
				$('#donePersonalityModal').modal();
			}
			else{
				$state.go('home');
			}
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};

	$scope.startPersonality = function () {
		GApi.executeAuth('lmi2Api', 'personality.startPersonality', {}).then(function(resp) {
			$state.go('myPersonality.preferences');
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	};

	$scope.submitRequest = function () {
		if (!$scope.$parent.$parent.facet5RequestRemarks || $scope.$parent.$parent.facet5RequestRemarks.length === 0) {
			NotificationsService.show($scope.translations['my-personality']['my-personliaty-facet5-request-remarks-required'], NOTIFICATIONS_FADEOUT_TIMEOUT);
			return;
		}
		
		$scope.isSubmittingFacet5Request = true;
		GApi.executeAuth('lmi2Api', 'personality.requestFacet5Questionnaire', {
			remarks: $scope.$parent.$parent.facet5RequestRemarks
		}).then(function(resp) {
			$scope.isSubmittingFacet5Request = false;
			
			if (resp && resp.request) {
				$scope.$parent.$parent.myPersonality.lastFacet5Request = resp.request;
				$scope.$parent.$parent.copyOfMyPersonality = angular.copy($scope.$parent.$parent.myPersonality);
				
				if (!resp.emailSent) {
					NotificationsService.show($scope.translations['my-personality']['my-personality-facet5-submited-email-not-sent'], NOTIFICATIONS_FADEOUT_TIMEOUT);
				}
			}
			
		}, function (err) {
			$scope.isSubmittingFacet5Request = false;
			
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
	
	$scope.$watch('$parent.$parent.myPersonality', function () {
		$scope.myPersonality = $scope.$parent.$parent.myPersonality;
		$scope.lastFacet5RequestDate = null;
		
		if ($scope.myPersonality && $scope.myPersonality.lastFacet5Request) {
			$scope.lastFacet5RequestDate = moment($scope.myPersonality.lastFacet5Request.dateCreated);
		}
	});
	
	$scope.$watch('$parent.$parent.isLoading', function () {
		$scope.isLoading = $scope.$parent.$parent.isLoading;
	});
	
}]);

var app = angular.module('app');

app.controller('MyPersonalityMotivatorsController', ["$rootScope", "$scope", "$state", "NotificationsService", function ($rootScope, $scope, $state, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.isSaving = false;
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.cancel = function () {
		$scope.$parent.$parent.cancelMotivatorsStep();
	};
	
	$scope.finish = function () {
		var checkCount = _.filter($scope.$parent.$parent.myPersonality.motivators, function (motivator) {
			return motivator.checked === true;
		}).length;
		
		if (checkCount > 5) {
			NotificationsService.show('Please select 5 motivators at most.', NOTIFICATIONS_FADEOUT_TIMEOUT);
			return;
		}
		
		$scope.isSaving = true;
		
		$scope.$parent.$parent.finishFromMotivators().then(function () {
			$scope.isSaving = false;
			$state.go('myPersonality.home');
		}, function (err) {
			NotificationsService.show(getErrorMessage('my-personality', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isSaving = false;
		});
	};
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		$.material.init();
	});
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}
		
}]);
var app = angular.module('app');

app.controller('MyPersonalityBaseController', ["$scope", "$state", "$q", "GApi", function ($scope, $state, $q, GApi) {
	
	$scope.facet5RequestRemarks = null;
	$scope.myPersonality = null;
	$scope.copyOfMyPersonality = null;
	$scope.isLoading = false;
	
	$scope.cancelPreferencesStep = function () {
		$scope.myPersonality = angular.copy($scope.copyOfMyPersonality);
		$state.go('myPersonality.home');
	};
	
	$scope.nextFromPreferences = function () {
		var defer = $q.defer();
		
		GApi.executeAuth('lmi2Api', 'personality.savePreferences', { 
			preferences: $scope.myPersonality.preferenceQuestions
		}).then(function(resp) {
			if (resp && resp.items) {
				$scope.myPersonality.preferenceQuestions = angular.copy(resp.items);
				$scope.copyOfMyPersonality = angular.copy($scope.myPersonality);
			}
			
			defer.resolve(resp);
		}, function (err) {
			defer.reject(err);
		});	
		
		return defer.promise;
	};
	
	$scope.cancelMotivatorsStep = function () {
		$scope.myPersonality = angular.copy($scope.copyOfMyPersonality);
		$state.go('myPersonality.home');
	};
	
	$scope.finishFromMotivators = function () {
		var defer = $q.defer();
		
		GApi.executeAuth('lmi2Api', 'personality.saveMotivators', { 
			motivators: $scope.myPersonality.motivators
		}).then(function(resp) {
			if (resp && resp.items) {
				$scope.myPersonality.motivators = angular.copy(resp.items);
				$scope.copyOfMyPersonality = angular.copy($scope.myPersonality);
			}
			
			defer.resolve(resp);
		}, function (err) {
			defer.reject(err);
		});	
				
		return defer.promise;
	};
	
	function getMyPersonality() {
		$scope.isLoading = true;
		GApi.executeAuth('lmi2Api', 'personality.getMyPersonality', { }).then(function(resp) {
			$scope.myPersonality = null;
			$scope.copyOfMyPersonality = null;
			
			if (resp && resp.result) {
				$scope.myPersonality = resp.result;
				$scope.copyOfMyPersonality = angular.copy(resp.result);
			}
				
			$scope.isLoading = false;
		}, function (err) {
			$scope.isLoading = false;
		});	
	}
	
	$state.go('myPersonality.home'); // Redirect to index
	getMyPersonality();
	
}]);
var app = angular.module('app');

app.controller('MyPersonalityPreferencesController', ["$rootScope", "$scope", "$state", "NotificationsService", function ($rootScope, $scope, $state, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.isSaving = false;
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
		
	$scope.cancel = function () {		
		$scope.$parent.$parent.cancelPreferencesStep();		
	};
	
	$scope.next = function () {
		$scope.isSaving = true;

		if (!$scope.spocCareUserAccess) {
			$scope.$parent.$parent.nextFromPreferences().then(function () {
				$scope.isSaving = false;
				$state.go('myPersonality.motivators');
			}, function (err) {
				NotificationsService.show(getErrorMessage('my-personality', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
				$scope.isSaving = false;
			});
		} else {
			$state.go('myPersonality.motivators');
		}
	};
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		$.material.init();		
	});
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}
	
}]);
var app = angular.module('app');

app.controller('OtherCompetencesContentController', [ '$rootScope', '$scope', '$state', '$modal', '$http', 'RolesManager', 'GApi', 'NotificationsService', '$sce',
                                     function($rootScope, $scope, $state, $modal, $http,rolesManager, GApi, NotificationsService, $sce) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;

	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};
	
	//Begin - FeedbackMatrix table
	//$scope.results = [0,1,2,3,4];
	$scope.results = [{id: '5',displayName: $scope.getTranslation("core", "not-yet-develop-define-value")},
	               {id: '1',displayName: '1'}, 
	               {id: '2',displayName: '2'},
	               {id: '3',displayName: '3'},
	               {id: '4',displayName: '4'}];
	
	$scope.userChoice = [];
    $scope.val = 0;
    $scope.feedbackList=null;
    $scope.feedbackPopup = "";
    $scope.showLoading = true;
    $scope.mapperEmail = "";
    $scope.mappingDate = "";
    $scope.noData = true;

    /*
    $scope.feedbackList = {
		"items": [
			{
				"headerName": "Competences",
				"dataRecord": [
					"Behavior",
					"",
					"",
					"",
					"",
					"Skills",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"Knowledge",
					"",
					"",
					"",
					"",
					"Knowledge: Financial Instituions",
					"",
					"",
					"",
					"Knowledge: Public Specific",
					"",
					"",
					""
				]
			},{
				"headerName": "",
				"dataRecord": [
					"START",
					"Behavior",
					"",
					"",
					"",
					"START",
					"Problem-solving",
					"",
					"",
					"",
					"",
					"Methodological",
					"",
					"",
					"",
					"START",
					"Generic knowledge",
					"",
					"Project Management",
					"",
					"START",
					"Value Chain",
					"",
					"",
					"START",
					"Functional Finance",
					"",
					""
				]
			},
			{
				"headerName": " ",
				"dataRecord": [
					"START",
					"",
					"Self-starting",
					"Self-propelling",
					"Self-marketing ",
					"START",
					"",
					"Daring to communicate",
					"Teamwork",
					"Methodological",
					"People Management ",
					"",
					"Business Acumen",
					"Business Development",
					"Process Management",
					"START",
					"",
					"Controlling & Financial Analyses",
					"",
					"Project Management",
					"START",
					"",
					"Change Management",
					"Financial Accounting & Reporting",
					"START",
					"",
					"Risk Management",
					"Financial Systems & Applications"
				]
			},
			{
				"headerName": "Me",
				"dataRecord": [
					"START",
					"",
					"3",
					"3",
					"3",
					"START",
					"",
					"3",
					"3",
					"3",
					"2",
					"",
					"3",
					"3",
					"3",
					"START",
					"",
					"3",
					"",
					"3",
					"START",
					"",
					"3",
					"3",
					"START",
					"",
					"3",
					"3"
				]
			},
			{
				"headerName": "lennart.benoot@trifinance.be",
				"dataRecord": [
					"START",
					"",
					"3",
					"3",
					"3",
					"START",
					"",
					"3",
					"3",
					"3",
					"2",
					"",
					"3",
					"3",
					"3",
					"START",
					"",
					"3",
					"",
					"3",
					"START",
					"",
					"3",
					"3",
					"START",
					"",
					"3",
					"3"
				]
			}
		]
	};
    */

    $scope.myStyleFunction = function($index) {
    	  return {
    	    'width': $index < 3 ? '130px' : '80px',
    	    'word-wrap': $index ? '' : 'break-word',
			  'text-align': $index >= 3 ? 'center' : ''
    	  };
    };

    /* old api
	GApi.executeAuth('lmi2Api', 'otherCompetences.getFeedbackMatrix', { 
	}).then(function(resp) {
		// $scope.feedbackList = resp;
       
		$scope.tableSize = $scope.feedbackList.items[0].dataRecord.length;
		
		// for(var i=0; i<$scope.feedbackList.items.length; i++){
        //     if($scope.feedbackList.items[i].headerName === "Adjusted Value"){
        //         $scope.userChoice= $scope.feedbackList.items[i].dataRecord;
        //     }
		// }
		
		$scope.showLoading = false;
		
	}, function (err) {
		
	});
	*/

	 GApi.executeAuth('lmi2Api', 'otherCompetences.getOthersCompetence', {
	 }).then(function(resp) {
		 // $scope.feedbackList = resp;
		if (resp["tableValue"]) {
			$scope.deltaRecord = createDelta(resp["tableValue"]["competence"], resp["tableValue"]["otherCompetence"]);
			$scope.feedbackList = {
				"items": [
					{
						"headerName": "Competences",
						"dataRecord": resp["tableValue"]["main"]
					},
					{
						"headerName": "",
						"dataRecord": resp["tableValue"]["group"]
					},
					{
						"headerName": "",
						"dataRecord": resp["tableValue"]["competenceName"]
					},
					{
						"headerName": "Me",
						"dataRecord": resp["tableValue"]["competence"]
					},
					{
						"headerName": resp["mapper"],
						"dataRecord": resp["tableValue"]["otherCompetence"]
					},
					{
						"headerName": "Delta",
						"dataRecord": $scope.deltaRecord
					}

				]
			};
			$scope.noData = false;
			$scope.mapperEmail = resp["mapper"];
			$scope.mappingDate = resp["dateDone"];
			$scope.tableSize = $scope.feedbackList.items[0].dataRecord.length;
		} else {
			if (resp["noData"]) {
				noInformationPopup();
				$scope.noData = true;
			} else {
				NotificationsService.show(getErrorMessage('other-competences', 'other-competences-error'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				$scope.noData = true;
			}
			$scope.showLoading = false;
			$state.go("otherCompetences.list");
		}

		 // GApi.executeAuth('lmi2Api', 'otherCompetences.getOthersCompetence', {}).then(function(resp) {
	 // $http.get('http://127.0.0.1:8887/otherCompetenceValue.json').then(function(resp) {
		//  if (resp.data["tableValue"]) {
		// 	 $scope.deltaRecord = createDelta(resp.data["tableValue"]["competence"], resp.data["tableValue"]["otherCompetence"]);
		// 	 $scope.feedbackList = {
		// 		 "items": [
		// 			 {
		// 				 "headerName": "Competences",
		// 				 "dataRecord": resp.data["tableValue"]["main"]
		// 			 },
		// 			 {
		// 				 "headerName": "",
		// 				 "dataRecord": resp.data["tableValue"]["group"]
		// 			 },
		// 			 {
		// 				 "headerName": "",
		// 				 "dataRecord": resp.data["tableValue"]["competenceName"]
		// 			 },
		// 			 {
		// 				 "headerName": "Me",
		// 				 "dataRecord": resp.data["tableValue"]["competence"]
		// 			 },
		// 			 {
		// 				 "headerName": resp.data["mapper"],
		// 				 "dataRecord": resp.data["tableValue"]["otherCompetence"]
		// 			 },
		// 			 {
		// 				 "headerName": "Delta",
		// 				 "dataRecord": $scope.deltaRecord
		// 			 }
	 //
		// 		 ]
		// 	 };
		// 	 $scope.noData = false;
		// 	 $scope.mapperEmail = resp.data["mapper"];
		// 	 $scope.mappingDate = resp.data["dateDone"];
		// 	 $scope.tableSize = $scope.feedbackList.items[0].dataRecord.length;
		//  } else {
		// 	 if (resp.data["noData"]) {
		// 		 noInformationPopup();
		// 		 $scope.noData = true;
		// 	 } else {
		// 		 NotificationsService.show(getErrorMessage('other-competences', 'other-competences-error'), NOTIFICATIONS_FADEOUT_TIMEOUT);
		// 		 $scope.noData = true;
		// 	 }
		// 	 $scope.showLoading = false;
		// 	 $state.go("otherCompetences.list");
		//  }

		function createDelta(meCompetence, otherCompetences){
			var deltaRecords = [];
			if (meCompetence.length === otherCompetences.length) {
				for (var i = 0; i < meCompetence.length; i++) {
					if (meCompetence[i] == "START" || meCompetence[i] === "") {
						deltaRecords.push(meCompetence[i]);
					} else {
						var delta = parseInt(otherCompetences[i]) - parseInt(meCompetence[i]);
						if (delta > 0) {
							deltaRecords.push("+" + delta.toString());
						}
						if (delta < 0) {
							deltaRecords.push(delta.toString());
						}
						if (delta === 0) {
							deltaRecords.push(delta.toString());
						}
					}
				}
			}
			return deltaRecords;
		}

		function noInformationPopup() {
			 modalInstance = $modal.open({
				 templateUrl: 'noInformation.html',
				 controller: 'NoInformationPopup',
				 backdrop: true,
				 resolve: {
					 translations: function () {
						 return $scope.translations;
					 }
				 }
			 });
			 modalInstance.result.then(function () {
				 $state.go('otherCompetences.list');
			 }, function (err) {
			 });
		}

		 // for(var i=0; i<$scope.feedbackList.items.length; i++){
		 //     if($scope.feedbackList.items[i].headerName === "Adjusted Value"){
		 //         $scope.userChoice= $scope.feedbackList.items[i].dataRecord;
		 //     }
		 // }

		 $scope.showLoading = false;

	 }, function (err) {
	 	console.log(err);
		 $scope.showLoading = false;
	 });
	
	$scope.changeValue = function(rowIndex){
       
        GApi.executeAuth('lmi2Api', 'otherCompetences.updateOtherCompetences', { 
    		competenceId: $scope.userChoice[rowIndex].id,
    		rating: $scope.userChoice[rowIndex].adjustedValue
    	}).then(function() {
    		NotificationsService.show($scope.getTranslation("core", "saved"), NOTIFICATIONS_FADEOUT_TIMEOUT);
    	}, function (err) {
    		NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
    	});
    };
	
	$scope.getSize=function(n){
        return new Array(n);
    };

    
    $scope.viewFeedback = function (item) {
    	
    	$('#feedbackDialogModal').modal();
    	$scope.feedbackPopup = item;
		
	};
	
	$scope.subStringHeader = function (headerName) {
	    //var breakWord = headerName.indexOf("@")+1;
		var breakAliasWord = headerName.indexOf("@");
	    var text = headerName.substring(0, breakAliasWord);
	    
	    if(text){ //if the text is empty, get again the word
	    	text = text.replace(/\./g," ");

		    text = text.toLowerCase().replace(/\b[a-z]/g, function(letter) {
		        return letter.toUpperCase();
		    });

	    }
	    else{
	    	//text = headerName.substring(breakAliasWord+1);
	    	return headerName;
	    }
	    
	    return text;
	};
	
	$scope.displayData = function (headerName, dataRecord) {
		/*
		if(headerName != "Competences" && headerName != "Adjusted Value" && headerName != "Average"){
			 if(dataRecord === "5" || dataRecord === 5){
			    	//return "0";
				 return $scope.getTranslation("core", "not-yet-develop-define-value");
			 }
		}
		else if(headerName === "Average"){
			if(dataRecord === "0.0"){
			 return $scope.getTranslation("core", "not-yet-develop-define-value");
			}
		}
		*/
		switch (dataRecord){
			case "START":
				dataRecord = "";
				break;

			case "Behaviour":
				dataRecord = "Behavior";
				break;
		}

	    return dataRecord;
	};
	
	$scope.showSeperator = function(row, index) {
		if (row.headerName === 'Competences' && row.dataRecord[index] !== '') return true;
		
		if (row.dataRecord[index] === 'START' || row.dataRecord[index] === 'No Data Available') return true;
	};

	$scope.getColor = function(row, index){
		var color = "black";
		if (row.headerName === 'Delta' && row.dataRecord[index] !== ''){
			if (parseInt(row.dataRecord[index]) > 0) {
				color = "green";
			}
			if (parseInt(row.dataRecord[index]) === 0) {
				color = "blue";
			}
			if (parseInt(row.dataRecord[index]) < 0) {
				color = "red";
			}
		}
		return color;
	};
    
   //End - FeedbackMatrix table
	
	//Begin - I'm Done Now
	$scope.doneOtherCompetences = function () {
		
		GApi.executeAuth('lmi2Api', 'otherCompetences.doneOtherCompetences', { 
		}).then(function(resp) {
			if(resp.items[0] == "nok"){
				$('#doneOtherCompetencesModal').modal();
			}
			else{
				$state.go('home');
			}
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	};
	
	//End - I'm Done Now

	var AUTOCOMPLETE_MIN_LENGTH = 2;

	var criteriaLength = 0;
	var cachedSuggestions = [];
	$scope.isSaving = false;
	$scope.criteria = '';
	$scope.suggestions = [];
	$scope.selectedUsers = [];	
	$scope.validateEmailFormat = false;

	$scope.getHTMLFormat = function(content){
	    return $sce.trustAsHtml(content);
	};
	
	$scope.otherCompetence={
		email: $scope.selectedUsers,
		emailSubject: $scope.getTranslation("other-competences", "email-subject"),
		emailText: $scope.getTranslation("other-competences", "email-text").replace(/<br\s*[\/]?>/gi,"\n").replace("[your name]", $rootScope.user.name)
	};
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}	
	
	$scope.onKeyDown = function(ev) {
		//criteriaLength = $scope.criteria.length;
	};
	
	$scope.selectSuggestedUser = function (userId) {
		var user = _.find(cachedSuggestions, function (item) {
			return item.userId === userId;
		});
		
		if (user) $scope.selectedUsers.push(user);
		
		$scope.criteria = '';
		$('[name=search]').focus();
	};
	
	$scope.filterSuggestions = function (value, index, array) {
		var criteria = new RegExp($scope.criteria.toLowerCase());
		var exists = _.find($scope.selectedUsers, function (user) { return user.userId === value.userId; });
		
		return !exists && (value.userId.toLowerCase().match(criteria) ||
				(value.fullName || '').toLowerCase().match(criteria));
	};
	
	$scope.removeUser = function (userId, email) {
		if ($scope.isSaving) return;
		
//		$scope.selectedUsers = _.filter($scope.selectedUsers, function (user) {
//			return user.userId !== userId;
//		});
		
		$scope.selectedUsers = _.filter($scope.selectedUsers, function (user) {
			return user.email !== email;
		});		
	};
	
	$scope.$watch('criteria', function () {
		if ($scope.criteria.length === AUTOCOMPLETE_MIN_LENGTH && criteriaLength < AUTOCOMPLETE_MIN_LENGTH) {
			// Get auto complete suggestions
			GApi.executeAuth('lmi2Api', 'auth.suggestUsers', 
					{ criteria: $scope.criteria }
			).then(function(resp) {
				if (resp && resp.items && _.isArray(resp.items)) {
					cachedSuggestions = resp.items;
					$scope.suggestions = resp.items;
				}
			});			
		} else if ($scope.criteria.length < AUTOCOMPLETE_MIN_LENGTH) {
			// Clear auto complete suggestions
			cachedSuggestions = [];
			$scope.suggestions = [];
		}
	});
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material checkboxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('[name=search]').focus();
	});	
	
	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	$scope.submitForm = function () {
		var submitArr=[];
		
		if ($scope.otherCompetence.emailText.indexOf("<<applicationLink>>") =='-1'){
			$scope.isSaving = false;
			NotificationsService.show(getErrorMessage('other-competences', 'app-link-missing'), NOTIFICATIONS_FADEOUT_TIMEOUT);
			return;
		}
		for(var i=0; i< $scope.otherCompetence.email.length; i++){
			submitArr.push({
				receipient:$scope.otherCompetence.email[i].email,
				emailSubject:$scope.otherCompetence.emailSubject,
				emailText:$scope.otherCompetence.emailText
			});
		}	
				

		$scope.isSaving = true;
		GApi.executeAuth('lmi2Api', 'otherCompetences.submitEmail', {
			invitationEmail: submitArr,
			sender: $rootScope.user.email
		}).then(function(resp) {
			$scope.isSaving = false;
			NotificationsService.show(getErrorMessage('other-competences', 'message-success-submit'), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$state.go("otherCompetences.list");
			//$state.reload();
		}, function (err) {
			$scope.isSaving = false;
			NotificationsService.show(getErrorMessage('other-competences', err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
		
	};
	
	$scope.checkEmailFormat = function () {
	    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	    $scope.validateEmailFormat = re.test($scope.criteria);
	    
	    if(!$scope.validateEmailFormat){
	    	NotificationsService.show(getErrorMessage('other-competences', 'message-invalid-email-address'), NOTIFICATIONS_FADEOUT_TIMEOUT);
	    	return;
	    }else{
	    	$scope.selectedUsers.push({email:$scope.criteria, fullName:$scope.criteria});	
	    	$scope.criteria = "";
	    }
	};
} ]);


app.controller('NoInformationPopup', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance', 'translations',
function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, translations) {
	$scope.translations = translations;

	$scope.closeModalDialog = function () {
		$modalInstance.dismiss('cancel');
	};

} ]);
var app = angular.module('app');

app.controller('OtherCompetencesListController', [ '$rootScope', '$scope', '$state', '$modal', 'RolesManager', 'GApi', 'NotificationsService', '$sce',
                                     function($rootScope, $scope, $state, $modal, rolesManager, GApi, NotificationsService, $sce) {
	
	//Begin - I'm Done Now
	$scope.doneOtherCompetences = function () {
		
		// GApi.executeAuth('lmi2Api', 'otherCompetences.doneOtherCompetences', {
		// }).then(function(resp) {
		// 	if(resp.items[0] == "nok"){
		// 		$('#doneOtherCompetencesModal').modal();
		// 	}
		// 	else{
				$state.go('home');
			// }
		// }, function (err) {
		// 	NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		// });
	};
	//End - I'm Done Now


	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.getTranslation = function (pkgName, key) {
		var translations = $scope.translations || { };
		
		if (translations[pkgName] && translations[pkgName][key]) return translations[pkgName][key];
		else return key;
	};
	
	$scope.otherCompetence={
		email: $scope.selectedUsers,
		emailSubject: $scope.getTranslation("other-competences", "email-subject"),
		emailText: $scope.getTranslation("other-competences", "email-text").replace(/<br\s*[\/]?>/gi,"\n")
	};
	
	function getErrorMessage(pkg, key) {
		if ($scope.translations && $scope.translations[pkg] && $scope.translations[pkg][key]) return $scope.translations[pkg][key];
		else return key;
	}	
} ]);

/**
 * dirPagination - AngularJS module for paginating (almost) anything.
 *
 *
 * Credits
 * =======
 *
 * Daniel Tabuenca: https://groups.google.com/d/msg/angular/an9QpzqIYiM/r8v-3W1X5vcJ
 * for the idea on how to dynamically invoke the ng-repeat directive.
 *
 * I borrowed a couple of lines and a few attribute names from the AngularUI Bootstrap project:
 * https://github.com/angular-ui/bootstrap/blob/master/src/pagination/pagination.js
 *
 * Copyright 2014 Michael Bromley <michael@michaelbromley.co.uk>
 */

(function() {

    /**
     * Config
     */
    var moduleName = 'angularUtils.directives.dirPagination';
    var DEFAULT_ID = '__default';

    /**
     * Module
     */
    var module;
    try {
        module = angular.module(moduleName);
    } catch(err) {
        // named module does not exist, so create one
        module = angular.module(moduleName, []);
    }

    module
        .directive('dirPaginate', ['$compile', '$parse', 'paginationService', dirPaginateDirective])
        .directive('dirPaginateNoCompile', noCompileDirective)
        .directive('dirPaginationControls', ['paginationService', 'paginationTemplate', dirPaginationControlsDirective])
        .filter('itemsPerPage', ['paginationService', itemsPerPageFilter])
        .service('paginationService', paginationService)
        .provider('paginationTemplate', paginationTemplateProvider);

    function dirPaginateDirective($compile, $parse, paginationService) {

        return  {
            terminal: true,
            multiElement: true,
            compile: dirPaginationCompileFn
        };

        function dirPaginationCompileFn(tElement, tAttrs){

            var expression = tAttrs.dirPaginate;
            // regex taken directly from https://github.com/angular/angular.js/blob/master/src/ng/directive/ngRepeat.js#L211
            var match = expression.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);

            var filterPattern = /\|\s*itemsPerPage\s*:[^|]*/;
            if (match[2].match(filterPattern) === null) {
                throw 'pagination directive: the \'itemsPerPage\' filter must be set.';
            }
            var itemsPerPageFilterRemoved = match[2].replace(filterPattern, '');
            var collectionGetter = $parse(itemsPerPageFilterRemoved);

            addNoCompileAttributes(tElement);

            // If any value is specified for paginationId, we register the un-evaluated expression at this stage for the benefit of any
            // dir-pagination-controls directives that may be looking for this ID.
            var rawId = tAttrs.paginationId || DEFAULT_ID;
            paginationService.registerInstance(rawId);

            return function dirPaginationLinkFn(scope, element, attrs){

                // Now that we have access to the `scope` we can interpolate any expression given in the paginationId attribute and
                // potentially register a new ID if it evaluates to a different value than the rawId.
                var paginationId = $parse(attrs.paginationId)(scope) || attrs.paginationId || DEFAULT_ID;
                paginationService.registerInstance(paginationId);

                var repeatExpression = getRepeatExpression(expression, paginationId);
                addNgRepeatToElement(element, attrs, repeatExpression);

                removeTemporaryAttributes(element);
                var compiled =  $compile(element);

                var currentPageGetter = makeCurrentPageGetterFn(scope, attrs, paginationId);
                paginationService.setCurrentPageParser(paginationId, currentPageGetter, scope);

                if (typeof attrs.totalItems !== 'undefined') {
                    paginationService.setAsyncModeTrue(paginationId);
                    scope.$watch(function() {
                        return $parse(attrs.totalItems)(scope);
                    }, function (result) {
                        if (0 <= result) {
                            paginationService.setCollectionLength(paginationId, result);
                        }
                    });
                } else {
                    scope.$watchCollection(function() {
                        return collectionGetter(scope);
                    }, function(collection) {
                        if (collection) {
                            paginationService.setCollectionLength(paginationId, collection.length);
                        }
                    });
                }

                // Delegate to the link function returned by the new compilation of the ng-repeat
                compiled(scope);
            };
        }

        /**
         * If a pagination id has been specified, we need to check that it is present as the second argument passed to
         * the itemsPerPage filter. If it is not there, we add it and return the modified expression.
         *
         * @param expression
         * @param paginationId
         * @returns {*}
         */
        function getRepeatExpression(expression, paginationId) {
            var repeatExpression,
                idDefinedInFilter = !!expression.match(/(\|\s*itemsPerPage\s*:[^|]*:[^|]*)/);

            if (paginationId !== DEFAULT_ID && !idDefinedInFilter) {
                repeatExpression = expression.replace(/(\|\s*itemsPerPage\s*:[^|]*)/, "$1 : '" + paginationId + "'");
            } else {
                repeatExpression = expression;
            }

            return repeatExpression;
        }

        /**
         * Adds the ng-repeat directive to the element. In the case of multi-element (-start, -end) it adds the
         * appropriate multi-element ng-repeat to the first and last element in the range.
         * @param element
         * @param attrs
         * @param repeatExpression
         */
        function addNgRepeatToElement(element, attrs, repeatExpression) {
            if (element[0].hasAttribute('dir-paginate-start') || element[0].hasAttribute('data-dir-paginate-start')) {
                // using multiElement mode (dir-paginate-start, dir-paginate-end)
                attrs.$set('ngRepeatStart', repeatExpression);
                element.eq(element.length - 1).attr('ng-repeat-end', true);
            } else {
                attrs.$set('ngRepeat', repeatExpression);
            }
        }

        /**
         * Adds the dir-paginate-no-compile directive to each element in the tElement range.
         * @param tElement
         */
        function addNoCompileAttributes(tElement) {
            angular.forEach(tElement, function(el) {
                if (el.nodeType === Node.ELEMENT_NODE) {
                    angular.element(el).attr('dir-paginate-no-compile', true);
                }
            });
        }

        /**
         * Removes the variations on dir-paginate (data-, -start, -end) and the dir-paginate-no-compile directives.
         * @param element
         */
        function removeTemporaryAttributes(element) {
            angular.forEach(element, function(el) {
                if (el.nodeType === Node.ELEMENT_NODE) {
                    angular.element(el).removeAttr('dir-paginate-no-compile');
                }
            });
            element.eq(0).removeAttr('dir-paginate-start').removeAttr('dir-paginate').removeAttr('data-dir-paginate-start').removeAttr('data-dir-paginate');
            element.eq(element.length - 1).removeAttr('dir-paginate-end').removeAttr('data-dir-paginate-end');
        }

        /**
         * Creates a getter function for the current-page attribute, using the expression provided or a default value if
         * no current-page expression was specified.
         *
         * @param scope
         * @param attrs
         * @param paginationId
         * @returns {*}
         */
        function makeCurrentPageGetterFn(scope, attrs, paginationId) {
            var currentPageGetter;
            if (attrs.currentPage) {
                currentPageGetter = $parse(attrs.currentPage);
            } else {
                // if the current-page attribute was not set, we'll make our own
                var defaultCurrentPage = paginationId + '__currentPage';
                scope[defaultCurrentPage] = 1;
                currentPageGetter = $parse(defaultCurrentPage);
            }
            return currentPageGetter;
        }
    }

    /**
     * This is a helper directive that allows correct compilation when in multi-element mode (ie dir-paginate-start, dir-paginate-end).
     * It is dynamically added to all elements in the dir-paginate compile function, and it prevents further compilation of
     * any inner directives. It is then removed in the link function, and all inner directives are then manually compiled.
     */
    function noCompileDirective() {
        return {
            priority: 5000,
            terminal: true
        };
    }

    function dirPaginationControlsDirective(paginationService, paginationTemplate) {

        var numberRegex = /^\d+$/;

        return {
            restrict: 'AE',
            template: '<ul class="pagination" ng-if="1 < pages.length">' +
            	'<li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == 1 }">' +
            		'<a href="" ng-click="setCurrent(1)">&laquo;</a>' +
            	'</li>' +
        		'<li ng-if="directionLinks" ng-class="{ disabled : pagination.current == 1 }" class="ng-scope">' +
            		'<a href="" ng-click="setCurrent(pagination.current - 1)" class="ng-binding"><</a>' +
            	'</li>' +
        		'<li ng-repeat="pageNumber in pages track by $index" ng-class="{ active : pagination.current == pageNumber, disabled : pageNumber == \'...\' }">' +
            		'<a href="" ng-click="setCurrent(pageNumber)">{{ pageNumber }}</a>' +
            	'</li>' +
        		'<li ng-if="directionLinks" ng-class="{ disabled : pagination.current == pagination.last }" class="ng-scope">' +
            		'<a href="" ng-click="setCurrent(pagination.current + 1)" class="ng-binding">></a>' +
            	'</li>' +
        		'<li ng-if="boundaryLinks"  ng-class="{ disabled : pagination.current == pagination.last }">' +
            		'<a href="" ng-click="setCurrent(pagination.last)">&raquo;</a>' +
            	'</li>' +
        	'</ul>',
            scope: {
                maxSize: '=?',
                onPageChange: '&?',
                paginationId: '=?'
            },
            link: dirPaginationControlsLinkFn
        };

        function dirPaginationControlsLinkFn(scope, element, attrs) {

            // rawId is the un-interpolated value of the pagination-id attribute. This is only important when the corresponding dir-paginate directive has
            // not yet been linked (e.g. if it is inside an ng-if block), and in that case it prevents this controls directive from assuming that there is
            // no corresponding dir-paginate directive and wrongly throwing an exception.
            var rawId = attrs.paginationId ||  DEFAULT_ID;
            var paginationId = scope.paginationId || attrs.paginationId ||  DEFAULT_ID;

            if (!paginationService.isRegistered(paginationId) && !paginationService.isRegistered(rawId)) {
                var idMessage = (paginationId !== DEFAULT_ID) ? ' (id: ' + paginationId + ') ' : ' ';
                throw 'pagination directive: the pagination controls' + idMessage + 'cannot be used without the corresponding pagination directive.';
            }

            if (!scope.maxSize) { scope.maxSize = 9; }
            scope.directionLinks = angular.isDefined(attrs.directionLinks) ? scope.$parent.$eval(attrs.directionLinks) : true;
            scope.boundaryLinks = angular.isDefined(attrs.boundaryLinks) ? scope.$parent.$eval(attrs.boundaryLinks) : false;

            var paginationRange = Math.max(scope.maxSize, 5);
            scope.pages = [];
            scope.pagination = {
                last: 1,
                current: 1
            };
            scope.range = {
                lower: 1,
                upper: 1,
                total: 1
            };

            scope.$watch(function() {
                return (paginationService.getCollectionLength(paginationId) + 1) * paginationService.getItemsPerPage(paginationId);
            }, function(length) {
                if (0 < length) {
                    generatePagination();
                }
            });

            scope.$watch(function() {
                return (paginationService.getItemsPerPage(paginationId));
            }, function(current, previous) {
                if (current != previous && typeof previous !== 'undefined') {
                    goToPage(scope.pagination.current);
                }
            });

            scope.$watch(function() {
                return paginationService.getCurrentPage(paginationId);
            }, function(currentPage, previousPage) {
                if (currentPage != previousPage) {
                    goToPage(currentPage);
                }
            });

            scope.setCurrent = function(num) {
                if (isValidPageNumber(num)) {
                    num = parseInt(num, 10);
                    paginationService.setCurrentPage(paginationId, num);
                }
            };

            function goToPage(num) {
                if (isValidPageNumber(num)) {
                    scope.pages = generatePagesArray(num, paginationService.getCollectionLength(paginationId), paginationService.getItemsPerPage(paginationId), paginationRange);
                    scope.pagination.current = num;
                    updateRangeValues();

                    // if a callback has been set, then call it with the page number as an argument
                    if (scope.onPageChange) {
                        scope.onPageChange({ newPageNumber : num });
                    }
                }
            }

            function generatePagination() {
                var page = parseInt(paginationService.getCurrentPage(paginationId)) || 1;

                scope.pages = generatePagesArray(page, paginationService.getCollectionLength(paginationId), paginationService.getItemsPerPage(paginationId), paginationRange);
                scope.pagination.current = page;
                scope.pagination.last = scope.pages[scope.pages.length - 1];
                if (scope.pagination.last < scope.pagination.current) {
                    scope.setCurrent(scope.pagination.last);
                } else {
                    updateRangeValues();
                }
            }

            /**
             * This function updates the values (lower, upper, total) of the `scope.range` object, which can be used in the pagination
             * template to display the current page range, e.g. "showing 21 - 40 of 144 results";
             */
            function updateRangeValues() {
                var currentPage = paginationService.getCurrentPage(paginationId),
                    itemsPerPage = paginationService.getItemsPerPage(paginationId),
                    totalItems = paginationService.getCollectionLength(paginationId);

                scope.range.lower = (currentPage - 1) * itemsPerPage + 1;
                scope.range.upper = Math.min(currentPage * itemsPerPage, totalItems);
                scope.range.total = totalItems;
            }

            function isValidPageNumber(num) {
                return (numberRegex.test(num) && (0 < num && num <= scope.pagination.last));
            }
        }

        /**
         * Generate an array of page numbers (or the '...' string) which is used in an ng-repeat to generate the
         * links used in pagination
         *
         * @param currentPage
         * @param rowsPerPage
         * @param paginationRange
         * @param collectionLength
         * @returns {Array}
         */
        function generatePagesArray(currentPage, collectionLength, rowsPerPage, paginationRange) {
            var pages = [];
            var totalPages = Math.ceil(collectionLength / rowsPerPage);
            var halfWay = Math.ceil(paginationRange / 2);
            var position;

            if (currentPage <= halfWay) {
                position = 'start';
            } else if (totalPages - halfWay < currentPage) {
                position = 'end';
            } else {
                position = 'middle';
            }

            var ellipsesNeeded = paginationRange < totalPages;
            var i = 1;
            while (i <= totalPages && i <= paginationRange) {
                var pageNumber = calculatePageNumber(i, currentPage, paginationRange, totalPages);

                var openingEllipsesNeeded = (i === 2 && (position === 'middle' || position === 'end'));
                var closingEllipsesNeeded = (i === paginationRange - 1 && (position === 'middle' || position === 'start'));
                if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                    pages.push('...');
                } else {
                    pages.push(pageNumber);
                }
                i ++;
            }
            return pages;
        }

        /**
         * Given the position in the sequence of pagination links [i], figure out what page number corresponds to that position.
         *
         * @param i
         * @param currentPage
         * @param paginationRange
         * @param totalPages
         * @returns {*}
         */
        function calculatePageNumber(i, currentPage, paginationRange, totalPages) {
            var halfWay = Math.ceil(paginationRange/2);
            if (i === paginationRange) {
                return totalPages;
            } else if (i === 1) {
                return i;
            } else if (paginationRange < totalPages) {
                if (totalPages - halfWay < currentPage) {
                    return totalPages - paginationRange + i;
                } else if (halfWay < currentPage) {
                    return currentPage - halfWay + i;
                } else {
                    return i;
                }
            } else {
                return i;
            }
        }
    }

    /**
     * This filter slices the collection into pages based on the current page number and number of items per page.
     * @param paginationService
     * @returns {Function}
     */
    function itemsPerPageFilter(paginationService) {

        return function(collection, itemsPerPage, paginationId) {
            if (typeof (paginationId) === 'undefined') {
                paginationId = DEFAULT_ID;
            }
            if (!paginationService.isRegistered(paginationId)) {
                throw 'pagination directive: the itemsPerPage id argument (id: ' + paginationId + ') does not match a registered pagination-id.';
            }
            var end;
            var start;
            if (collection instanceof Array) {
                itemsPerPage = parseInt(itemsPerPage) || 9999999999;
                if (paginationService.isAsyncMode(paginationId)) {
                    start = 0;
                } else {
                    start = (paginationService.getCurrentPage(paginationId) - 1) * itemsPerPage;
                }
                end = start + itemsPerPage;
                paginationService.setItemsPerPage(paginationId, itemsPerPage);

                return collection.slice(start, end);
            } else {
                return collection;
            }
        };
    }

    /**
     * This service allows the various parts of the module to communicate and stay in sync.
     */
    function paginationService() {

        var instances = {};
        var lastRegisteredInstance;

        this.registerInstance = function(instanceId) {
            if (typeof instances[instanceId] === 'undefined') {
                instances[instanceId] = {
                    asyncMode: false
                };
                lastRegisteredInstance = instanceId;
            }
        };

        this.isRegistered = function(instanceId) {
            return (typeof instances[instanceId] !== 'undefined');
        };

        this.getLastInstanceId = function() {
            return lastRegisteredInstance;
        };

        this.setCurrentPageParser = function(instanceId, val, scope) {
            instances[instanceId].currentPageParser = val;
            instances[instanceId].context = scope;
        };
        this.setCurrentPage = function(instanceId, val) {
            instances[instanceId].currentPageParser.assign(instances[instanceId].context, val);
        };
        this.getCurrentPage = function(instanceId) {
            var parser = instances[instanceId].currentPageParser;
            return parser ? parser(instances[instanceId].context) : 1;
        };

        this.setItemsPerPage = function(instanceId, val) {
            instances[instanceId].itemsPerPage = val;
        };
        this.getItemsPerPage = function(instanceId) {
            return instances[instanceId].itemsPerPage;
        };

        this.setCollectionLength = function(instanceId, val) {
            instances[instanceId].collectionLength = val;
        };
        this.getCollectionLength = function(instanceId) {
            return instances[instanceId].collectionLength;
        };

        this.setAsyncModeTrue = function(instanceId) {
            instances[instanceId].asyncMode = true;
        };

        this.isAsyncMode = function(instanceId) {
            return instances[instanceId].asyncMode;
        };
    }

    /**
     * This provider allows global configuration of the template path used by the dir-pagination-controls directive.
     */
    function paginationTemplateProvider() {

        var templatePath = 'directives/pagination/dirPagination.tpl.html';

        this.setPath = function(path) {
            templatePath = path;
        };

        this.$get = function() {
            return {
                getPath: function() {
                    return templatePath;
                }
            };
        };
    }
})();

var app = angular.module('app');

app.controller('MenuController', [ '$rootScope', '$scope', '$state', '$window', 'RolesManager',
    function($rootScope, $scope, $state, $window, rolesManager) {

        $scope.isUserLoggedIn = false;
        $scope.isLoggingIn = false;
        $scope.isAdmin = false;
        $scope.isWorkFlowViewer = false;
        $scope.isReportViewer = false;
        $scope.isSpocCareOnly = false;
        $scope.user = null;
        $scope.translations = $rootScope.stateChangeStart.data &&
        $rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

        $rootScope.$on('$stateChangeSuccess',
            function(event, toState, toParams, fromState, fromParams) {

                $scope.translations = $rootScope.stateChangeStart.data &&
                $rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

            });

        $scope.isSelected = function (stateName) {
            if ($state, stateName, $state.includes(stateName)) return 'selected btn-warning';
        };

        $scope.gotoAdminInitialAdminPage = function () {
            var states = $state.get();
            var initialAdminState;

            if (states && _.isArray(states)) {
                var adminStates = _.filter(states, function(state) {
                    return state.name && state.name.match(/^admin./) &&
                        state.requiredRoles && !state.abstract &&
                        rolesManager.hasRoles($rootScope.user, state.requiredRoles);
                });

                initialAdminState = _.first(adminStates);
                if (initialAdminState) $state.go(initialAdminState.redirectToState || initialAdminState.name);
            }
        };

        $scope.gotoSpocCareInitialAdminPage = function () {
            var states = $state.get();
            var initialSpocCareState;

            if (states && _.isArray(states)) {
                var spocCareStates = _.filter(states, function(state) {
                    return state.name && state.name.match(/^spoc-care./) &&
                        state.requiredRoles && !state.abstract &&
                        rolesManager.hasRoles($rootScope.user, state.requiredRoles);
                });

                initialSpocCareState = _.first(spocCareStates);
                if (initialSpocCareState) $state.go(initialSpocCareState.redirectToState || initialSpocCareState.name);
            }
        };

        $scope.gotoWorkflowInitialPage = function () {
            var states = $state.get();
            var initialWorkdlowState;

            if (states && _.isArray(states)) {
                var workflowStates = _.filter(states, function(state) {
                    return state.name && state.name.match(/^workflows./) &&
                        state.requiredRoles && !state.abstract &&
                        rolesManager.hasRoles($rootScope.user, state.requiredRoles);
                });

                initialWorkdlowState = _.first(workflowStates);
                if (initialWorkdlowState) $state.go(initialWorkdlowState.redirectToState || initialWorkdlowState.name);
            }
        };

        $scope.openBSKDocument = function () {
            //$window.open('https://www.google.com', '_blank');
            $window.open($scope.translations.core['bsk-document-link'], '_blank');

        };

        $scope.openManualDocument = function () {
            //$window.open('https://www.google.com', '_blank');
            $window.open($scope.translations.core['manual-document-link'], '_blank');

        };

        $rootScope.$on('auth:logging-in', function () {
            $scope.isLoggingIn = true;
        });

        $rootScope.$on('auth:logging-in-completed', function () {
            $scope.isLoggingIn = false;
        });

        $rootScope.$on('auth:logged-in-success', function () {
            toggleMenuOptions();
        });

        $rootScope.$watch('impersonateUserRoles', function () {
            toggleMenuOptions();
        });

        function toggleMenuOptions(impersonate) {
            $scope.isSpocCareOnly = false;// always set to false whenever enter this function
            $scope.user = $rootScope.user;
            $scope.isUserLoggedIn = $rootScope.user !== null && $rootScope.user !== undefined;
            var roles = $rootScope.impersonateUser ? $rootScope.impersonateUserRoles : ($scope.user ? $scope.user.roles : []);
            $scope.isAdmin = roles && _.isArray(roles) && _.contains(roles, 'administrator', 'spoc-care');

            if($scope.isAdmin === false){
                $scope.isSpocCareOnly = roles && _.isArray(roles) && _.contains(roles,'spoc-care');
            }

            $scope.isWorkFlowViewer = roles && _.isArray(roles) && _.intersection(roles, ['care-management', 'unit-leader', 'spoc-care']).length > 0;
            $scope.isReportViewer = roles && _.isArray(roles) && _.intersection(roles, ['administrator', 'care-management', 'spoc-care', 'unit-leader']).length > 0;
        }

        $rootScope.$on('auth:logged-out-success', function () {
            $scope.isUserLoggedIn = false;
            $scope.user = null;
            $scope.isAdmin = false;
            $scope.isWorkFlowViewer = false;
            $scope.isReportViewer = false;
            $scope.isSpocCareOnly = false;
        });

    } ]);
var app = angular.module('app');

app.controller('NavBarController', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                     function($rootScope, $scope, $state, rolesManager) {
	
	$scope.isUserLoggedIn = false;
	$scope.isLoggingIn = false;
	$scope.user = null;
	$scope.impersonateUser = null;
	
	$scope.cancelImpersonation = function () {
		$scope.impersonateUser = null;
		$rootScope.impersonateUser = null;
		$rootScope.impersonateUserRoles = [];
		$rootScope.spocCareUser = null;
		$state.go('home', {}, {reload: true});
	};
	
	$rootScope.$on('auth:logging-in', function () {
		$scope.isLoggingIn = true;
	});
	
	$rootScope.$on('auth:logging-in-completed', function () {
		$scope.isLoggingIn = false;
	});
	
	$rootScope.$on('auth:logged-in-success', function () {
		$scope.isUserLoggedIn = true;
		$scope.user = $rootScope.user;
	});
	
	$rootScope.$on('auth:logged-out-success', function () {
		$scope.isUserLoggedIn = false;
		$scope.user = null;
	});
	
	$rootScope.$watch('impersonateUser', function () {
		$scope.impersonateUser = $rootScope.impersonateUser;
	});

	 $rootScope.$watch('spocCareUser', function () {
		 $scope.spocCareUser = $rootScope.spocCareUser;
	 });
} ]);
var app = angular.module('app');

app.controller('RealizationInsightListController', [ '$rootScope', '$scope', '$state', '$modal', 'RolesManager', 'GApi', 'NotificationsService', 
                                     function($rootScope, $scope, $state, $modal, rolesManager, GApi, NotificationsService) {
	
	// paging param
	var PAGE_SIZE = 10;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	// paging param
	$scope.realizationInsightList=[];
	//$scope.realizationInsightObjectivesList=[];
	listObjectives();
	listRealizationInsight();
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		listRealizationInsight();
	};
	
	function listObjectives(){
		$scope.isListLoadingObjectives = true;
		GApi.executeAuth('lmi2Api', 'realizeMyPlan.getPlanObjectivesList', {
		}).then(function (resp) {
			$scope.realizationInsightObjectivesList = resp.items;
			
			$scope.isListLoadingObjectives = false;

		},function (err) {
			$state.go('home');
		});		
	}
	
	function listRealizationInsight(){
		$scope.isListLoading = true;
		GApi.executeAuth('lmi2Api', 'achievement.getAchievementDetailList', {
			page: $scope.currentPage,
			pageSize: PAGE_SIZE
		}).then(function (resp) {
			$scope.isListLoading = false;
			$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.realizationInsightList = _.map(resp.records, function (realizationInsight) {
					return realizationInsight;
				});
				
				$scope.realizationInsightListCopy = angular.copy($scope.realizationInsightList);
			} else {
				$scope.realizationInsightList = [];
				$scope.realizationInsightListCopy = [];
			}
			
			$scope.pagination = [];
			for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
				$scope.pagination.push(i);
			}
			
			
		},function (err) {
			$state.go('home');
		});			
	}
	
	$scope.showFullObjectives = function (item) {
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'popupRealizationInsightObjectives.html',
			  controller: 'RealizationInsightObjectives',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});	
	};
	
	$scope.completeObjectives = function (item) {
		var allItems = {items: item, translations: $scope.translations};
		modalInstance = $modal.open({
			  templateUrl: 'completeRealizationInsightDialogModal',
			  controller: 'CompleteObjectivesController',
			  backdrop: true,
			  resolve: {
				  allItems: function () {
			      return allItems;
			    }
			  }		      		      
		});	
		modalInstance.result.then(function (item) {
			$state.go('myAchievements.list',{realizeMyPlanId: item.realizeMyPlanId, achievementsId:null, viewMode:false});
	    	//$scope.deleteAchievement(item);
	      }, function (err) {
	    });
	};
	
} ]);

app.controller('RealizationInsightObjectives', [ '$rootScope', '$scope', '$http', 'GApi', '$q','$timeout','$modalInstance','allItems',
                                        function($rootScope, $scope, $http, GApi, $q, $timeout, $modalInstance, allItems) {
     	$scope.items = allItems.items;
    	$scope.translations = allItems.translations;
     	
		$scope.dialogTitle=$scope.translations.core.notice;
		$scope.dialogMsg=$scope.items.objectives;
			
     	$scope.closeModalDialog = function () {
     		$modalInstance.dismiss('cancel');
     	};

} ]);

app.controller('CompleteObjectivesController', [ '$rootScope', '$scope', '$modalInstance','allItems',
                                            function($rootScope, $scope, $modalInstance, allItems) {
         	$scope.items = allItems.items;
        	$scope.translations = allItems.translations;

         	$scope.closeAndDoSomething = function () {
         		$modalInstance.close($scope.items);
         	};	
         	$scope.closeModalDialog = function () {
         		$modalInstance.dismiss('cancel');
         	};

} ]);
var app = angular.module('app');

app.controller('RealizeMyPlanContentController', ["$rootScope", "$scope", "$state", "$modal", "$http", "GApi", "NotificationsService", function($rootScope, $scope, $state, $modal, $http, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.isSaving = false;
	$scope.isAllowSave = false;
	$scope.isContentLoading = true;
	$scope.objectivesTextList = [];
	$scope.objectivesSelectList = [];
	$scope.itemsSelected = [
		{
			'titel': "",
			'bsk_sub_id': 0
		},
		{
			'titel': "",
			'bsk_sub_id': 0
		},
		{
			'titel': "",
			'bsk_sub_id': 0
		}
	];

	$scope.getIconState = function (initialState, workingState) {
		return $scope.isSaving ? workingState : initialState;
	};
	
	if($scope.$parent.$parent.selectedObjectiveType && $scope.$parent.$parent.selectedObjectiveType.realizeMyPlanTemplateId){
		contentInit();
		populateDropDown();
	}
	
	function contentInit(){
		$scope.isContentLoading = true;
		
		GApi.executeAuth('lmi2Api', 'realizeMyPlan.getRealizeMyPlan', { 
			realizeMyPlanTemplateId: $scope.$parent.$parent.selectedObjectiveType.realizeMyPlanTemplateId
		}).then(function(resp) {
			
			if(resp && resp.items){
				$scope.objectivesTextList = resp.items;
				for (var i = 0; i <= 2; i++) {
					$scope.itemsSelected[i].titel = $scope.objectivesTextList[i].objectives;
					$scope.itemsSelected[i].bsk_sub_id = $scope.objectivesTextList[i].bsk_sub_id;
				}
				checkObjectiveListSizeFunc();
			}

		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	}

	function populateDropDown(){

		GApi.executeAuth('lmi2Api', 'realizeMyPlan.getIngredientsList').then(function(resp) {

			$scope.isContentLoading = false;
			if(resp && resp.ingredientsList){
				$scope.ingredientResults = [];
				angular.forEach(resp.ingredientsList, function(ingredient) {
					obj = {
						'id': ingredient.id,
						'titel': ingredient.titel,
						'low': ingredient.titel.toLowerCase(),
					};
					$scope.ingredientResults.push(obj);
				});
				$scope.objectivesSelectList = $scope.ingredientResults;
			}

		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});

		// $http.get('http://127.0.0.1:8887/ingredientList.json').then(function(resp) {
		// 	$scope.isContentLoading = false;
		// 	if(resp && resp.data.ingredientsList){
		// 		$scope.ingredientResults = [];
		// 		angular.forEach(resp.data.ingredientsList, function(ingredient) {
		// 			obj = {
		// 				'id': ingredient.id,
		// 				'titel': ingredient.titel,
		// 				'low': ingredient.titel.toLowerCase(),
		// 			};
		// 			$scope.ingredientResults.push(obj);
		// 		});
		// 		$scope.objectivesSelectList = $scope.ingredientResults;
		// 	}
		// }, function (err) {
		// 	NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		// });
	}
	
	$scope.getNumber = function(){
		if($scope.$parent.$parent.selectedObjectiveType && $scope.$parent.$parent.selectedObjectiveType.inputRequired){
			return new Array($scope.$parent.$parent.selectedObjectiveType.inputRequired);
		}
        
    };

	$scope.selectedItemChange = function (item, index){
		if (item !== undefined && item !== null) {
			$scope.itemsSelected[index].titel = item.titel;
			$scope.itemsSelected[index].bsk_sub_id = item.id;
		} else {
			$scope.itemsSelected[index].titel = "";
			$scope.itemsSelected[index].bsk_sub_id = 0;
		}
		checkObjectiveListSizeFunc();
	};

	$scope.querySearch = function (query) {
		var results = query ? $scope.objectivesSelectList.filter(createFilterFor(query)) : $scope.objectivesSelectList;
		return results;
	};

	function createFilterFor(query) {
		var lowerCaseQuery = query.toLowerCase();
		return function filterFn(ingredient) {
			return (ingredient.low.indexOf(lowerCaseQuery) === 0);
		};
	}
    
    $scope.checkObjectiveListSize = function(){
    	checkObjectiveListSizeFunc();
    };
    
    function checkObjectiveListSizeFunc(){
    	$scope.isAllowSave = false;
    	for(var i = 0; i < $scope.objectivesTextList.length; i++){
			if ($scope.$parent.$parent.selectedObjectiveType.objectiveType == 'preferred-ingredients') {
				if (i > 2) {
					if($scope.objectivesTextList[i] && $scope.objectivesTextList[i].objectives.trim() !== ""){
						$scope.isAllowSave = true;
						break;
					}
				} else {
					if ($scope.objectivesTextList[i] && $scope.itemsSelected[i].titel !== "") {
						$scope.isAllowSave = true;
						break;
					}
				}
			} else {
				if($scope.objectivesTextList[i] && $scope.objectivesTextList[i].objectives.trim() !== ""){
					$scope.isAllowSave = true;
					break;
				}
			}
    	}
    }
    
    $scope.saveRealizeMyPlan = function() {
    	$scope.isSaving = true;
    	formatObjectivesTextList();
    	GApi.executeAuth('lmi2Api', 'realizeMyPlan.saveRealizeMyPlan', { 
			realizeMyPlanTemplateJson: JSON.stringify($scope.$parent.$parent.selectedObjectiveType),
			objectivesTextList: $scope.objectivesTextList
		}).then(function(resp) {
			
			$scope.isSaving = false;
			
			$state.go('realizeMyPlan.home');	

		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
    };

	function formatObjectivesTextList(){
		for(var i=0; i < $scope.objectivesTextList.length; i++){
			if ($scope.$parent.$parent.selectedObjectiveType.objectiveType == 'preferred-ingredients') {
				if(i > 2) {
					if($scope.objectivesTextList[i].objectives === null || $scope.objectivesTextList[i].objectives === undefined) {
						$scope.objectivesTextList[i].objectives = "";
						$scope.objectivesTextList[i].bsk_sub_id = 0;
					} else {
						$scope.objectivesTextList[i].bsk_sub_id = 0;
					}
				}
				else {
					if ($scope.objectivesTextList[i].objectives !== null) {
						if ($scope.itemsSelected[i].titel !== undefined) {
							$scope.objectivesTextList[i].objectives = $scope.itemsSelected[i].titel;
							$scope.objectivesTextList[i].bsk_sub_id = $scope.itemsSelected[i].bsk_sub_id;
						}
					} else {
						$scope.objectivesTextList[i].objectives = "";
						$scope.objectivesTextList[i].bsk_sub_id = 0;
					}
				}
			} else {
				if($scope.objectivesTextList[i].objectives === null || $scope.objectivesTextList[i].objectives === undefined) {
					$scope.objectivesTextList[i].objectives = "";
					$scope.objectivesTextList[i].bsk_sub_id = 0;
				} else {
					$scope.objectivesTextList[i].bsk_sub_id = 0;
				}
			}
		}
	}
    
}]);




var app = angular.module('app');

app.controller('RealizeMyPlanBaseController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	$scope.selectedObjectiveType = null;
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	$state.go('realizeMyPlan.home');
	
}]);

app.controller('RealizeMyPlanIndexController', ["$rootScope", "$scope", "$state", "$q", "$modal", "GApi", "NotificationsService", function($rootScope, $scope, $state, $q, $modal, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
	$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	$scope.spocCareUserAccess = $rootScope.spocCareUser ? $rootScope.spocCareUser : false;
	
	$scope.isIndexLoading = true;
	$scope.isBskLoading = true;
	$scope.objectiveTypeList = [];
	
	init();
	
	function init(){
		$scope.isIndexLoading = true;
		
		GApi.executeAuth('lmi2Api', 'realizeMyPlan.listObjectiveType', { 

		}).then(function(resp) {
			$scope.objectiveTypeList = resp.items;
			$scope.isIndexLoading = false;
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			$state.go('home');
		});			
	}
	
	$scope.editObjectTypes = function(item){
		$scope.$parent.$parent.selectedObjectiveType = item;
		GApi.executeAuth('lmi2Api', 'realizeMyPlan.startRealizeMyPlan', {}).then(function(resp) {
			if (item.objectiveType === 'preferred-ingredients') {
				$state.go('realizeMyPlan.preferred-ingredients');
			}
			if (item.objectiveType === 'active-participation') {
				$state.go('realizeMyPlan.active-participation');
			}
			if (item.objectiveType === 'formal-learnings') {
				$state.go('realizeMyPlan.formal-learnings');
			}
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});
	};

	var competencesTableList = null;
	var competencesMapTableList = null;
	$scope.competencesResultList = [];
	GApi.executeAuth('lmi2Api', 'realizeMyPlan.getCompetencesValue').then(function(resp) {
		if (resp) {
			if (resp && resp.result) {
				competencesTableList = resp.result.competencesTableList;
				competencesMapTableList = resp.result.competencesMapTableList;
			}
			if (competencesTableList && competencesTableList.length > 0) {
				angular.forEach(competencesTableList, function (competencesTable) {
					angular.forEach(competencesTable.competencesValueModalList, function (competencesValueModal) {
						$scope.competencesResultList.push(competencesValueModal);
					});
				});
			}
			if (competencesMapTableList && competencesMapTableList.length > 0) {
				angular.forEach(competencesMapTableList, function (competencesTable) {
					angular.forEach(competencesTable.competencesValueModalList, function (competencesValueModal) {
						angular.forEach($scope.competencesResultList, function(competenceResult) {
                            if(competenceResult.rating == "1"){
                                competenceResult.rating = "Beginner";
                            } else if(competenceResult.rating == "2"){
                                competenceResult.rating = "Advanced Beginner";
                            } else if(competenceResult.rating == "3"){
                                competenceResult.rating = "Competent";
                            } else if(competenceResult.rating == "4"){
                                competenceResult.rating = "Proficient";
                            } else if(competenceResult.rating == "5"){
                                competenceResult.rating = "Expert";
                            }
							if (competenceResult.competenceId == competencesValueModal.competenceId && competenceResult.competenceName == competencesValueModal.competenceName) {
								competenceResult.asIsDefinition = competencesValueModal.definition;
                                //competenceResult.asIsRating = competencesValueModal.rating;
                                if(competencesValueModal.rating == "1"){
                                    competenceResult.asIsRating = "Beginner";
                                } else if(competencesValueModal.rating == "2"){
                                    competenceResult.asIsRating = "Advanced Beginner";
                                } else if(competencesValueModal.rating == "3"){
                                    competenceResult.asIsRating = "Competent";
                                } else if(competencesValueModal.rating == "4"){
                                    competenceResult.asIsRating = "Proficient";
                                } else if(competencesValueModal.rating == "5"){
                                    competenceResult.asIsRating = "Expert";
                                }
							}
						});
					});
				});
			}
			angular.forEach($scope.competencesResultList, function (competenceResult) {
				if (competenceResult.asIsRating !== undefined || competenceResult.asIsRating !== null) {
					switch (competenceResult.asIsRating){
						case "1":
							competenceResult.asIsRating = "Beginner";
							break;
						case "2":
							competenceResult.asIsRating = "Advanced Beginner";
							break;
						case "3":
							competenceResult.asIsRating = "Competent";
							break;
						case "4":
							competenceResult.asIsRating = "Proficient";
							break;
						case "5":
							competenceResult.asIsRating = "Expert";
							break;
					}
				}
				if (competenceResult.rating !== undefined || competenceResult.rating !== null) {
					switch (competenceResult.rating){
						case "1":
							competenceResult.rating = "Beginner";
							break;
						case "2":
							competenceResult.rating = "Advanced Beginner";
							break;
						case "3":
							competenceResult.rating = "Competent";
							break;
						case "4":
							competenceResult.rating = "Proficient";
							break;
						case "5":
							competenceResult.rating = "Expert";
							break;
					}
				}
			});
			console.log($scope.competencesResultList);
		}
		$scope.isBskLoading = false;
	}, function (err) {
		NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		$scope.isBskLoading = false;
	});	
	
	/*--- Begin: I'm done for now ---*/
	$scope.doneRealizeMyPlan = function () {
		GApi.executeAuth('lmi2Api', 'realizeMyPlan.doneRealizeMyPlan', { 
		}).then(function(resp) {

			if(resp.items[0] === "at_least_one"){
				$scope.dialogTitle=$scope.translations.core.warning;
				$scope.dialogMsg=$scope.translations['realize-my-plan']['msg-not-allow-done'];
				$('#CannotDoneRealizeMyPlanDialog').modal();
			}
			else{
	
				item=resp.items[0];
				var allItems = {items: item, translations: $scope.translations};
				modalInstance = $modal.open({
					  templateUrl: 'doneRealizeMyPlanDialogModal',
					  controller: 'RealizeMyPlanPopupController',
					  backdrop: true,
					  resolve: {
						  allItems: function () {
					      return allItems;
					    }
					  }		      
				});
				modalInstance.result.then(function (item) {
					$state.go('home');
			      }, function (err) {
			    });
			}
			
		}, function (err) {
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			$state.go('home');
		});			
	};
	/*--- End: I'm done for now ---*/
	
}]);

app.controller('RealizeMyPlanPopupController', ["$rootScope", "$scope", "$modalInstance", "allItems", function($rootScope, $scope, $modalInstance, allItems) {
 
		$scope.items = allItems.items;
    	$scope.translations = allItems.translations;
     	
		if($scope.items === "done"){
			$scope.dialogTitle=$scope.translations.core.notice;
			$scope.dialogMsg=$scope.translations['realize-my-plan']['msg-done'];
			
		}
		else if($scope.items === "continue_later"){
			$scope.dialogTitle=$scope.translations.core.notice;
			$scope.dialogMsg=$scope.translations['realize-my-plan']['msg-continue-later'];
		}
    		
     	$scope.closeAndDoSomething = function () {
     		$modalInstance.close($scope.items);
     	};	
     	$scope.closeModalDialog = function () {
     		$modalInstance.dismiss('cancel');
     	};

}]);

var app = angular.module('app');

app.controller('ReportCoachingAlignmentController', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	$scope.selectedEmployees = [];
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.columns = [];
	$scope.selectedEmployee = null;
	$scope.showPersonByPersonView = false;
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};
	
	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};
	
	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function (isPersonView) {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.columns = [];
		$scope.showPersonByPersonView = false;
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) { 
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			showTotals: $scope.showTotals
		};
				
		GApi.executeAuth('lmi2Api', 'reports.getCoachingAlignmentData', params).then(function(resp) {
			$scope.isGeneratingReport = false;
			
			$scope.reportDataUsers = resp.users;
			$scope.reportDataCoachingInitiated = resp.initiatedCoachesInvitation || [];
			$scope.reportDataCoachingCompleted = resp.completedCoachesInvitation || [];
			$scope.reportDataAligningInitiated = resp.initiatedUnitLeaderInvitation || [];
			$scope.reportDataAligningCompleted = resp.completedUnitLeaderInvitation || [];
			
			if ($scope.groupBy === 'country') {
				$scope.columns = _.filter($scope.countries, function (item) { return item.checked; });
			} else if ($scope.groupBy === 'department') {
				$scope.columns = _.map(_.filter($scope.departments, function (item) { 
					return item.checked; 
				}), function (item) {
					return {
						id: item.name,
						name: item.name
					};
				});
			} else if ($scope.groupBy === 'individual') {
				$scope.columns = _.clone($scope.selectedEmployees);
			}
			
			if ($scope.showTotals) {
				$scope.columns.push({
					id: 'report-coaching-alignment-total',
					name: $scope.getTranslation('report-process-progress-total'),
					isTotalColumn: true
				});
			}
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.columns = [];
	});
	
	$scope.getCountryOrDepartmentName = function (id) {
		if ($scope.groupBy === 'country') {
			var country = _.find($scope.countries, function (ctry) { return ctry.id === id; });
			
			if (country) return country.name;
			else return null;
		} else if ($scope.groupBy === 'department') {
			return id;
		} else {
			return null;
		}
	};
	
	function filterUsers (column, user) {
		var userIds = [];
		if (!user) { // Not person-by-person view	
			if (column === 'report-coaching-alignment-total') {
				if (!$scope.reportDataUsers) { // grouped by individual
					userIds = _.map(_.filter($scope.columns, function (col) { return !col.isTotalColumn; }), function (col) { return col.id; });
				} else {
					_.each($scope.reportDataUsers, function (users) {
						userIds.push(users);
					});
					
					userIds = _.map(_.flatten(userIds), function (user) { return user.userId; });
				}
			} else {
				if (!$scope.reportDataUsers) { // grouped by individual
					userIds.push(column);					
				} else {
					_.each($scope.reportDataUsers, function (users, key) {
						if (key === column) userIds = users;
					});
					
					userIds = _.map(_.flatten(userIds), function (user) { return user.userId; });
				}
			}
		} else { // Is person-by-person view. Column is id of country or department name
			userIds.push(user.userId);
		}
				
		return userIds;
	}
	
	$scope.getInitiatedCoachingValue = function (column, user) {		
		var userIds = filterUsers(column, user);
		var total = _.filter($scope.reportDataCoachingInitiated, function (item) {
			return _.contains(userIds, item.userId);
		}).length;
				
		if (!userIds || userIds.length === 0) return '0/0 (0.00%)';
		else return total + '/' + userIds.length + ' (' + (total / userIds.length * 100).toFixed(2) + '%)';
	};
	
	$scope.getCompletedCoachingValue = function (column, user) {
		var userIds = filterUsers(column, user);
		var total = _.filter($scope.reportDataCoachingCompleted, function (item) {
			return _.contains(userIds, item.userId);
		}).length;
				
		if (!userIds || userIds.length === 0) return '0/0 (0.00%)';
		else return total + '/' + userIds.length + ' (' + (total / userIds.length * 100).toFixed(2) + '%)';
	};
	
	$scope.getInitiatedAlignmentValue = function (column, user) {
		var userIds = filterUsers(column, user);
		var total = _.filter($scope.reportDataAligningInitiated, function (item) {
			return _.contains(userIds, item.userId);
		}).length;
				
		if (!userIds || userIds.length === 0) return '0/0 (0.00%)';
		else return total + '/' + userIds.length + ' (' + (total / userIds.length * 100).toFixed(2) + '%)';
	};
	
	$scope.getCompletedAlignmentValue = function (column, user) {
		var userIds = filterUsers(column, user);
		var total = _.filter($scope.reportDataAligningCompleted, function (item) {
			return _.contains(userIds, item.userId);
		}).length;
				
		if (!userIds || userIds.length === 0) return '0/0 (0.00%)';
		else return total + '/' + userIds.length + ' (' + (total / userIds.length * 100).toFixed(2) + '%)';
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
		
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getReportOptions', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
}]);
var app = angular.module('app');

app.controller('CompetenceDestReport1Controller', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.isGeneratingGoogleSheet = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	$scope.selectedEmployees = [];
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.searchCriteria = null;
	$scope.searchDestinationCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};

	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};

	$scope.selectedDestionationItemChange = function (destination) {
		if (destination) $scope.selectedDestinations.push(destination);		
		$scope.searchDestinationCriteria = null;
		$scope.searchDestination = null;
	};

	$scope.suggestDestination = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.destinations, function (destination) {
			var exists = _.find($scope.selectedDestinations, function (item) { return destination.id === item.id; });
			return !exists && destination.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeDestination = function (destination) {
		$scope.selectedDestinations = _.filter($scope.selectedDestinations, function (item) {
			return item.id !== destination.id;
		});
	};
	
	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.reportGroupBy = _.clone($scope.groupBy);
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				if($scope.groupBy === 'all') return true; // for all users, select all
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			destinations: _.map($scope.selectedDestinations, function (destination) { 
				return destination.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceDestinationReport1', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp;
				$scope.reportDataUsers = resp.users;
				$scope.reportData = [];

				var users =  {};
				var totalUser = 0;
				_.each($scope.reportDataUsers, function (value, key) {
					_.each(value, function (item) {
						users[item.userId] = item;
						totalUser++;
					});
				});
				
				angular.forEach($scope.reportDataSet.destinationList, function (destination) {
					var selectedDestinationsFilter =  _.map($scope.selectedDestinations, function (destination) { 
						return destination.id; 
					});
					if(($scope.selectedDestinations.length === 0 ||
						selectedDestinationsFilter.indexOf(destination.destinationTemplateId) !== -1 )){
						destination.isChecked = false;
						destination.isVisible = true;
						destination.isExpandable = true;
						var tempUserArray = [];
						for(var indx in $scope.reportDataSet.results){
							var destinationResult = $scope.reportDataSet.results[indx];
							if(destinationResult[0] === destination.destinationTemplateId.toString() && users[destinationResult[1]]){
								tempUserArray.push({
									name: users[destinationResult[1]].fullName,
									department: users[destinationResult[1]].department,
									isUser: true,
									isExpandable:false,
									parentKey: destinationResult[0]
								});
							}		
						}
						destination.total = tempUserArray.length + " / "+ totalUser;
						$scope.reportData.push(destination);
						if(tempUserArray.length > 0){
							tempUserArray.sort(function (a, b) {
  								var nameA = a.name.toUpperCase(); // ignore upper and lowercase
								var nameB = b.name.toUpperCase(); // ignore upper and lowercase
								if (nameA < nameB)	return -1;
								if (nameA > nameB)  return 1;
								// names must be equal
								return 0;
							});
							$scope.reportData = $scope.reportData.concat(tempUserArray);
						}
					}
				});	
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	
	$scope.toggleStepsVisibility = function (row, $event) {
		if (row.isUser) return;
		
		var table = $($event.target).parents('table:first');
		if (row.checked) table.find('tr[data-parent-key=' + row.destinationTemplateId + ']').addClass('ng-hide');
		else table.find('tr[data-parent-key=' + row.destinationTemplateId + ']').removeClass('ng-hide');
		
		row.checked = !row.checked;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} 
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceReportOptionsForUser', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}

				$scope.destinations = [];
				$scope.selectedDestinations = [];
				if (resp.destinations) {
					_.each(resp.destinations, function (value, key) {
							$scope.destinations.push({
								id: value.destinationTemplateId,
								name: $scope.translations['my-destination'][value.selection]
							});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
	$scope.exportDrive = function(){
		$scope.isGeneratingGoogleSheet = true;
		var table = document.getElementById("tableReport");
		var fileName = $scope.getTranslation('report-competence-dest1');
		var csvString = '';
		for(var i=0; i<table.rows.length;i++){
			var rowData = table.rows[i].cells;
			for(var j=0; j<rowData.length;j++){
				csvString = csvString + $.trim(rowData[j].innerText) + "\t";
			}
			csvString = csvString.substring(0,csvString.length - 1);
			csvString = csvString + "\n";
	    }
	 	csvString = csvString.substring(0, csvString.length - 1);
	 	GApi.generateReportDrive(fileName, csvString).then(function(resp) {
			if (resp) {
				window.open(resp.alternateLink,"driveReportWindow");
				$scope.isGeneratingGoogleSheet = false;
			}		
		}, function (err) {
			$scope.isGeneratingGoogleSheet = false;
		});	
	 	
	 	
	};		
}]);
var app = angular.module('app');

app.controller('CompetenceDestReport2Controller', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.isGeneratingGoogleSheet = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	$scope.selectedEmployees = [];
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};

	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};

	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.reportGroupBy = _.clone($scope.groupBy);
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceDestinationReport1', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp;
				$scope.reportDataUsers = resp.users;
				$scope.reportData = [];
				var destinationMaps = {};
				var destinationUserMaps = {};
				
				angular.forEach($scope.reportDataSet.destinationList, function (destination) {
					destinationMaps[destination.destinationTemplateId] = destination.selection;
				});
				for(var indx in $scope.reportDataSet.results){
					var destinationResult = $scope.reportDataSet.results[indx];
					destinationUserMaps[destinationResult[1]] = destinationResult[0];
				}

				_.each($scope.reportDataUsers, function (value, key) {
					_.each(value, function (item) {
						if(destinationUserMaps[item.userId] && destinationMaps[destinationUserMaps[item.userId]]){
							$scope.reportData.push({
								name: item.fullName,
								selection: destinationMaps[destinationUserMaps[item.userId]], 
								unit: item.department
							});
						}
					});
				});
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	
	$scope.toggleStepsVisibility = function (row, $event) {
		if (row.isUser) return;
		
		var table = $($event.target).parents('table:first');
		if (row.checked) table.find('tr[data-parent-key=' + row.destinationTemplateId + ']').addClass('ng-hide');
		else table.find('tr[data-parent-key=' + row.destinationTemplateId + ']').removeClass('ng-hide');
		
		row.checked = !row.checked;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceReportOptionsForUser', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}

				$scope.destinations = [];
				$scope.selectedDestinations = [];
				if (resp.destinations) {
					_.each(resp.destinations, function (value, key) {
						if(value.parentDestinationTemplateId)
							$scope.destinations.push({
								id: value.destinationTemplateId,
								name: $scope.translations['my-destination'][value.selection]
							});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
	$scope.exportDrive = function(){
		$scope.isGeneratingGoogleSheet = true;
		var table = document.getElementById("tableReport");
		var fileName = $scope.getTranslation('report-competence-dest2');
		var csvString = '';
		for(var i=0; i<table.rows.length;i++){
			var rowData = table.rows[i].cells;
			for(var j=0; j<rowData.length;j++){
				csvString = csvString + $.trim(rowData[j].innerText) + "\t";
			}
			csvString = csvString.substring(0,csvString.length - 1);
			csvString = csvString + "\n";
	    }
	 	csvString = csvString.substring(0, csvString.length - 1);
	 	GApi.generateReportDrive(fileName, csvString).then(function(resp) {
			if (resp) {
				window.open(resp.alternateLink,"driveReportWindow");
				$scope.isGeneratingGoogleSheet = false;
			}		
		}, function (err) {
			$scope.isGeneratingGoogleSheet = false;
		});	
	 	
	 	
	};		
}]);
var app = angular.module('app');

app.controller('CompetenceDestReport3Controller', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.isGeneratingGoogleSheet = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	
	$scope.selectedEmployees = [];
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	
	$scope.selectedCompetences = [];
	$scope.searchCompetenceCriteria = null;
	$scope.suggestedCompetences = [];
	$scope.selectedCompetence = null;
	$scope.competences = null;
	
	$scope.selectedRatings = [];
	$scope.searchRatingCriteria = null;
	$scope.suggestedRatings = [];
	$scope.selectedRating = null;
	$scope.ratings = [{id: '5',name:$scope.translations.reports["report-competence-rating-na"]},
	               	               {id: '1',name: '1'}, 
	            	               {id: '2',name: '2'},
	            	               {id: '3',name:'3'},
	            	               {id: '4',name: '4'}];
	
	$scope.namesUnits = []; //keep track of added org unit;
	
	
	$scope.selectedRatingItemChange = function (rating) {
		if (rating) $scope.selectedRatings.push(rating);		
		$scope.searchRatingCriteria = null;
		$scope.selectedRating = null;
	};

	$scope.suggestRating = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.ratings, function (rating) {
			var exists = _.find($scope.selectedEmployees, function (item) { return rating.id === item.id; });
			return !exists && rating.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeRating = function (rating) {
		$scope.selectedRatings = _.filter($scope.selectedRatings, function (item) {
			return item.id !== rating.id;
		});
	};
	
	
	$scope.selectedCompetenceItemChange = function (competence) {
		if (competence) $scope.selectedCompetences.push(competence);		
		$scope.searchCompetenceCriteria = null;
		$scope.selectedCompetence = null;
	};

	$scope.suggestCompetence = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.competences, function (competence) {
			var exists = _.find($scope.selectedCompetences, function (item) { return competence.id === item.id; });
			return !exists && competence.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeCompetence = function (competence) {
		$scope.selectedCompetences = _.filter($scope.selectedCompetences, function (item) {
			return item.id !== competence.id;
		});
	};
	
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};

	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};

	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.reportGroupBy = _.clone($scope.groupBy);
		$scope.namesUnits = [];
		var mode = $scope.groupBy;

		if($scope.groupBy === 'all' && $scope.countries.length === 0)
			mode = 'competenceLevel';
		
		var params = {
			mode: mode,
			countries: _.map(_.filter($scope.countries, function (country) {
				if(mode === 'all') return true; // for all users, select all
				return mode === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				if(mode === 'competenceLevel') return true; // Competence & Level, select all
				return mode === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return mode === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			competences: _.map($scope.selectedCompetences, function (competence) { 
				return competence.id; 
			}),
			ratings: _.map($scope.selectedRatings, function (rating) { 
				return rating.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceDestinationReport3', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp;
				$scope.reportDataUsers = resp.users;
				$scope.reportData = [];
                var users = {};
                var naText = $scope.translations.reports["report-competence-rating-na"];
                var calc = 0;
                _.each($scope.reportDataUsers, function(value, key) {
                    _.each(value, function(item) {
                        users[item.userId] = item;
                        calc = calc+1;
                    });
                });
                _.each($scope.reportDataSet.results, function (item) {
                	var userId = item[0];
					var compId = item[1];
					var rating = item[2];
					var isVisible = false;
					
					if($scope.namesUnits.indexOf(userId) === -1){
						isVisible = true;
						$scope.namesUnits.push(userId);
					}
						
					
					var fullName = "";
					var department = "";
					
					if(users[userId]){
						fullName = users[userId].fullName;
						department = users[userId].department;
						
						$scope.reportData.push({
							userId:userId,
							competenceId:compId,
							name: fullName,
							unit: department,
							competence: $scope.competencesMap[compId],
							isVisible:isVisible,
							rating: rating === 5 ? naText : rating
						});						
					}else{

						
					}
					

                });
                
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'individual') {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceReport3OptionsForUser', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}
				$scope.competences = [];
				$scope.selectedCompetences = [];
				$scope.competencesMap = {};
				if (resp.competences) {
					_.each(resp.competences, function (competence) {
						var text = $scope.translations["my-competences"][competence.competenceName];
						$scope.competencesMap[competence.competenceSeq] = text;
						$scope.competences.push({
							id: competence.competenceSeq,
							name: text
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
	$scope.exportDrive = function(){
		$scope.isGeneratingGoogleSheet = true;
		var table = document.getElementById("tableReport");
		var fileName = $scope.getTranslation('report-competence-dest3');
		var csvString = '';
		for(var i=0; i<table.rows.length;i++){
			var rowData = table.rows[i].cells;
			for(var j=0; j<rowData.length;j++){
				csvString = csvString + $.trim(rowData[j].innerText) + "\t";
			}
			csvString = csvString.substring(0,csvString.length - 1);
			csvString = csvString + "\n";
	    }
	 	csvString = csvString.substring(0, csvString.length - 1);
	 	GApi.generateReportDrive(fileName, csvString).then(function(resp) {
			if (resp) {
				window.open(resp.alternateLink,"driveReportWindow");
				$scope.isGeneratingGoogleSheet = false;
			}		
		}, function (err) {
			$scope.isGeneratingGoogleSheet = false;
		});	
	 	
	 	
	};		
}]);
var app = angular.module('app');

app.controller('CompetenceDestReport4Controller', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.isGeneratingGoogleSheet = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	
	$scope.selectedEmployees = [];
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	
	$scope.namesUnits = []; //keep track of added org unit;
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};

	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};

	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.reportGroupBy = _.clone($scope.groupBy);
		$scope.namesUnits = [];
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				if($scope.groupBy === 'all') return true; // for all users, select all
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceDestinationReport4', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp;
				$scope.reportDataUsers = resp.users;
				$scope.reportData = [];
                var users = {};
                var naText = $scope.translations.reports["report-competence-rating-na"];
                
                _.each($scope.reportDataUsers, function(value, key) {
                    _.each(value, function(item) {
                        users[item.userId] = item;
                    });
                });
                _.each($scope.reportDataSet.results, function (item) {
                	var userId = item[0];
					var compId = item[1];
					var rating1 = String(item[2] ? item[2] : "");
					var rating2 = String(item[3] ? item[3] : "");
					var isVisible = false;
					
					if($scope.namesUnits.indexOf(userId) === -1){
						isVisible = true;
						$scope.namesUnits.push(userId);
					}
						
					$scope.reportData.push({
						userId:userId,
						competenceId:compId,
						name: users[userId].fullName,
						unit: users[userId].department,
						competence: $scope.competencesMap[compId],
						isVisible:isVisible,
						rating1: rating1 === "5" ? naText : rating1,
					    rating2: rating2 === "5" ? naText : rating2
					});
                });
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'individual') {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getCompetenceReport3OptionsForUser', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}
				$scope.competences = [];
				$scope.selectedCompetences = [];
				$scope.competencesMap = {};
				if (resp.competences) {
					_.each(resp.competences, function (competence) {
						var text = $scope.translations["my-competences"][competence.competenceName];
						$scope.competencesMap[competence.competenceSeq] = text;
						$scope.competences.push({
							id: competence.competenceSeq,
							name: text
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
	$scope.exportDrive = function(){
		$scope.isGeneratingGoogleSheet = true;
		var table = document.getElementById("tableReport");
		var fileName = $scope.getTranslation('report-competence-dest4');
		var csvString = '';
		for(var i=0; i<table.rows.length;i++){
			var rowData = table.rows[i].cells;
			for(var j=0; j<rowData.length;j++){
				csvString = csvString + $.trim(rowData[j].innerText) + "\t";
			}
			csvString = csvString.substring(0,csvString.length - 1);
			csvString = csvString + "\n";
	    }
	 	csvString = csvString.substring(0, csvString.length - 1);
	 	GApi.generateReportDrive(fileName, csvString).then(function(resp) {
			if (resp) {
				window.open(resp.alternateLink,"driveReportWindow");
				$scope.isGeneratingGoogleSheet = false;
			}		
		}, function (err) {
			$scope.isGeneratingGoogleSheet = false;
		});	
	 	
	 	
	};		
}]);
/**
 * 
 */
var app = angular.module('app');

app.controller('ReportDestinationsStatsController', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.chartObject = {
		type: 'ColumnChart',
		data: {
			cols: [
			       {id: "t", label: "Destinations", type: 'string'},
			       {id: "s", label: "Count", type: 'number'}
			       ],
			rows: null
		},
		options: {
			bars: 'vertical',
			backgroundColor: 'transparent',
			colors: ['#e60004'],
			legend: { position: 'none' },
			chartArea: {
				height: '50%',
				top: 20
			},
			hAxis: {
				showTextEvery: 1,
				slantedText: true,
				slantedTextAngle: 80
			},
			vAxis: { 
				showTextEvery: 1
			}
		}
	};
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
		
	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		
		var params = {
				mode: $scope.groupBy,
				countries: _.map(_.filter($scope.countries, function (country) {
					return $scope.groupBy === 'country' && country.checked;
				}), function (country) { 
					return country.id; 
				}),
				departments: _.map(_.filter($scope.departments, function (department) {
					return $scope.groupBy === 'department' && department.checked;
				}), function (department) { 
					return department.name;
				})
			};
					
			GApi.executeAuth('lmi2Api', 'reports.getDestinationsStatsReportData', params).then(function(resp) {
				if (resp.results) {
					var rows = [];
					angular.forEach(resp.results, function (item) {
						rows.push({
							c: [
							    { v: $scope.translations['my-destination'][item.name] },
							    { v: item.count }
							    ]
						});
					});
					
					$scope.chartObject.data.rows = rows;					
				}
				
				$scope.isGeneratingReport = false;
			}, function (err) {
				$scope.isGeneratingReport = false;
				NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			});	
	};
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getReportOptions', {
			includeEmployees: false
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;		
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
		
}]);
var app = angular.module('app');

app.controller('ReportsController', ["$rootScope", "$scope", "$state", "RolesManager", function ($rootScope, $scope, $state, RolesManager) {
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
			
	$scope.tabs = _.filter($state.get(), function (state) {
		return (state.name && 
				state.name.match(/^report./) && 
				state.title &&
				state.requiredRoles && 
				RolesManager.hasRoles($rootScope.user, state.requiredRoles));
	});
		
	$scope.isSelected = function (stateName) {
		return (stateName && $state.includes(stateName)) ? 'selected' : '';
	};
	
	$scope.getRouteName = function (redirectTo, stateName) {
		return redirectTo || stateName;
	};
	
}]);
var app = angular.module('app');

app.controller('ReportProcessProgressController', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	$scope.selectedEmployees = [];
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	$scope.columns = [];
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};
	
	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};
	
	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.columns = [];
		$scope.reportGroupBy = _.clone($scope.groupBy);
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getProcessProgressReportData', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp.data;
				$scope.reportDataUsers = resp.users;
				$scope.reportDataProcesses = [];
				
				angular.forEach(resp.processes, function (process) {
					process.isProcess = true;
					process.isChecked = false;
					process.isVisible = true;
					$scope.reportDataProcesses.push(process);
					
					angular.forEach(process.steps, function (step) {
						step.isStep = true;
						step.parentKey = process.nameKey;
						step.isVisible = false;
						$scope.reportDataProcesses.push(step);
					});					
				});
				
				if ($scope.groupBy === 'country') {
					$scope.columns = _.filter($scope.countries, function (item) { return item.checked; });
				} else if ($scope.groupBy === 'department') {
					$scope.columns = _.map(_.filter($scope.departments, function (item) { 
						return item.checked; 
					}), function (item) {
						return {
							id: item.name,
							name: item.name
						};
					});
				} else if ($scope.groupBy === 'individual') {
					$scope.columns = _.clone($scope.selectedEmployees);
				}
				
				if ($scope.showTotals) {
					$scope.columns.push({
						id: 'report-coaching-alignment-total',
						name: $scope.getTranslation('report-process-progress-total'),
						isTotalColumn: true
					});
				}
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	$scope.getCountryOrDepartmentName = function (id) {
		if ($scope.reportGroupBy === 'country') {
			var country = _.find($scope.countries, function (ctry) { return ctry.id === id; });
			
			if (country) return country.name;
			else return null;
		} else if ($scope.reportGroupBy === 'department') {
			return id;
		} else {
			return null;
		}
	};
	
	$scope.getProgressByPerson = function (key, userId) {
		var record = _.find($scope.reportDataSet, function (item) {
			return item.nameKey === key && item.userId === userId;
		});
		
		if (record) return '1/1 (100.00%)';
		else return '0/1 (0.00%)';
	};
	
	$scope.getProgress = function (key, column) {
		var userIds = [];
		if ($scope.reportGroupBy === 'country' || $scope.groupBy === 'department') {
			if (column.isTotalColumn) {
				_.each($scope.reportDataUsers, function (value, key) {
					_.each(value, function (item) {
						userIds.push(item.userId);
					});
				});
			} else {
				userIds = _.map($scope.reportDataUsers[column.id], function (item) {
					return item.userId;
				});
			}
		} else if ($scope.reportGroupBy === 'individual') {
			if (column.isTotalColumn) {
				userIds = _.map(_.filter($scope.columns, function (item) {
					return !item.isTotalColumn;
				}), function (item) {
					return item.id;
				});
			} else {
				userIds.push(column.id);
			}
		}
		
		if (userIds.length === 0) {
			return '0/0 (0.00%)';
		} else {
			var progress = _.filter($scope.reportDataSet, function (item) {
				return item.nameKey === key && _.contains(userIds, item.userId);
			});
			
			return progress.length + '/' + userIds.length + ' (' + ((progress.length / userIds.length) * 100).toFixed(2) + '%)';
		}
	};
	
	$scope.toggleStepsVisibility = function (row, $event) {
		if (row.isStep) return;
		
		var table = $($event.target).parents('table:first');
		if (row.checked) table.find('tr[data-parent-key=' + row.nameKey + ']').addClass('ng-hide');
		else table.find('tr[data-parent-key=' + row.nameKey + ']').removeClass('ng-hide');
		
		row.checked = !row.checked;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.columns = [];
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getReportOptions', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
}]);
var app = angular.module('app');

app.controller('ReportProcessProgress1Controller', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.isGeneratingGoogleSheet = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	$scope.selectedEmployees = [];
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};
	
	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};
	
	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.reportGroupBy = _.clone($scope.groupBy);
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getProcessProgressReportData2', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp.data;
				$scope.reportDataUsers = resp.users;
				$scope.reportDataProcesses = [];
				var users =  [];
				var userDataMap = [];
				angular.forEach(resp.data, function (data) {
					userDataMap.push(data.nameKey+data.userId);
				});
				_.each($scope.reportDataUsers, function (value, key) {
					_.each(value, function (item) {
						users.push(item);
					});
				});
				$scope.totalUser = users.length;
				angular.forEach(resp.processes, function (process) {
					process.isProcess = true;
					process.isChecked = false;
					process.isVisible = true;
					$scope.reportDataProcesses.push(process);
					process.isExpandable = true;
					process.userCompleted = 0;
					var userCompletedMap = {};
					angular.forEach(process.steps, function (step) { 
						step.isStep = true;
						step.isExpandable = true;
						step.parentKey = process.nameKey;
						step.isVisible = false;
						step.userCompleted = 0;
						$scope.reportDataProcesses.push(step);
						angular.forEach(users, function (user) {
							if(userDataMap.indexOf(step.nameKey+user.userId) !== -1){
								var userObj = {};
								step.userCompleted++;
								userObj.fullName = user.fullName;
								userObj.isUser = true;
								userObj.isExpandable = false;
								userObj.rootKey = process.nameKey;
								userObj.stepKey = step.nameKey;
								userObj.isChecked = false;
								userObj.isVisible = false;
								$scope.reportDataProcesses.push(userObj);
								userCompletedMap[user.userId] = userCompletedMap[user.userId] ? userCompletedMap[user.userId]+1 : 1;
								if(userCompletedMap[user.userId] === process.steps.length){
									process.userCompleted++;
								}
							}
						});
						step.total = step.userCompleted+"/"+$scope.totalUser + " ("+
						Math.round(step.userCompleted/$scope.totalUser*100)+"%)";
					});	
					process.total = process.userCompleted+"/"+$scope.totalUser + " ("+
					Math.round(process.userCompleted/$scope.totalUser*100)+"%)";
				});	
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	
	$scope.toggleStepsVisibility = function (row, $event) {
		if (row.isUser) return;
		
		var rows = null;
		var table = $($event.target).parents('table:first');
		if (row.isStep){
			rows = table.find('tr[data-step-key=' + row.nameKey + ']');
			//close by parent, reset it back to false; //sub row is visible
			if(row.checked && rows[0] && !$(rows[0]).is(":visible")){
				row.checked = false;
			}
		}else{
			rows = table.find('tr[data-parent-key=' + row.nameKey + ']');
			if(row.checked){
				rows = rows.add(table.find('tr[data-root-key=' + row.nameKey + ']:visible'));
			}
		}
			
		if (row.checked) rows.addClass('ng-hide');
		else rows.removeClass('ng-hide');
		
		row.checked = !row.checked;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getReportOptions', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	$scope.exportDrive = function(){
		$scope.isGeneratingGoogleSheet = true;
		var table = document.getElementById("tableReport");
		var fileName = $scope.getTranslation('report-process-progress1');
		var csvString = '';
		for(var i=0; i<table.rows.length;i++){
			var rowData = table.rows[i].cells;
			for(var j=0; j<rowData.length;j++){
				csvString = csvString + $.trim(rowData[j].innerText) + "\t";
			}
			csvString = csvString.substring(0,csvString.length - 1);
			csvString = csvString + "\n";
	    }
	 	csvString = csvString.substring(0, csvString.length - 1);
	 	GApi.generateReportDrive(fileName, csvString).then(function(resp) {
			if (resp) {
				window.open(resp.alternateLink,"driveReportWindow");
				$scope.isGeneratingGoogleSheet = false;
			}		
		}, function (err) {
			$scope.isGeneratingGoogleSheet = false;
		});	
	 	
	 	
	};
	
}]);


var app = angular.module('app');

app.controller('ReportProcessProgress2Controller', ["$rootScope", "$scope", "$state", "GApi", "NotificationsService", function ($rootScope, $scope, $state, GApi, NotificationsService) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
		
	$scope.isGettingReportOptions = false;
	$scope.isGeneratingReport = false;	
	$scope.isGeneratingGoogleSheet = false;	
	$scope.showTotals = true;
	$scope.groupBy = 'country';
	$scope.canSelectCountries = null;
	$scope.countries = [];
	$scope.departments = [];
	$scope.employees = [];
	$scope.selectedEmployees = [];
	$scope.allCountriesSelected = false;
	$scope.allDepartmentsSelected = false;
	$scope.searchCriteria = null;
	$scope.suggestedEmployees = [];
	$scope.selectedEmployee = null;
	$scope.showPersonByPersonView = false;
	$scope.reportDataSet = null;
	$scope.reportDataUsers = null;
	$scope.reportDataProcesses = null;
	$scope.columns = [];
	
	$scope.toggleCountrySelection = function (isChecked) {
		_.each($scope.countries, function (country) { country.checked = isChecked; });
		$scope.allCountriesSelected = isChecked;
	};
	
	$scope.selectedItemChange = function (employee) {
		if (employee) $scope.selectedEmployees.push(employee);		
		$scope.searchCriteria = null;
		$scope.selectedEmployee = null;
	};
	
	$scope.suggestEmployee = function (criteria) {
		var regx = new RegExp((criteria || '').toLowerCase());
		
		return _.filter($scope.employees, function (employee) {
			var exists = _.find($scope.selectedEmployees, function (item) { return employee.id === item.id; });
			return !exists && employee.name.toLowerCase().match(regx);
		});
	};
	
	$scope.removeEmployee = function (employee) {
		$scope.selectedEmployees = _.filter($scope.selectedEmployees, function (item) {
			return item.id !== employee.id;
		});
	};
	
	$scope.toggleDepartmentSelection = function (isChecked) {
		_.each($scope.departments, function (department) { department.checked = isChecked; });
		$scope.allDepartmentsSelected = isChecked;
	};
	
	$scope.$watch('countries', function () {
		$scope.allCountriesSelected = _.filter($scope.countries, function (country) {
			return !country.checked;
		}).length === 0;
	}, true);
	
	$scope.$watch('departments', function () {
		$scope.allDepartmentsSelected = _.filter($scope.departments, function (department) {
			return !department.checked;
		}).length === 0;
	}, true);
	
	$scope.generate = function () {
		if (!isValid()) return;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		$scope.isGeneratingReport = true;
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.columns = [];
		$scope.reportGroupBy = _.clone($scope.groupBy);
		
		var params = {
			mode: $scope.groupBy,
			countries: _.map(_.filter($scope.countries, function (country) {
				return $scope.groupBy === 'country' && country.checked;
			}), function (country) { 
				return country.id; 
			}),
			departments: _.map(_.filter($scope.departments, function (department) {
				return $scope.groupBy === 'department' && department.checked;
			}), function (department) {
				return department.name;
			}),
			employees: _.map(_.filter($scope.selectedEmployees, function (employee) {
				return $scope.groupBy === 'individual';
			}), function (employee) { 
				return employee.id; 
			}),
			showTotals: $scope.showTotals
		};
						
		GApi.executeAuth('lmi2Api', 'reports.getProcessProgressReportData2', params).then(function(resp) {
			if (resp) {
				$scope.reportDataSet = resp.data;
				$scope.reportDataUsers = resp.users;
				$scope.reportDataProcesses = [];
				$scope.reportDataUsersArray = [];
				$scope.reportDataUsersIndividualArray = [];
				
				for (var groupKey in $scope.reportDataUsers) {
					$scope.reportDataUsersArray = $scope.reportDataUsersArray.concat($scope.reportDataUsers[groupKey]);
				}
				
//				angular.forEach($scope.reportDataUsers, function (obj) {				
//					// $scope.reportDataUsersArray.push(obj);
//				});					
								
				angular.forEach(resp.processes, function (process) {
					process.isProcess = true;
					process.isChecked = false;
					process.isVisible = true;
					$scope.reportDataProcesses.push(process);
					
					angular.forEach(process.steps, function (step) {
						step.isStep = true;
						step.parentKey = process.nameKey;
						step.isVisible = false;
						$scope.reportDataProcesses.push(step);
					});					
				});
				
				if ($scope.groupBy === 'country') {
					$scope.columns = _.filter($scope.countries, function (item) { return item.checked; });
				} else if ($scope.groupBy === 'department') {
					$scope.columns = _.map(_.filter($scope.departments, function (item) { 
						return item.checked; 
					}), function (item) {
						return {
							id: item.name,
							name: item.name
						};
					});
				} else if ($scope.groupBy === 'individual') {
					$scope.columns = _.clone($scope.selectedEmployees);
				}
				
				if ($scope.showTotals) {
					$scope.columns.push({
						id: 'report-coaching-alignment-total',
						name: $scope.getTranslation('report-process-progress-total'),
						isTotalColumn: true
					});
				}
			}
			
			$scope.isGeneratingReport = false;
		}, function (err) {
			$scope.isGeneratingReport = false;
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
		});	
	};
		
	$scope.getTranslation = function (key) {
		if ($scope.translations.reports[key]) return $scope.translations.reports[key];
		else return key;
	};
	
	$scope.getCountryOrDepartmentName = function (id) {
		if ($scope.reportGroupBy === 'country') {
			var country = _.find($scope.countries, function (ctry) { return ctry.id === id; });
			
			if (country) return country.name;
			else return null;
		} else if ($scope.reportGroupBy === 'department') {
			return id;
		} else {
			return null;
		}
	};
	
	$scope.getProgressByPerson = function (key, userId) {
		var record = _.find($scope.reportDataSet, function (item) {
			return item.nameKey === key && item.userId === userId;
		});
		
		if (record) return '1/1 (100.00%)';
		else return '0/1 (0.00%)';
	};
	
	$scope.getProgressCompletedByPerson = function (key, userId) {
		var record = _.find($scope.reportDataSet, function (item) {
			return item.nameKey === key && item.userId === userId;
		});
		
		if (record) return 'X';
		else return '';
	};
	
	$scope.createBorder = function (row) {
		var testing = row;
		
		if((row.isVisible === false && row.sortOrder === 1) || (row.nameKey === "process-3-step-4-name" && row.sortOrder === 4))
			return "createBorderLeft";
		else
			return "";
	};
	
	$scope.getProgress = function (key, column) {
		var userIds = [];
		if ($scope.reportGroupBy === 'country' || $scope.groupBy === 'department') {
			if (column.isTotalColumn) {
				_.each($scope.reportDataUsers, function (value, key) {
					_.each(value, function (item) {
						userIds.push(item.userId);
					});
				});
			} else {
				userIds = _.map($scope.reportDataUsers[column.id], function (item) {
					return item.userId;
				});
			}
		} else if ($scope.reportGroupBy === 'individual') {
			if (column.isTotalColumn) {
				userIds = _.map(_.filter($scope.columns, function (item) {
					return !item.isTotalColumn;
				}), function (item) {
					return item.id;
				});
			} else {
				userIds.push(column.id);
			}
		}
		
		if (userIds.length === 0) {
			return '0/0 (0.00%)';
		} else {
			var progress = _.filter($scope.reportDataSet, function (item) {
				return item.nameKey === key && _.contains(userIds, item.userId);
			});
			
			return progress.length + '/' + userIds.length + ' (' + ((progress.length / userIds.length) * 100).toFixed(2) + '%)';
		}
	};
	
	$scope.toggleStepsVisibility = function (row, $event) {
		if (row.isStep) return;
		
		var table = $($event.target).parents('table:first');
		if (row.checked) table.find('tr[data-parent-key=' + row.nameKey + ']').addClass('ng-hide');
		else table.find('tr[data-parent-key=' + row.nameKey + ']').removeClass('ng-hide');
		
		row.checked = !row.checked;
	};
	
	$scope.$watch('groupBy', function () {
		$scope.showPersonByPersonView = false;
		$scope.reportDataSet = null;
		$scope.reportDataUsers = null;
		$scope.reportDataProcesses = null;
		$scope.columns = [];
	});
	
	function isValid() {
		if ($scope.groupBy === 'country') {
			if (_.filter($scope.countries, function (country) { return country.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-country-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else if ($scope.groupBy === 'department') {
			if (_.filter($scope.departments, function (department) { return department.checked; }).length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-department-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		} else {
			if ($scope.selectedEmployees.length === 0) {
				NotificationsService.show($scope.getTranslation('report-options-employee-required'), NOTIFICATIONS_FADEOUT_TIMEOUT);
				return false;
			}
		}
		
		return true;
	}
	
	function init() {
		
		$scope.isGettingReportOptions = true;
		GApi.executeAuth('lmi2Api', 'reports.getReportOptions', { 
			includeEmployees: true
		}).then(function(resp) {
			if (resp) {
				$scope.canSelectCountries = resp.canSelectCountries || false;
				
				$scope.countries = [];
				$scope.allCountriesSelected = true;
				if (resp.countries) {
					_.each(resp.countries, function (value, key) {
						$scope.countries.push({
							id: key,
							name: value,
							checked: false
						});
					});
				}
								
				$scope.departments = [];
				$scope.allDepartmentsSelected = true;
				if (resp.departments) {
					_.each(resp.departments, function (value) {
						$scope.departments.push({
							name: value,
							checked: false
						});
					});
				}
				
				$scope.employees = [];
				$scope.selectedEmployees = [];
				if (resp.employees) {
					_.each(resp.employees, function (value, key) {
						$scope.employees.push({
							id: key,
							name: value
						});
					});
				}
			}
			
			if ($scope.canSelectCountries) $scope.groupBy = 'country';
			else $scope.groupBy = 'department';
			
			$scope.isGettingReportOptions = false;			
		}, function (err) {
			NotificationsService.show($scope.getTranslation(err.message), NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isGettingReportOptions = false;
		});	
		
	}
	
	init();	
	
	$scope.exportDrive = function(){
		$scope.isGeneratingGoogleSheet = true;
		var table = document.getElementById("tableReport");
		var fileName = $scope.getTranslation('report-process-progress2');
		var csvString = '';
		for(var i=0; i<table.rows.length;i++){
			var rowData = table.rows[i].cells;
			for(var j=0; j<rowData.length;j++){
				if(i==1){
					if($.trim(rowData[j].innerText.toLowerCase()) == "prepare yourself")
						csvString = csvString + "\t\t\t" + $.trim(rowData[j].innerText);
					else if($.trim(rowData[j].innerText.toLowerCase()) == "refine with career coach")
						csvString = csvString + "\t\t\t\t\t\t\t" + $.trim(rowData[j].innerText);
					else if($.trim(rowData[j].innerText.toLowerCase()) == "align with unit leader")
						csvString = csvString + "\t\t\t\t\t" + $.trim(rowData[j].innerText);	
					else if($.trim(rowData[j].innerText.toLowerCase()) == "realize together")
						csvString = csvString + "\t" + $.trim(rowData[j].innerText);						
					else
						csvString = csvString + $.trim(rowData[j].innerText) + "\t";
				}else{
					if(rowData[j].getAttribute("class")){
						if(rowData[j].getAttribute("class").indexOf("ng-hide") == -1)
							csvString = csvString + $.trim(rowData[j].innerText) + "\t";					
					}else{
						csvString = csvString + $.trim(rowData[j].innerText) + "\t";
					}
				}
				
				
			}
			csvString = csvString.substring(0,csvString.length - 1);
			csvString = csvString + "\n";
	    }
//		for(var i=0; i<table.rows.length;i++){
//			var rowData = table.rows[i].cells;
//			for(var j=0; j<rowData.length;j++){
//				if(rowData[j].getAttribute("class")){
//					if(rowData[j].getAttribute("class").indexOf("ng-hide") == -1)
//						csvString = csvString + $.trim(rowData[j].innerText) + "\t";					
//				}else{
//					csvString = csvString + $.trim(rowData[j].innerText) + "\t";
//				}
//			}
//			csvString = csvString.substring(0,csvString.length - 1);
//			csvString = csvString + "\n";
//	    }		
	 	csvString = csvString.substring(0, csvString.length - 1);
	 	GApi.generateReportDrive(fileName, csvString).then(function(resp) {
			if (resp) {
				window.open(resp.alternateLink,"driveReportWindow");
				$scope.isGeneratingGoogleSheet = false;
			}		
		}, function (err) {
			$scope.isGeneratingGoogleSheet = false;
		});	
	 	
	 	
	};	
}]);
/*jshint esnext: true */

var app = angular.module('app');

app.factory('GApi', ['$q', 'GClient', '$state', '$rootScope', 'NotificationsService', function($q, GClient, $state, $rootScope, NotificationsService) {

	var isLogin = false;
	var apisLoad  = [];
	var observerCallbacks = [];

	function registerObserverCallback(api, method, params, auth, deferred) {
		var observerCallback = {};
		observerCallback.api = api;
		observerCallback.apiLoad = false;
		observerCallback.method = method;
		observerCallback.params = params;
		observerCallback.auth = auth;
		observerCallback.deferred = deferred;
		observerCallbacks.push(observerCallback);
	}

	function load(api, version, url) {
		GClient.get(function () {
			gapi.client.load(api, version, function() {
				apisLoad.push(api);
				executeCallbacks(api);
			}, url);
		});
		GClient.getGIS(function () {
			$rootScope.$broadcast('gis:script-loaded');
		});
	}

	function executeCallbacks(api){
		var apiName = api;

		for (var i= 0; i < observerCallbacks.length; i++) {
			var observerCallback = observerCallbacks[i];
			if ((observerCallback.api === apiName || observerCallback.apiLoad) &&
				(observerCallback.auth === false || isLogin === true)) {
				runGapi(observerCallback.api, observerCallback.method, observerCallback.params, observerCallback.deferred);
				if (i > -1) {
					observerCallbacks.splice(i--, 1);
				}
			} else {
				if (observerCallback.api === apiName)
					observerCallbacks[i].apiLoad = true;
			}
		}
	}

	function getAccessTokenLocal(cName){
		response = null;
		cookieName = cName + "=";
		cDecoded = decodeURIComponent(document.cookie);
		cArray = cDecoded.split("; ");
		cArray.forEach(function(value) {
			if(value.indexOf(cookieName) === 0){
				response = value.substring(cookieName.length);
			}
		});
		return response;
	}

	function signInWithGIS(authorizeCallback) {
		console.log("Signing in with GIS");
		tokenClient = google.accounts.oauth2.initTokenClient({
			client_id: '644872794567-l19ht004d5tjnu170l3h0luajufqemc1.apps.googleusercontent.com',
			scope: 'profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file',
			callback: authorizeCallback
		});
		tokenClient.requestAccessToken({prompt: ''});
	}

	function checkToken(deferred2){
		if(gapi.client.getToken() === undefined || gapi.client.getToken() === null){
			access_token = getAccessTokenLocal("access_token");
			if (access_token !== null) {
				gapi.client.setToken({
					"access_token" : access_token
				});
			} else {
				NotificationsService.show("Refreshing User Session, Please wait for a moment", 60000);
				signInWithGIS(function(token) {
					if(token.error){
						deferred2.reject({ status: 401, error: token.error });
						NotificationsService.hide();
					} else {
						gapi.client.setToken(token);
						NotificationsService.show("Successfully Refresh User Session", 5000);
						deferred2.resolve(token);
					}
				});
			}
		} else {
			deferred2.resolve(gapi.client.getToken());
		}
		return deferred2.promise;
	}

	function runGapi(api, method, params, deferred) {
		var pathMethod = method.split('.');
		var deferred2 = $q.defer();
		api = gapi.client[api];
		for(var i= 0; i < pathMethod.length; i++) {
			api = api[pathMethod[i]];
		}

		// Send impersonated user's email address together with all API calls
		var request = api(params);
		if ($rootScope.impersonateUser) {
			var headerObject = findHeaderObject(request, "headers");
			headerObject[0].headers = headerObject[0].headers || {};
			headerObject[0].headers['Accept-Language'] = $rootScope.impersonateUser.email; // TODO: Bad hack pending for answer to https://groups.google.com/forum/#!topic/google-api-javascript-client/00spVN6PSzU
		}
		request.execute(function (response) {
			if (response.error) {
				deferred.reject(response);
			} else {
				deferred.resolve(response);
			}
		});
	}
	
	function findHeaderObject(obj, key) {
		if (_.has(obj, key)) // or just (key in obj)
	        return [obj];
	    // elegant:
	    return _.flatten(_.map(obj, function(v) {
	        return typeof v == "object" ? findHeaderObject(v, key) : [];
	    }), true);

	    // or efficient:
	    /*var res = [];
	    _.forEach(obj, function(v) {
	        if (typeof v == "object" && (v = findHeaderObject(v, key)).length)
	            res.push.apply(res, v);
	    });
	    return res;*/
	}

	function execute(api, method, params, auth) {
		var deferred = $q.defer();
		if (apisLoad.indexOf(api) > -1) {
			runGapi(api, method, params, deferred);
		}
		else
			registerObserverCallback(api, method, params, auth, deferred);
		return deferred.promise; 
	}

	return {

		isLogin : function(value) {
			if(arguments.length === 0)
				return isLogin;
			isLogin = value;
		},

		executeCallbacks : function() {
			executeCallbacks();
		},

		load: function(name, version, url){
			load(name, version, url);
		},

		execute: function (api, method, params) {
			var deferred = $q.defer();

			var promise = null;
			if(arguments.length == 3)              
				promise = execute(api, method, params, false);
			if(arguments.length == 2)
				promise = execute(api, method, null, false);

			if (promise) {
				promise.then(function (result) {
					deferred.resolve(result);
				}).catch(function (err) {
					if (err && err.code && err.code === 401) {
						$state.go('logout');
					} else if (err && err.code && err.code === 403) {
						$state.go('forbidden');
					} else {
						deferred.reject(err);
					}
				});
			}

			return deferred.promise;
		},

		executeAuth: function (api, method, params){
			var deferred = $q.defer();

			var promise = null;
			if(arguments.length == 3)              
				promise = execute(api, method, params, true);
			if(arguments.length == 2)
				promise = execute(api, method, null, true);

			if (promise) {
				promise.then(function (result) {
					deferred.resolve(result);
				}).catch(function (err) {
					if (err && err.code && err.code === 401) {
						$state.go('logout');
					} else if (err && err.code && err.code === 403) {
						$state.go('forbidden');
					} else {
						deferred.reject(err);
					}
				});
			}

			return deferred.promise;
		},
		generateReportDrive: function(fileName, fileData) {
			 var deferred = $q.defer();
			 var promise = null;
			 var boundary = '-------314159265358979323846';
			 var delimiter = "\r\n--" + boundary + "\r\n";
			 var close_delim = "\r\n--" + boundary + "--";
			 var contentType = 'text/csv';
			 var metadata = {
			   'title': fileName,
			   'mimeType': contentType
			 };
			 var base64Data = btoa(fileData);
			 var multipartRequestBody =
			     delimiter +
			     'Content-Type: application/json\r\n\r\n' +
			     JSON.stringify(metadata) +
			     delimiter +
			     'Content-Type: ' + contentType + '\r\n' +
			     'Content-Transfer-Encoding: base64\r\n' +
			     '\r\n' +
			     base64Data +
			     close_delim;

			 var request = gapi.client.request({
			     'path': '/upload/drive/v2/files',
			     'method': 'POST',
			     'params': {'uploadType': 'multipart','convert':true},
			     'headers': {
			       'Content-Type': 'multipart/mixed; boundary="' + boundary + '"'
			     },
			     'body': multipartRequestBody});
			 request.execute(function (response) {
			 	if (response.error) {
			 		deferred.reject(response);
			 	} else {
			 		deferred.resolve(response);
			 	}
			 });
            return deferred.promise;
        }
	};

}]);
var app = angular.module('app');

app.factory('GAuth', ['$rootScope', '$q', '$http', '$state', 'GClient', 'GApi', '$location',
	function($rootScope, $q, $http, $state, GClient, GApi, $location){
		var isLoad = false;
		var isLoadGis = false;
		var isCheckingAuth = false;

		var CLIENT_ID;
		var SCOPES = ['https://www.googleapis.com/auth/userinfo.email','https://www.googleapis.com/auth/drive.file'];
		var authToken = null;
		var tokenClient;

		function load(calback){
			if (isLoad === false) {
				var args = arguments.length;
				GClient.get(function (){
					gapi.client.load('oauth2', 'v2', function() {
						isLoad = true;
						if (args == 1)
							calback();
					});
				});
			} else {
				calback();
			}
		}

		function loadGIS(callback) {
			if (isLoadGis === false) {
				GClient.getGIS(function () {
					isLoadGis = true;
					callback();
				});
			} else {
				callback();
			}
		}

		function signInWithGIS(authorizeCallback) {
			if (!isLoadGis) {
				loadGIS(function () {
					tokenClient = google.accounts.oauth2.initTokenClient({
						// client_id: '644872794567-l19ht004d5tjnu170l3h0luajufqemc1.apps.googleusercontent.com', //tst
						client_id:'1089290613938-4djm0t4uinorptj1ru5icmfbb1ccsh24.apps.googleusercontent.com', //prd
						scope: 'profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file',
						callback: authorizeCallback,
						error_callback: myErrorCallback
					});
					tokenClient.requestAccessToken({prompt: ''});
				});
			} else {
				tokenClient = google.accounts.oauth2.initTokenClient({
					// client_id: '644872794567-l19ht004d5tjnu170l3h0luajufqemc1.apps.googleusercontent.com', //tst
					client_id:'1089290613938-4djm0t4uinorptj1ru5icmfbb1ccsh24.apps.googleusercontent.com', //prd
					scope: 'profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file',
					callback: authorizeCallback,
					error_callback: myErrorCallback
				});
				tokenClient.requestAccessToken({prompt: ''});
			}
		}

		function myErrorCallback(err) {
			if (err.type == 'popup_failed_to_open') {
				console.log("failed to login due to popup failed");
				$rootScope.$broadcast('gis:login-popup-failed');
			}
		}


		function signout() {
			var deferred = $q.defer();
			load(function () {
				removeStoredCookies();
				var token = gapi.auth.getToken();
				if (!token) {
					deferred.resolve();
					return;
				}

				$rootScope.user = null;
				gapi.auth.setToken(null);

				if (!gapi.auth2.getAuthInstance()) {
					gapi.auth.signOut();
					deferred.resolve();
					return;
				} else {
					gapi.auth2.getAuthInstance().disconnect();
					//gapi.auth2.getAuthInstance().signOut();
					deferred.resolve();
				}
			});
			return deferred.promise;
		}

		function removeStoredCookies() {
			document.cookie = 'user_details=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		}

		function getUserv2(responsePayload) {
			var deferred = $q.defer();
			if (!responsePayload.code) {
				GApi.isLogin(true);
				GApi.executeCallbacks();
				$rootScope.user = {};
				$rootScope.user.email = responsePayload.email;
				$rootScope.user.picture = responsePayload.picture;
				$rootScope.user.id = responsePayload.sub;
				$rootScope.user.link = responsePayload.link;
				if (responsePayload.name === undefined) {
					$rootScope.user.name = responsePayload.email;
				} else {
					$rootScope.user.name = responsePayload.name;
				}

				$rootScope.$apply($rootScope.user);

				GApi.executeAuth('lmi2Api', 'auth.getUserRoles', {
					email: null
				}).then(function(resp) {
					if (resp && resp.items && resp.items.length > 0) {
						$rootScope.user.roles = resp.items;
						deferred.resolve();
					} else {
						signout().then(function () {
							deferred.reject({ status: 403 });
						});
					}
				}, function(err) {
					signout().then(function () {
						deferred.reject({ status: 403 });
					});
				});
			} else {
				deferred.reject();
			}
			return deferred.promise;
		}

		function storeAccessToken(authToken){
			document.cookie = "access_token=" + authToken.access_token + "; max-age=" + authToken.expires_in + ";";
		}

		function getAccessToken(cName){
			response = null;
			cookieName = cName + "=";
			cDecoded = decodeURIComponent(document.cookie);
			cArray = cDecoded.split("; ");
			cArray.forEach(function(value) {
				if(value.indexOf(cookieName) === 0){
					response = value.substring(cookieName.length);
				}
			});
			return response;
		}

		function storeUserDetails(userDetails){
			document.cookie = "user_details=" + userDetails + ";";
		}

		function getUserDetails(cName) {
			var result = document.cookie.match(new RegExp(cName + '=([^;]+)'));
			if (result) {
				return JSON.parse(result[1]);
			}
			return null;
		}

		function getUser() {
			var deferred = $q.defer();

			gapi.client.oauth2.userinfo.get().execute(function(resp) {
				if (!resp.code) {
					GApi.isLogin(true);
					GApi.executeCallbacks();
					$rootScope.user = {};
					$rootScope.user.email = resp.email;
					$rootScope.user.picture = resp.picture;
					$rootScope.user.id = resp.id;
					$rootScope.user.link = resp.link;

					if (resp.name === undefined) {
						$rootScope.user.name = resp.email;
					} else {
						$rootScope.user.name = resp.name;
					}

					$rootScope.$apply($rootScope.user);

					GApi.executeAuth('lmi2Api', 'auth.getUserRoles', {
						email: null
					}).then(function(resp) {

						if (resp && resp.items && resp.items.length > 0) {
							$rootScope.user.roles = resp.items;

							deferred.resolve();
						} else {
							signout().then(function () {
								deferred.reject({ status: 403 });
							});
						}
					}, function(err) {
						signout().then(function () {
							deferred.reject({ status: 403 });
						});
					});
				} else {
					deferred.reject();
				}
			});

			return deferred.promise;
		}

		return {

			setClient: function(client) {
				CLIENT_ID = client;
			},

			setScopes: function(scopes) {
				SCOPES = scopes;
			},

			load: function(calback){
				var args = arguments.length;
				GClient.get(function (){
					gapi.client.load('oauth2', 'v2', function() {
						if (args == 1)
							calback();
					});
				});
			},

			// checkAuth: function(){
			// 	var deferred = $q.defer();
			// 	var token = getAccessToken("access_token");
			// 	if (token) {
			// 		document.cookie = "access_token=;max-age=0;"; //force user to login if page refresh or close for security
			// 		$rootScope.$broadcast('auth:logged-in-failed');
			// 		deferred.reject({ status: 401, error: "User is not authenticated yet."});
			// 	} else {
			// 		$rootScope.$broadcast('auth:logged-in-failed');
			// 		deferred.reject({ status: 401, error: "User is not authenticated yet."});
			// 	}
			// 	return deferred.promise;
			// },

			checkAuth: function(){
				var deferred = $q.defer();
				if (!isCheckingAuth) {
					isCheckingAuth = true;
					var userDetails = getUserDetails('user_details');
					if (userDetails) {
						signInWithGIS(function (token) {
							if (token.error) {
								authToken = null;
								removeStoredCookies();
								deferred.reject({ status: 401, error: token.error });
							} else {
								authToken = token;
								storeAccessToken(authToken);
								getUserv2(userDetails).then(function () {
									$rootScope.$broadcast('auth:logged-in-success');
									deferred.resolve();
								}, function (err) {
									$rootScope.$broadcast('auth:logged-in-failed');
									removeStoredCookies();
									deferred.reject(err);
								});
							}
							isCheckingAuth = false;
						});
					} else {
						isCheckingAuth = false;
						deferred.reject({ status: 401, error: 'ERROR' });
					}
				}
				return deferred.promise;
			},

			refreshAccessToken: function () {
				var deferred = $q.defer();
				var userDetail = getUserDetails('user_details');
				if (userDetail) {
					signInWithGIS(function (token) {
						if (token.error) {
							authToken = null;
							removeStoredCookies();
							deferred.reject({ status: 401, error: token.error});
						} else {
							authToken = token;
							getUserv2(userDetail).then(function () {
								$rootScope.$broadcast('auth:logged-in-success');
								deferred.resolve();
							}, function (err) {
								$rootscope.$broadcast('auth:logged-in-failed');
								removeStoredCookies();
								deferred.reject(err);
							});
						}
					});
				} else {
					deferred.reject({ status: 401, error: 'No user found'});
				}
				return deferred.promise;
			},

			login: function (responsePayload){
				var deferred = $q.defer();
				signInWithGIS(function(token) {
					if (token.error) {
						authToken = null;
						deferred.reject({ status: 401, error: token.error });
					} else {
						authToken = token;
						storeAccessToken(authToken);
						storeUserDetails(JSON.stringify(responsePayload));
						getUserv2(responsePayload).then(function () {
							$rootScope.$broadcast('auth:logged-in-success');
							deferred.resolve();
						}, function (err) {
							$rootScope.$broadcast('auth:logged-in-failed');
							deferred.reject(err);
						});
					}
				});
				return deferred.promise;
			},

			logout: function () {
				var deferred = $q.defer();

				$rootScope.user = null;
				$rootScope.$broadcast('auth:logging-out');

				signout().then(function () {
					$rootScope.$broadcast('auth:logged-out-success');
					deferred.resolve();
				}, function (err) {
					$rootScope.$broadcast('auth:logged-out-failed');
					deferred.reject(err);
				});

				return deferred.promise;
			},

			getAuthToken: function () {
				return authToken;
			},

			challengeAuth: function () {
				if ($rootScope.user) {
					GApi.executeAuth('lmi2Api', 'auth.challengeAuth', { })
						.then(function(resp) {
							// User is a MeIncer
						}, function(err) {
							// Don't need to react. GApi will log the user out already.
						});
				}
			}
		};

	}]);

var app = angular.module('app');

app.factory('GClient', ['$document', '$q', '$timeout', '$interval',
	function ($document, $q, $timeout, $interval) {

		var LOAD_GAE_API = false;
		var LOAD_GIS = false;
		var URL = 'https://apis.google.com/js/client.js';
		var URL2 = 'https://accounts.google.com/gsi/client';

		function loadScript(src) {
			var deferred = $q.defer();
			var script = $document[0].createElement('script');
			script.onload = function (e) {
				$timeout(function () {
					deferred.resolve(e);
				});
			};
			script.onerror = function (e) {
				$timeout(function () {
					deferred.reject(e);
				});
			};
			script.src = src;
			$document[0].body.appendChild(script);
			return deferred.promise;
		}

		function loadGISScript(src) {
			var deferred = $q.defer();
			var script = $document[0].createElement('script');
			script.onload = function (e) {
				$timeout(function () {
					deferred.resolve(e);
				});
			};
			script.onerror = function (e) {
				$timeout(function () {
					deferred.reject(e);
				});
			};
			script.src = src;
			$document[0].body.appendChild(script);
			return deferred.promise;
		}

		function load(calback) {
			loadScript(URL).then(function() {
				var isok = function(calback) {
					if(gapi.client !== undefined) {
						LOAD_GAE_API = true;
						calback();
						$interval.cancel(check);
					}
				};

				isok(calback);
				var check = $interval(function() {
					isok(calback);
				}, 10);
				LOAD_GAE_API = true;

			});
		}

		function loadGIS(calback) {
			loadGISScript(URL2).then(function() {
				var isok = function(calback) {
					if(google.accounts !== undefined) {
						LOAD_GIS = true;
						calback();
						$interval.cancel(check);
					}
				};

				isok(calback);
				var check = $interval(function() {
					isok(calback);
				}, 10);
				LOAD_GIS = true;

			});
		}

		return {

			get: function(calback){
				if(LOAD_GAE_API)
					calback();
				else
					load(calback);

			},

			getGIS: function(calback) {
				if(LOAD_GIS) {
					calback();
				} else {
					loadGIS(calback);
				}
			}

		};

	}]);

var app = angular.module('app');

app.factory('NotificationsService', function () {
	
	return {
		'show': function (message, timeout, alignment, allowHtml) {
			MDSnackbars.show({
				text: message,
				timeout: timeout || 0,
				html: allowHtml || false,
				align: alignment || 'right'
			});
		},
		
		'hide': function () {
			MDSnackbars.hide();
		}
	};

});
var app = angular.module('app');

app.factory('RolesManager', ['$state', function ($state) {

	return {

		// Checks if the user has the required roles
		hasRoles: function(user, roles, matchAllRoles) {
			// If matchAllRoles is true, then the user must have all the roles provided 
			if (matchAllRoles === undefined) matchAllRoles = false;
			
			if (!user || !user.roles || !_.isArray(user.roles)) return false;
			if (!roles || !_.isArray(roles)) roles = [];
			
			if (matchAllRoles) return _.intersection(user.roles, roles).length === roles.length;
			else return _.intersection(user.roles, roles).length > 0;
		},
		
		getUserRoles: function() {
			return [
			        { id: 'administrator', translationKey: 'roles-administrator', name: 'Administrator' },
			        { id: 'care-management', translationKey: 'roles-care-management', name: 'Care Management' },
			        { id: 'spoc-care', translationKey: 'roles-spoc-care', name: 'SPOC Care' },
			        { id: 'unit-leader', translationKey: 'roles-unit-leader', name: 'Unit Leader' }
			        ];
		}

	};

}]);
var app = angular.module('app');

app.factory('TranslationsService', ['$rootScope', '$q', '$http', 'GApi', 'localStorageService',
        function ($rootScope, $q, $http, GApi, localStorageService) {

	var CACHE_KEY_TIMESTAMPS = 'com.trifinance.lmi2.services.translations.timestamps';
	var CACHE_KEY_TRANSLATIONS = 'com.trifinance.lmi2.services.translations.translations';
	var CACHE_STATIC_CONTENT_KEY = 'static-content-{lang}-{pkg}-{key}';
	var CACHE_STATIC_CONTENT_LANGUAGE_PLACEHOLDER = '{lang}';
	var CACHE_STATIC_CONTENT_PACKAGE_PLACEHOLDER = '{pkg}';
	var CACHE_STATIC_CONTENT_KEY_PLACEHOLDER = '{key}';
	var CACHE_STATIC_CONTENT_KEY_REGEX_MATCH = '^static-content-(.*)$';
	var GOOGLE_DOC_URL = 'https://docs.google.com/document/d/{GOOGLE_DOC_ID}/pub';
	var GOOGLE_DOC_PLACEHOLDER = '{GOOGLE_DOC_ID}';
	var DEFAULT_LANGUAGE = 'EN';
	
	return {
		
		'isCached': function (pkgName) {
			
			var language = this._getUserLanguage();
			var cachedTranslation = this._getCachedTranslations(language, pkgName);

			return this._getCachedTimestamp(language, pkgName) > 0 && cachedTranslation !== null && cachedTranslation !== undefined;
		},
		
		'load': function (pkgNames) {
			
			if (!pkgNames || !(pkgNames instanceof Array)) pkgNames = [];
			
			// Load translations in parallel
			var d = $q.defer();
			var promises = [];
			var svc = this;
			angular.forEach(pkgNames, function (pkgName) {
				promises.push(svc._load(pkgName));
			});
			
			$q.all(promises).then(function (results) {
				// Update cache in local storage, ideally should get from server the cached timestamp
				if (results && results instanceof Array) {
					angular.forEach(results, function (result) {
						svc._setCacheTimestamp(result.language, result.pkgName, result.serverCacheTimestamp);
						svc._setCachedTranslations(result.language, result.pkgName, result.translations);
					});
				}
				
				return svc.getTranslations(pkgNames);
			}).then(function (translations) {
				return svc._loadStaticContent(translations);
			}).then(function (translations) {
				d.resolve(translations); // Return value is similar to what getTranslations would return, i.e. static content resolved.
			}, function (err) {
				d.reject(err);
			});
			
			return d.promise;
			
		},
		
		'getCachedStaticDocId': function (pkgName, key) {
		
			var language = this._getUserLanguage();			
			var translations = this._getCachedTranslations(language, pkgName);
			
			if (translations === null) translations = { };			
			var value = translations[key] || '';
			var regexResult = value.match(/(\${google[Dd]oc:)(.*)(})$/);
			
			if (regexResult && regexResult instanceof Array && regexResult.length === 4) return regexResult[2];
			else return null;
			
		},
		
		'getTranslations': function (pkgNames) {
			
			var language = this._getUserLanguage();
			var svc = this;
			var result = { };
			
			angular.forEach(pkgNames, function (pkgName) {
				var cachedTranslations = svc._getCachedTranslations(language, pkgName);
				if (cachedTranslations) {
					if (!cachedTranslations) cachedTranslations = { };
					
					for (var key in cachedTranslations) {
						var staticContent = svc._getCachedStaticContent(language, pkgName, key);
						
						if (staticContent) cachedTranslations[key] = staticContent;
					}					
					
					result[pkgName] = cachedTranslations;
				}
			});
			
			return result;
			
		},
		
		'clearCache': function () {
			
			var d = $q.defer();
			
			GApi.execute('lmi2Api', 'translations.clearCache').then(function () {
				// Remove translations
				localStorageService.remove(CACHE_KEY_TIMESTAMPS, CACHE_KEY_TRANSLATIONS);
				
				// Remove static content
				angular.forEach(localStorageService.keys(), function (key) {
					if (key.match(new RegExp(CACHE_STATIC_CONTENT_KEY_REGEX_MATCH))) {
						localStorageService.remove(key);
					}
				});
				
				d.resolve();
			}, function () {
				d.reject();
			});
			
			return d.promise;
			
		},
		
		'_getUserLanguage': function () {
			
			return ($rootScope.user && $rootScope.user.language) ? $rootScope.user.language : DEFAULT_LANGUAGE;
			
		},
		
		'_getCachedTimestamp': function (language, pkgName) {
			
			var cache = { };
			cache[language] = { };
			cache[language][pkgName] = null;
			cache = angular.extend(cache, localStorageService.get(CACHE_KEY_TIMESTAMPS));
			
			return cache[language][pkgName] || 0;
			
		},
		
		'_setCacheTimestamp': function (language, pkgName, cacheTimestamp) {
			
			if (!cacheTimestamp) cacheTimestamp = 0;
			cacheTimestamp = parseInt(cacheTimestamp);
			
			var cache = { };
			cache[language] = { };
			cache[language][pkgName] = null;
			cache = angular.extend(cache, localStorageService.get(CACHE_KEY_TIMESTAMPS));
			
			if (cacheTimestamp > 0) {
				cache[language][pkgName] = cacheTimestamp;
				localStorageService.set(CACHE_KEY_TIMESTAMPS, cache);
			} else {
				delete cache[language][pkgName];
				localStorageService.set(CACHE_KEY_TIMESTAMPS, cache);
			}
			
			return cache;
			
		},
		
		'_getCachedTranslations': function (language, pkgName) {
			
			var cache = { };
			cache[language] = { };
			cache[language][pkgName] = null;
			cache = angular.extend(cache, localStorageService.get(CACHE_KEY_TRANSLATIONS));
			
			return cache[language][pkgName];
			
		},
		
		'_setCachedTranslations': function (language, pkgName, translations) {
			
			var cache = { };
			cache[language] = { };
			cache[language][pkgName] = null;
			cache = angular.extend(cache, localStorageService.get(CACHE_KEY_TRANSLATIONS));
	
			if (translations) {
				cache[language][pkgName] = translations;
				localStorageService.set(CACHE_KEY_TRANSLATIONS, cache);
			}
			
			return cache;
			
		},
		
		'_getCachedStaticContent': function (language, pkgName, key) {
			
			var cacheKey = CACHE_STATIC_CONTENT_KEY
				.replace(CACHE_STATIC_CONTENT_LANGUAGE_PLACEHOLDER, language)
				.replace(CACHE_STATIC_CONTENT_PACKAGE_PLACEHOLDER, pkgName)
				.replace(CACHE_STATIC_CONTENT_KEY_PLACEHOLDER, key);
			
			return localStorageService.get(cacheKey);
			
		},
		
		'_setCachedStaticContent': function (language, pkgName, key, content) {
			
			var cacheKey = CACHE_STATIC_CONTENT_KEY
				.replace(CACHE_STATIC_CONTENT_LANGUAGE_PLACEHOLDER, language)
				.replace(CACHE_STATIC_CONTENT_PACKAGE_PLACEHOLDER, pkgName)
				.replace(CACHE_STATIC_CONTENT_KEY_PLACEHOLDER, key);
			
			localStorageService.set(cacheKey, content);
			
		},
		
		'_loadStaticContent': function (translations) {
			
			var d = $q.defer();
			var language = this._getUserLanguage();
			var svc = this;
			
			// Scan through translations and find static content to load
			var staticContent = [];
			if (translations) {
				for (var pkgName in translations) {
					if (!translations[pkgName]) continue;
					
					for (var key in translations[pkgName]) {
						var value = translations[pkgName][key] || '';
						var regexResult = value.match(/(\${google[Dd]oc:)(.*)(})$/);
						
						if (regexResult && regexResult instanceof Array && regexResult.length === 4) {
							staticContent.push({
								pkgName: pkgName,
								key: key,
								value: value,
								googleDocId: regexResult[2]
							});
						}
					}
				}
			}
			
			if (staticContent.length > 0) {
				var promises = [];
				var keyMap = { };
				
				angular.forEach(staticContent, function (item) {
					if (item && 'googleDocId' in item) {						
						var url = GOOGLE_DOC_URL.match(new RegExp('^(.*)(' + GOOGLE_DOC_PLACEHOLDER + ')(.*)$'));
						
						if (url && url instanceof Array && url.length === 4) {							
							url = url.slice(1, 4);
							url.splice(1, 1, item.googleDocId);
							url = url.join('');
							
							keyMap[url] = item;
							
							promises.push($http.get(url));
						}
					}
				});
				
				$q.all(promises).then(function (results) {
					if (results && results instanceof Array) {
						angular.forEach(results, function (result) {
							if (result.config && result.config.url && 
									result.status === 200 && result.data) {
								
								var data = keyMap[result.config.url];
								
								// Cleanse data
								// First throw away everything before <div id="contents"> and on </div><div id="footer"> onwards
								//var elements = result.data.match(/(.*)(<.*?id="contents">)(.*)(<\/.*?>\s?<.*?id="footer">)(.*)/);
                               // var elements = result.data.match(/(.)(<div id="contents">)(.*)(<\/div><script type="text\/javascript" nonce=")(.*)/);
								var elements = result.data.match(/(.)(<div id="contents">)(.*)(<\/div><script nonce=")(.*)/);
								var html = null;
								if (elements && elements.length === 6) {
									html = elements[3];
									
									// Wrap HTML in new <div id="{data.key}">
									html = "<div id=\"" + data.key + "\">" + html + "</div>";
									
									// Now modify styles definition to include #{data.key}
									elements = html.match(/(.*)(<style.*?>)(.*)(<\/style>)(.*)/);
									
									if (elements && elements.length === 6) {
										var css = elements[3];
										
										var cssElements = css.split('}');
										if (cssElements && cssElements.length > 0) {
											cssElements[0] = '#' + data.key + ' ' + cssElements[0];
											css = cssElements.join('}#' + data.key + ' ');
										}
										
										elements = elements.slice(1, 6);
										elements.splice(2, 1, css);
										html = elements.join('');
									}
									
									html = html.replace(/<a /g, '<a target="_blank" ');
								}
								
								svc._setCachedStaticContent(
										language,
										data.pkgName,
										data.key,
										html);
								
								translations[data.pkgName][data.key] = result.data;
								
							}
						});
						
					}
					
					d.resolve(translations);
				}, function (err) {
					d.reject(err);
				});
			} else {
				d.resolve(translations);
			}
			
			return d.promise;
			
		},
		
		'_load': function (pkgName) {
			
			var d = $q.defer();
			var language = this._getUserLanguage();
			var lastCacheUpdate = this._getCachedTimestamp(language, pkgName);
			var svc = this;
			
			var cachedTranslation = this._getCachedTranslations(language, pkgName);
			if(cachedTranslation === null || cachedTranslation === undefined){
				lastCacheUpdate = 0;
			}
			
			GApi.execute('lmi2Api', 'translations.getTranslations', { 
				cacheTimestampUtcInMs: lastCacheUpdate, 
				language: language, 
				packageName: pkgName
			}).then(function(resp) {
				d.resolve({
					language: language,
					pkgName: pkgName,
					serverCacheTimestamp: resp.serverCacheTimestamp,
					translations: resp.translations
				});
			}, function(err) {	
				if (svc.isCached(pkgName)) {
					// Although loading translations failed, there is cache. Let's use that for now.
					d.resolve();
				} else {
					// Tell the application to route the user to the HTTP 500 page.
					d.reject({
						status: 500,
						error: 'Could not load translations "' + pkgName + '" language "' + language + '".',
						innerException: err
					});
				}
			});

			return d.promise;
			
		}
		
	};

}]);
var app = angular.module('app');

app.controller('SpocCareAccessMeincerController', [ '$rootScope', '$scope', '$state', 'RolesManager', 'GApi', 'NotificationsService', '$q',
                                    function($rootScope, $scope, $state, rolesManager, GApi, notificationsService, $q) {
	
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	
	$scope.isReady = true;
	$scope.searchText = null;
	$scope.selectedUser = null;
	$scope.selectedUserRoles = [];	
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };

	$scope.isAdmin = false;
	$scope.isSpocCareOnly = false;// always set to false whenever enter this function
	$scope.user = $rootScope.user;
	$scope.isUserLoggedIn = $rootScope.user !== null && $rootScope.user !== undefined;
	var roles = $rootScope.impersonateUser ? $rootScope.impersonateUserRoles : ($scope.user ? $scope.user.roles : []);
	$scope.isAdmin = roles && _.isArray(roles) && _.contains(roles, 'administrator', 'spoc-care');

	if($scope.isAdmin === false){
		$scope.isSpocCareOnly = roles && _.isArray(roles) && _.contains(roles,'spoc-care');
	}

	$scope.selectedItemChange = function (item) {
		if (!item) return;
		
		$scope.isReady = false;
		
		GApi.executeAuth('lmi2Api', 'auth.getUserRoles', {
			email: item.email
		}).then(function(resp) {
			if (resp) $scope.selectedUserRoles = resp.items || [];
			else $scope.selectedUserRoles = [];
			
			$scope.isReady = true;
		});
	};
	
	$scope.accessMeincer = function () {
		$rootScope.impersonateUser = angular.copy($scope.selectedUser);
		$rootScope.impersonateUserRoles = angular.copy($scope.selectedUserRoles);
		$rootScope.spocCareUser = true;

		$state.go('home');
	};
	
	$scope.querySearch = function (query) {
		deferred = $q.defer();
		
		GApi.executeAuth('lmi2Api', 'auth.suggestUsersForSpocCare', {
			criteria: query 
		}).then(function(resp) {			
			var results = [];
			
			if (resp && resp.items) {
				angular.forEach(resp.items, function (item) {
					if (item.userId !== $rootScope.user.id) results.push(item);
				});
			}
			
			deferred.resolve(results);
		}, function (err) {
			deferred.reject(err);
		});	
		
		return deferred.promise;
	};
	
	$scope.getDisplayText = function (item) {
		return item.fullName + '<' + item.email + '>';
	};
		
} ]);
var app = angular.module('app');

app.controller('SpocCareController', [ '$rootScope', '$scope', '$state', 'RolesManager',
                                    function($rootScope, $scope, $state, rolesManager) {
		
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
			
	$scope.tabs = _.filter($state.get(), function (state) {
		return (state.name && state.name.match(/^spoc-care./) && state.title &&
				state.requiredRoles && rolesManager.hasRoles($rootScope.user, state.requiredRoles));
	});
		
	$scope.isSelected = function (stateName) {
		return (stateName && $state.includes(stateName)) ? 'selected' : '';
	};
	
	$scope.getRouteName = function (redirectTo, stateName) {
		return redirectTo || stateName;
	};
	
} ]);
var app = angular.module('app');

app.controller('WorkflowsController', [ '$rootScope', '$scope', '$state', 'RolesManager', 
                                    function($rootScope, $scope, $state, rolesManager) {
		
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
			
	$scope.tabs = _.filter($state.get(), function (state) {
		return (state.name && state.name.match(/^workflows./) && state.title &&
				state.requiredRoles && rolesManager.hasRoles($rootScope.user, state.requiredRoles));
	});
		
	$scope.isSelected = function (stateName) {
		return (stateName && $state.includes(stateName)) ? 'selected' : '';
	};
	
	$scope.getRouteName = function (redirectTo, stateName) {
		return redirectTo || stateName;
	};
	
} ]);
var app = angular.module('app');

app.controller('NewcycleController', [ '$rootScope', '$scope', '$state', 'GApi', '$modal','NotificationsService', '$window',
                                    function($rootScope, $scope, $state, GApi, $modal, NotificationsService, $window) {
	// paging param
	var PAGE_SIZE = 10;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	$scope.requestApprove = 1;
	$scope.requestReject = 2;
	
	$scope.isLoading = true;

	// paging param
	$scope.reviewList=[];
	var modalInstance;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	listReview(); 
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		listReview();
	};
	

	
	$scope.updateRequestStatusPopUp = function (requestNewCycleId, requestStatus) {
		if(requestStatus==1){
			$scope.processPopupTitle = $scope.translations.core['lbl-popup-confirm-approve'];
			$scope.processPopupMsg = $scope.translations.core['msg-popup-confirm-approve'];			
			$scope.processPopupButton = $scope.translations.core['btn-proceed-approve'];
		}else{
			$scope.processPopupTitle = $scope.translations.core['lbl-popup-confirm-reject'];
			$scope.processPopupMsg = $scope.translations.core['msg-popup-confirm-reject'];	
			$scope.processPopupButton = $scope.translations.core['btn-proceed-reject'];
		}

		$scope.requestNewCycleIdVal = requestNewCycleId;
		$scope.requestStatusVal = requestStatus;
		$('#confirmStartNextProcess').modal('show');
	};
	
	$scope.updateRequestStatus = function (requestNewCycleId, requestStatus) {
		$('#confirmStartNextProcess').modal('hide');
		$scope.environment="TST";
		if($window.location.host.indexOf('localhost') < 0){
			if($window.location.host.indexOf('trifinance-lmi2-tst.appspot.com') < 0){
				$scope.environment="PRD";
			}
		}
			
		$scope.isLoading = true;
		GApi.executeAuth('lmi2Api', 'requestNewCycle.updateNewCycleRequestStatus', { 
			requestNewCycleId : requestNewCycleId,
			status : requestStatus,
			environment:$scope.environment
		}).then(function(resp) {
			$state.reload();
			$scope.isLoading = false;
		}, function (err) {
			$scope.isLoading = false;
			$scope.isSaving = false;
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	
	function listReview(){
		$scope.isLoading = true;
		GApi.executeAuth('lmi2Api', 'requestNewCycle.getNewCycleRequestList', {
			page: $scope.currentPage,
			pageSize: PAGE_SIZE		
		}).then(function (resp) {
			//$scope.coaches = resp.items;
			$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.reviewList = _.map(resp.records, function (coach) {
					return coach;
				});
				
				$scope.reviewListCopy = angular.copy($scope.reviewList);
			} else {
				$scope.reviewList = [];
				$scope.reviewListCopy = [];
			}
			
			$scope.pagination = [];
			for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
				$scope.pagination.push(i);
			}
			$scope.isLoading = false;
		},function (err) {
			// TODO: Log error
			var message = err.message;
			
			if(message !==""){
			var insufficientPrivileges = err.message.includes("Insufficient privileges");
			if(insufficientPrivileges === true)
				message = "Insufficient privileges to access this page";
			}
			NotificationsService.show(message, NOTIFICATIONS_FADEOUT_TIMEOUT);
			$scope.isLoading = false;
		});			
	}
	
} ]);
var app = angular.module('app');

app.controller('ReviewController', [ '$rootScope', '$scope', '$state', 'GApi', '$modal','NotificationsService',
                                    function($rootScope, $scope, $state, GApi, $modal, NotificationsService) {
	// paging param
	var PAGE_SIZE = 10;
	var NOTIFICATIONS_FADEOUT_TIMEOUT = 3000;
	$scope.currentPage = 1;
	$scope.totalRecords = 0;
	$scope.pagination = [];
	$scope.requestApprove = 1;
	$scope.requestReject = 2;
	$scope.requestDone = 3;
	$scope.isLoading = true;
	// paging param
	$scope.reviewList=[];
	var modalInstance;
	
	$scope.translations = $rootScope.stateChangeStart.data && 
		$rootScope.stateChangeStart.data.translations ? $rootScope.stateChangeStart.data.translations : { };
	
	listReview(); 
	
	$scope.$on('onRepeatLast', function (scope, element, attrs) {
		// Initialize the material elements (to render the material check boxes correctly) after the
		// last role check box has been rendered
		$.material.init();
		
		$('.pagination > li').removeClass('active');
		$('.pagination > li').each(function () {
			var item = $(this);
			var pageNbr = parseInt(item.find('a').html());
			
			if (pageNbr === parseInt($scope.currentPage)) item.addClass('active'); 
		});
		
	});
	
	$scope.changePage = function (page) {
		$scope.currentPage = page;
		listReview();
	};
	

	
	$scope.updateRequestStatusPopUp = function (requestId, requestStatus) {
		if(requestStatus==1){
			$scope.processPopupTitle = $scope.translations.core['lbl-popup-confirm-approve'];
			$scope.processPopupMsg = $scope.translations.core['msg-popup-confirm-approve'];			
			$scope.processPopupButton = $scope.translations.core['btn-proceed-approve'];
		}else if(requestStatus==2){
			$scope.processPopupTitle = $scope.translations.core['lbl-popup-confirm-reject'];
			$scope.processPopupMsg = $scope.translations.core['msg-popup-confirm-reject'];	
			$scope.processPopupButton = $scope.translations.core['btn-proceed-reject'];
		}else{
			$scope.processPopupTitle = $scope.translations.core['lbl-popup-confirm-process'];
			$scope.processPopupMsg = $scope.translations.core['msg-popup-confirm-process'];	
			$scope.processPopupButton = $scope.translations.core['btn-proceed-process'];			
		}

		$scope.requestIdVal = requestId;
		$scope.requestStatusVal = requestStatus;
		$('#confirmStartNextProcess').modal('show');
	};
	
	$scope.updateRequestStatus = function (requestId, requestStatus) {
		$('#confirmStartNextProcess').modal('hide');
		$scope.isLoading = true;
		GApi.executeAuth('lmi2Api', 'personality.updateRequestStatus', { 
			requestId : requestId,
			status : requestStatus
		}).then(function(resp) {
			$state.reload();
			$scope.isLoading = false;
		}, function (err) {
			$scope.isSaving = false;
			NotificationsService.show(err.message, NOTIFICATIONS_FADEOUT_TIMEOUT);
		});			
	};
	
	function listReview(){
		$scope.isLoading = true;
		GApi.executeAuth('lmi2Api', 'personality.getRequestList', {
			page: $scope.currentPage,
			pageSize: PAGE_SIZE		
		}).then(function (resp) {
			//$scope.coaches = resp.items;
			$scope.currentPage = resp.page;
			$scope.totalRecords = resp.totalRecords;
			
			if (resp.records && _.isArray(resp.records)) {
				$scope.reviewList = _.map(resp.records, function (coach) {
					return coach;
				});
				
				$scope.reviewListCopy = angular.copy($scope.reviewList);
			} else {
				$scope.reviewList = [];
				$scope.reviewListCopy = [];
			}
			
			$scope.pagination = [];
			for (var i = 1; i <= Math.ceil($scope.totalRecords / PAGE_SIZE); i++) {
				$scope.pagination.push(i);
			}			
			$scope.isLoading = false;
		},function (err) {
			$scope.isLoading = false;
			// TODO: Log error
		});			
	}
	
} ]);

